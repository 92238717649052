import React from 'react';
import IconSvg from 'common/javascripts/components/IconSvg';

export default function TopPageNotice({ notice, onClose }) {
  const isShow = notice ? true : false;

  return (
    <div className={`topPageNotice topPageNotice_show_${isShow}`}>
      <div className='topPageNotice__notice'>
        {notice}
      </div>
      <div className='topPageNotice__closeIconWrap' onClick={onClose}>
        <IconSvg
          icon='close'
          className='topPageNotice__closeIcon'
        />
      </div>
    </div>
  );
}
