import React from 'react';
import PropTypes from 'prop-types';
import FaqCard from '../Card';
import PageCardsList from '../../../../../components/PageCardsList';

export default function FaqCardsList({ items, filterTagId }) {
  const cardItems = items.map((item) => {
    if (filterTagId === null || item.tags.find((obj) => obj.id === filterTagId)) {
      return (
        <li key={`cardItem_${item.id}`}>
          <FaqCard
            faq={item}
            question={item.question}
            answerHtml={item.answer}
          />
        </li>
      );
    }
    return null;
  });

  return (
    <PageCardsList>
      {cardItems}
    </PageCardsList>
  );
}

FaqCardsList.propTypes = {
  items: PropTypes.instanceOf(Array),
  filterTagId: PropTypes.number,
};

FaqCardsList.defaultProps = {
  items: [],
  filterTagId: null,
};
