import React from 'react';
import PropTypes from 'prop-types';
import TextCopy from 'client/javascripts/components/TextCopy';
import cn from 'classnames';

import styles from './CodeItem.module.scss';

const codeBlockInfo = {
  used: 'Код использован',
  expired: 'Код просрочен',
  canceled: 'Код отменен',
};

export default function OrderCodeItem({ item, alwaysCanUse }) {
  const isExpired = item.state === 'expired';
  const isUsed = item.state === 'used';
  const isCanceled = item.state === 'canceled';
  const canUse = (!isExpired && !isUsed && !isCanceled) || alwaysCanUse;
  const className = cn(styles.CodeItem, { [styles.CodeItem_blocked]: !canUse });

  return (
    <div className={className}>
      <div className={styles.CodeItem__code}>
        {item.code}
        {
          canUse
          && (
            <TextCopy
              label="Скопировать"
              labelAfterCopy="Код скопирован"
              textToCopy={item.code}
              className={styles.CodeItem__copy}
            />
          )
        }

        {
          !canUse
          && (
            <span className={styles.CodeItem__copy}>
              {codeBlockInfo[item.state]}
            </span>
          )
        }
      </div>

      <div className={styles.CodeItem__label}>
        Действителен до&nbsp;
        <strong>{item.end_at}</strong>
      </div>
    </div>
  );
}

OrderCodeItem.propTypes = {
  item: PropTypes.object,
  alwaysCanUse: PropTypes.bool,
};

OrderCodeItem.defaultProps = {
  alwaysCanUse: false,
};
