/* eslint-disable react/prop-types */
/* TODO: prop types validations */
import React from 'react';
import TextCopy from '../../../TextCopy';
import QRShowBtn from '../../../QRShowBtn';
import Cost from '../../../Cost';
import Avatar from '../../../Avatar';
import TeamInvitePlayerBtn from '../../../TeamInvitePlayerBtn';
import TeamRatingPosition from '../../../TeamRatingPosition';
import TopbarMenuAvatar from '../MenuAvatar';
import TopbarMenuName from '../MenuName';
import TopbarMenuDivider from '../MenuDivider';

import styles from './TeamMenu.module.scss';

export default function TopbarTeamMenu({
  team, player, teamAPI, playerAPI, canInviteTeamMember,
}) {
  const teamPlayers = team.players.map((p) => (
    <a className={styles.Menu__playerAvatar} href={playerAPI.profilePath(p.id)} key={`teamPlayer${p.id}`}>
      <Avatar type="player" avatar={p.avatar_url} />
    </a>
  ));

  return (
    <div className={styles.Menu}>
      <div className={styles.Menu__avatarRow}>
        <div className={styles.Menu__rating}>
          {
            team['active?'] && <TeamRatingPosition ratingPosition={team.rating_position} />
          }
        </div>

        <div className={styles.Menu__avatar}>
          <TopbarMenuAvatar
            avatarUrl={team.avatar_url}
            avatarType="team"
            linkUrl={teamAPI.teamProfilePath(team.id)}
          />
        </div>

        <div className={styles.Menu__points}>
          <Cost
            icon="team-points"
            amount={team.points}
            showZero
          />
        </div>
      </div>

      <TopbarMenuName
        name={team.title}
        linkUrl={teamAPI.teamProfilePath(team.id)}
      />

      <div className={styles.Menu__playersWrap}>
        {teamPlayers}
        {canInviteTeamMember && (
          <div className={styles.Menu__addPlayer}>
            <TeamInvitePlayerBtn withLabel />
          </div>
        )}
      </div>

      <div className={styles.Menu__qrAndIdRow}>
        <QRShowBtn label="QR-код команды" value={`${team.uniq_num}`} />
        <div>
          <strong>{`id ${team.uniq_num}` }</strong>
          <TextCopy
            className={styles.Menu__copyIdLink}
            textToCopy={team.uniq_num}
            label="скопировать"
            labelAfterCopy="скопировано"
          />
        </div>
      </div>

      {
        !team['active?']
        && (
          <>
            <TopbarMenuDivider />
            <div>
              Чтобы участвовать в рейтинге, добавь хотя бы одного игрока!
            </div>
          </>
        )
      }
    </div>
  );
}
