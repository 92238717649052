/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TagsFilter from 'client/javascripts/components/TagsFilter';
import TagsFilterList from 'client/javascripts/components/TagsFilterList';
import TagsFilterButton from 'client/javascripts/components/TagsFilterButton';
import PageHeader from 'client/javascripts/components/PageHeader';
import ProgramMemberButton from 'client/javascripts/components/ProgramMemberButton';
import { removeDuplicates } from 'common/javascripts/helpers';

import FaqCardsList from './components/CardsList';

export default function FaqListScreen({ faqs, initialFilterTagId, programMember, settings }) {
  const [currentFilterTagId, setCurrentFilterTagId] = useState(initialFilterTagId);

  const handleTagClick = (id) => {
    setCurrentFilterTagId(id);
  };

  const collectedTags = removeDuplicates(
    faqs.flatMap((item) => item.tags), 'id',
  ).sort((a, b) => (a.position > b.position ? 1 : -1));

  const tagItems = collectedTags.map((tag) => (
    <li key={`tagItem_${tag.id}`}>
      <TagsFilterButton
        label={tag.title}
        onClick={() => handleTagClick(tag.id)}
        active={tag.id === currentFilterTagId}
      />
    </li>
  ));

  return (
    <>
      {!programMember && <ProgramMemberButton settings={settings.data} /> }
      <PageHeader><h1>FAQ</h1></PageHeader>
      <TagsFilter>
        <TagsFilterList>
          <li>
            <TagsFilterButton
              label="Все"
              onClick={() => handleTagClick(null)}
              active={currentFilterTagId === null}
            />
          </li>
          {tagItems}
        </TagsFilterList>
      </TagsFilter>

      <FaqCardsList
        filterTagId={currentFilterTagId}
        items={faqs}
      />
    </>
  );
}

FaqListScreen.propTypes = {
  faqs: PropTypes.instanceOf(Array),
  initialFilterTagId: PropTypes.number,
  programMember: PropTypes.bool.isRequired,
  settings: PropTypes.shape({
    data: PropTypes.shape({
      bracelet_text: PropTypes.string,
    }),
  }).isRequired,
};

FaqListScreen.defaultProps = {
  faqs: [],
  initialFilterTagId: null,
};
