import {
  action,
  makeObservable,
  observable,
  onBecomeObserved,
  runInAction,
} from 'mobx';

import PlayersApi from './playersApi';

const MODERATED_PLAYER_ID = 'moderatedPlayerId';

class ModeratedPlayer {
  player = null

  isPlayerLoading = false

  constructor(playersApi) {
    makeObservable(this, {
      player: observable,
      isPlayerLoading: observable,
      initPlayer: action,
      setPlayer: action,
      fetchPlayer: action,
    });
    this.playersApi = playersApi;
    onBecomeObserved(this, 'player', this.initPlayer);
  }

  initPlayer = async () => {
    const playerId = localStorage.getItem(MODERATED_PLAYER_ID);
    if (playerId) {
      this.fetchPlayer(playerId);
    }
  }

  setPlayer = (player) => {
    this.player = player;

    if (player) {
      localStorage.setItem(MODERATED_PLAYER_ID, player.id);
    } else {
      localStorage.removeItem(MODERATED_PLAYER_ID);
    }
  };

  resetPlayer = () => {
    this.setPlayer(null);
  }

  fetchPlayer = async (playerId) => {
    this.isPlayerLoading = true;
    const response = await this.playersApi.getPlayer(playerId);

    runInAction(() => {
      if (response) {
        this.setPlayer(response.player);
      }
      this.isPlayerLoading = false;
    });

    if (response) {
      return response.player;
    }
    return null;
  }

  fetchPlayerByPhone = async (phone) => {
    const result = await this.playersApi.searchPlayerByPhone(phone);
    if (result.status === 'ok') {
      const player = await this.fetchPlayer(result.player_id);
      return player;
    }

    return null;
  }
}

export default new ModeratedPlayer(new PlayersApi());
