import React from 'react';
import PartnerLogos from '../../../../components/PartnerLogos';
import BecomePartnerButton from '../../../../components/BecomePartnerButton';
import PageHeader from '../../../../components/PageHeader';

import styles from './PartnerLogos.module.scss';

function EventPartnerLogos() {
  return (
    <div className={styles.EventPartnerLogos}>
      <PageHeader bottomSpace="m">
        <h2>Наши партнеры</h2>
      </PageHeader>
      <PartnerLogos isSeesHeader={false} />
      <BecomePartnerButton />
    </div>
  );
}

export default React.memo(EventPartnerLogos);
