import React from 'react';
import PropTypes from 'prop-types';

import Card from '../../Card';
import CardCoverImage from '../../CardCoverImage';
import IconSvg from '../../../../../common/javascripts/components/IconSvg';
import styles from './BannerItem.module.scss';

export default function BannerItem({ banner, onClose }) {
  const handleBannerClose = (e) => {
    e.preventDefault();
    onClose(banner.id);
  };

  return (
    <Card className={styles.Banner}>
      {banner.can_hide && (
        <button
          type="button"
          className={styles.Banner__close}
          onClick={handleBannerClose}
        >
          <IconSvg icon="close" />
        </button>
      )}
      <CardCoverImage imageUrl={banner.cover_url} inList />
      <div className={styles.Banner__name}>
        {banner.name}
      </div>
    </Card>
  );
}

BannerItem.propTypes = {
  banner: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string,
    cover_url: PropTypes.string,
    can_hide: PropTypes.bool,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
