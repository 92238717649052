import React from 'react';
import PropTypes from 'prop-types';
import Hint from '../Hint';

export default function HintsList({ items }) {
  const hints = items.map((item) => <Hint key={item.id} hint={item} />);

  return hints;
}

HintsList.propTypes = {
  items: PropTypes.instanceOf(Array),
};
