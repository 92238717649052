import React from 'react';
import PropTypes from 'prop-types';

import TeamRatingCard from '../../TeamRatingCard';
import NotificationCard from '../components/Card';
import NotificationTitle from '../components/Title';

export default function NotificationTeamCard({ notification }) {
  const teamData = {
    title: notification.data.team_title,
    points: notification.data.team_points,
    avatar_url: notification.team_avatar_url,
    rating_position: notification.data.team_rating,
  };

  return (
    <NotificationCard notification={notification}>
      <NotificationTitle
        titleForNoHeaderCard
        title={notification.data.title}
      />
      <TeamRatingCard team={teamData} incard noPublicLink action={notification.action} />
    </NotificationCard>
  );
}

NotificationTeamCard.propTypes = {
  notification: PropTypes.object.isRequired,
};
