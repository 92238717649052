import { useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children }) => {
  const mount = document.getElementById('portalRoot');
  const el = document.createElement('div');

  useLayoutEffect(() => {
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el, mount]);

  return createPortal(children, el);
};

export default Portal;
