import React from 'react';
import PropTypes from 'prop-types';

import styles from './EventPlayers.module.scss';
import PlayerRatingCard from '../../../../../components/PlayerRatingCard';

function EventPlayers({ players }) {
  return (
    <div className={styles.EventRegisteredPlayers}>
      {
        players.map((player) => (
          <div key={Math.random()} className={styles.EventRegisteredPlayers__player}>
            <PlayerRatingCard player={player} />
          </div>
        ))
      }
    </div>
  );
}

EventPlayers.propTypes = {
  players: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    public_full_name: PropTypes.string,
  })).isRequired,
};

export default EventPlayers;
