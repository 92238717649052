import React from 'react';
import PropTypes from 'prop-types';
import Card from '../../../../components/Card';
import CardImages from '../../../../components/CardImages';
import CardVideos from '../../../../components/CardVideos';
import CardAudios from '../../../../components/CardAudios';

import CardHeader from '../../../../components/CardHeader';
import PlayerRate from '../../../../components/PlayerRate';
import FromEditor from '../../../../components/FromEditor';
import SocialButtons from '../../../../components/SocialButtons';
import CardTagsList from '../../../../components/CardTagsList';

import styles from './ProfileCard.module.scss';

export default function ProfileCard({ profile }) {
  return (
    <div className={styles.Profile}>
      <Card>
        <div
          className={styles.Profile__image}
          style={{ backgroundImage: `url(${profile.cover_url})` }}
        />
        <CardHeader className={styles.Profile__header}>
          <CardTagsList tags={profile.tags} />
          <h1>{profile.name}</h1>
        </CardHeader>

        <FromEditor>
          {profile.description}
        </FromEditor>

        <CardImages
          images={profile.images}
          startIndex={1}
        />
        <CardVideos videos={profile.videos} />
        <CardAudios audios={profile.audios} />

        <PlayerRate
          paths={profile.rate_paths}
          rate={profile.rate}
          header="Нравиться профиль?"
          className={styles.Profile__rate}
        />

        {
          profile.url && (
            <a href={`https://${profile.url}`} className={styles.Profile__url} target="_blank" rel="noreferrer">
              {profile.url}
            </a>
          )
        }

        <SocialButtons
          vkProfile={profile.vk_profile}
          fbProfile={profile.fb_profile}
          igProfile={profile.ig_profile}
        />

      </Card>
    </div>
  );
}

ProfileCard.propTypes = {
  profile: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    vk_profile: PropTypes.string,
    fb_profile: PropTypes.string,
    ig_profile: PropTypes.string,
    cover_url: PropTypes.string,
    hash_tag: PropTypes.string,
    rate: PropTypes.bool,
    rate_paths: PropTypes.shape({
      player_rate_up: PropTypes.string,
      player_rate_down: PropTypes.string,
      cancel_player_rate: PropTypes.string,
    }).isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
    })),
    images: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    })),
    audios: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    })),
    videos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    })),
  }).isRequired,
};
