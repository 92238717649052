import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import TagsFilter from '../../../components/TagsFilter';
import TagsFilterList from '../../../components/TagsFilterList';
import TagsFilterButton from '../../../components/TagsFilterButton';
import { removeDuplicates } from '../../../../../common/javascripts/helpers';

import PlayerChest from '../components/PlayerChest';
import ProductOrderCardsList from './components/CardsList';
import { isOrdersModerator } from '../../../helpers/players';
import ModeratedPlayer from '../../../components/ModeratedPlayer/ModeratedPlayer';
import moderatedPlayerStore from '../../../stores/moderatedPlayer';
import moderatedOrdersStore from '../../../stores/moderatedOrders';

function ProductOrdersListScreen({ player, orders, initialFilterTagId, setting }) {
  const [currentFilterTagId, setCurrentFilterTagId] = useState(initialFilterTagId);

  const handleTagClick = (id) => {
    setCurrentFilterTagId(id);
  };

  const collectedTags = removeDuplicates(
    orders.flatMap((item) => item.tags), 'id',
  ).sort((a, b) => (a.position > b.position ? 1 : -1));

  const tagItems = collectedTags.map((tag) => (
    <li key={`tagItem_${tag.id}`}>
      <TagsFilterButton
        label={tag.title}
        onClick={() => handleTagClick(tag.id)}
        active={tag.id === currentFilterTagId}
      />
    </li>
  ));

  let currentOrders = orders;
  const { player: moderatedPlayer } = moderatedPlayerStore;

  if (isOrdersModerator(player)) {
    if (moderatedPlayer) {
      currentOrders = moderatedOrdersStore.orders;
    }

    useEffect(() => {
      moderatedOrdersStore.fetchOrders();
    }, [moderatedPlayer]);
  }

  return (
    <>
      {isOrdersModerator(player) && <ModeratedPlayer />}

      {!moderatedPlayer && <PlayerChest player={player} />}

      {!moderatedPlayer && tagItems.length > 0 && (
        <TagsFilter>
          <TagsFilterList>
            <li>
              <TagsFilterButton
                label="Все"
                onClick={() => handleTagClick(null)}
                active={currentFilterTagId === null}
              />
            </li>
            {tagItems}
          </TagsFilterList>
        </TagsFilter>
      )}

      <ProductOrderCardsList
        filterTagId={moderatedPlayer ? null : currentFilterTagId}
        items={currentOrders}
        player={player}
        setting={setting}
      />
    </>
  );
}

ProductOrdersListScreen.propTypes = {
  player: PropTypes.shape({
    balance_player_points: PropTypes.number,
    balance_team_points: PropTypes.number,
  }).isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape({
    tags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
  })),
  initialFilterTagId: PropTypes.number,
  setting: PropTypes.shape({
    img_url_card: PropTypes.string.isRequired,
    title_card: PropTypes.string.isRequired,
    text_card: PropTypes.string.isRequired,
    description_card: PropTypes.string.isRequired,
  }).isRequired,
};
//
ProductOrdersListScreen.defaultProps = {
  orders: [],
  initialFilterTagId: null,
};

export default observer(ProductOrdersListScreen);
