import React from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';

import NotificationPaymentCard from '../../components/NotificationCards/Payment';
import NotificationTaskCard from '../../components/NotificationCards/Task';
import NotificationProductCard from '../../components/NotificationCards/Product';
import NotificationPlayerTeamCard from '../../components/NotificationCards/PlayerTeam';
import NotificationTeamCard from '../../components/NotificationCards/Team';
import NotificationInviteToTeamCard from '../../components/NotificationCards/InviteToTeam';
import NotificationInviteToTeamRejectedCard from '../../components/NotificationCards/InviteToTeamRejected';
import NotificationEventCard from '../../components/NotificationCards/Event';

const notificationCards = {
  /* eslint-disable camelcase */
  task_pending: NotificationTaskCard,
  task_step_pending: NotificationTaskCard,
  task_invite_pending: NotificationTaskCard,
  task_completed: NotificationTaskCard,
  task_step_completed: NotificationTaskCard,
  task_invite_completed: NotificationTaskCard,
  task_failed: NotificationTaskCard,
  task_step_failed: NotificationTaskCard,
  task_rejected: NotificationTaskCard,
  task_pending_to_available: NotificationTaskCard,
  task_fail_invite: NotificationTaskCard,

  product_bought: NotificationProductCard,
  product_returned: NotificationProductCard,
  product_for_registration: NotificationProductCard,
  registration_award_returned: NotificationProductCard,
  // product_sent: ProductSent,

  player_leaves_team: NotificationPlayerTeamCard,
  player_joins_team: NotificationPlayerTeamCard,
  team_created: NotificationTeamCard,
  team_removed: NotificationTeamCard,

  invite_to_team: NotificationInviteToTeamCard,
  invite_to_team_rejected: NotificationInviteToTeamRejectedCard,
  player_invited_to_team: NotificationPlayerTeamCard,

  payment_income: NotificationPaymentCard,
  payment_outcome: NotificationPaymentCard,

  event_confirmed: NotificationEventCard,
  /* eslint-enable camelcase */
};

function NotificationItem({ notification, onBecomeVisible }) {
  const NotificationCard = notificationCards[notification.action];

  const handleVisibilityChange = (isVisible) => {
    if (isVisible && !notification.viewed) {
      onBecomeVisible(notification);
    }
  };

  return (
    <VisibilitySensor onChange={handleVisibilityChange}>
      <li>
        <NotificationCard
          notification={notification}
        />
      </li>
    </VisibilitySensor>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    action: PropTypes.string.isRequired,
    viewed: PropTypes.bool,
  }).isRequired,
  onBecomeVisible: PropTypes.func.isRequired,
};

export default NotificationItem;
