import React from 'react';
import PropTypes from 'prop-types';

import Card from '../../../../../components/Card';
import TaskTitle from '../../../components/Title';

import styles from './CardCompleted.module.scss';

function TaskCardCompleted({ task, onVisit }) {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href=""
      onClick={onVisit}
    >
      <Card className={styles.CardCompleted}>
        <TaskTitle
          noBottomSpace
          task={task}
          statusIcon={task.status}
        />
      </Card>
    </a>
  );
}

TaskCardCompleted.propTypes = {
  task: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  onVisit: PropTypes.func.isRequired,
};

export default TaskCardCompleted;
