import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';
import classNames from 'classnames';
import BottomSlideUp from '../../components/BottomSlideUp';
import NewsArticleShowScreen from './Show';

import IconSvg from '../../../../common/javascripts/components/IconSvg';

import styles from './NewsArticle.module.scss';
import clickCounter from '../../helpers/counter';

function NewsArticleItem({ newsArticle, onBecomeVisible, onBecomeReadable }) {
  const [showSlideUp, setShowSlideUp] = useState(false);
  const [articleViewed, setArticleViewed] = useState(newsArticle.viewed);

  // const handleVisibilityChange = (isVisible) => {
  //   if (isVisible && !newsArticle.viewed) {
  //     onBecomeVisible(newsArticle);
  //   }
  // };

  const markAsRead = async (article) => {
    setArticleViewed(true);
    try {
      const response = await fetch(`/notifications/news/${article.id}.json`);

      const result = await response.json();

      if (response.ok) {
        setArticleViewed(result.newsArticle.viewed);
        onBecomeReadable(result.newsArticle);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickArticle = () => {
    setShowSlideUp(true);
    clickCounter(`/notifications/news/${newsArticle.id}`, newsArticle);
  };

  const handleCloseArticle = () => {
    if (!articleViewed) markAsRead(newsArticle);
    setShowSlideUp(false);
  };

  const cardClass = classNames(styles.Article, { [styles.Article_unread]: !articleViewed });

  return (
    <>
      {/* <VisibilitySensor onChange={handleVisibilityChange}> */}
      <li>
        <a className={cardClass} onClick={handleClickArticle}>
          {newsArticle.sticky && <IconSvg icon="sticky" className={styles.Article__stickyIcon} />}
          <div className={styles.Article__date}>{newsArticle.publication_date}</div>
          <div className={styles.Article__title}>{newsArticle.title}</div>
          {
            newsArticle.short_description.length > 0
            && (
              <div className={styles.Article__shortDescription}>
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: newsArticle.short_description }} />
                <span className={styles.Article__moreLink}> подробнее</span>
              </div>
            )
          }
        </a>
      </li>
      {/* </VisibilitySensor> */}
      <BottomSlideUp show={showSlideUp} onClose={handleCloseArticle}>
        <NewsArticleShowScreen newsArticle={JSON.stringify(newsArticle)} />
      </BottomSlideUp>
    </>
  );
}

/* eslint-disable camelcase */
NewsArticleItem.propTypes = {
  newsArticle: PropTypes.shape({
    id: PropTypes.number,
    sticky: PropTypes.bool,
    viewed: PropTypes.bool,
    title: PropTypes.string,
    short_description: PropTypes.string,
    publication_date: PropTypes.string,
  }).isRequired,
  onBecomeVisible: PropTypes.func.isRequired,
  onBecomeReadable: PropTypes.func.isRequired,
};
/* eslint-enable camelcase */

export default NewsArticleItem;
