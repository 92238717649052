import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';


import NotificationCard from '../components/Card';
import NotificationCardHeader from '../components/Header';
import NotificationCardFooter from '../components/Footer';
import NotificationCardPoints from '../components/Points';
import NotificationTitle from '../components/Title';

import styles from './Payment.module.scss';

const paymentKindToPointsKind = {
  payment_income: 'income',
  payment_outcome: 'expense',
};

export default function NotificationPaymentCard({ notification }) {
  if (!notification) return null;

  return (
    <NotificationCard notification={notification}>
      <NotificationCardHeader
        id={notification.notificationable_id}
      />
      <NotificationTitle title={notification.data.title} />
      {
        notification.data.body
        && (
          <div className={styles.Payment__text}>{notification.data.body}</div>
        )
      }

      <NotificationCardFooter>
        <NotificationCardPoints
          playerPoints={notification.data.player_points}
          teamPoints={notification.data.team_points}
          bonusPoints={notification.data.bonus_points}
          kind={paymentKindToPointsKind[notification.action]}
        />
      </NotificationCardFooter>
    </NotificationCard>
  );
}

NotificationPaymentCard.propTypes = {
  notification: PropTypes.object.isRequired,
};
