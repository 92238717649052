import React, { useState, useEffect } from 'react';
import TopPageNotice from './TopPageNotice';

export default function RailsTopPageNotice({ notice }) {
  const [noticeToRender, setNoticeToRender] = useState('');

  const handleClose = () => {
    setNoticeToRender('');
  };

  useEffect(() => {
    const openTimeout = setTimeout(() => setNoticeToRender(notice), 500);
    const closeTimeout = setTimeout(() => handleClose(), 3000);

    return () => {
      clearTimeout(openTimeout);
      clearTimeout(closeTimeout);
    };
  }, []);

  return <TopPageNotice notice={noticeToRender} onClose={handleClose} />;
}
