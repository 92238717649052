/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isBefore from 'date-fns/isBefore';
import { currentMoscowDate, dateToMoscowZone } from '../../../../../helpers/dates';
import { horizontalScrollElementToCenter } from '../../../../../helpers/scroll';

import styles from './EventDaysFilter.module.scss';

const inPast = (date) => {
  const d = new Date(date);
  d.setHours(23, 59, 59, 999);
  return isBefore(dateToMoscowZone(d), currentMoscowDate);
};

export default function EventDaysFilter({ filter, days, onFilterChange }) {
  if ((days || []).length === 0) return null;

  const listRef = useRef(null);
  const currentDayRef = useRef(null);

  const handleFilterClick = (newFilter) => {
    if (newFilter !== filter) {
      onFilterChange(newFilter);
    }
  };

  useEffect(() => {
    if (currentDayRef.current && listRef.current) {
      horizontalScrollElementToCenter({
        getContainer: () => listRef.current,
        getTarget: () => currentDayRef.current,
      });
    }
  }, [filter, days]);

  return (
    <ul className={styles.DaysFilter} ref={listRef}>
      {days.map((day) => (
        <li
          className={cn(
            styles.DaysFilter__item,
            { [styles.DaysFilter__item_active]: filter === day.date },
            { [styles.DaysFilter__item_past]: inPast(day.date) },
          )}
          ref={filter === day.date ? currentDayRef : null}
          key={day.id}
          onClick={() => handleFilterClick(day.date)}
          disabled={filter === day.date}
        >
          <div className={styles.DaysFilter__description}>
            {day.description}
          </div>
          <div className={styles.DaysFilter__day}>
            {day.day}
          </div>
          <div className={styles.DaysFilter__month}>
            {day.month}
          </div>
        </li>
      ))}
    </ul>
  );
}

EventDaysFilter.propTypes = {
  filter: PropTypes.string,
  days: PropTypes.array,
  onFilterChange: PropTypes.func.isRequired,
};
