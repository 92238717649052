import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

// import actionCable from 'actioncable';
import PageContentOtherBg from '../../components/PageContentOtherBg';
import MarkAsReadButton from '../../components/MarkAsReadButton';
import NotificationNavigation from '../../components/NotificationNavigation';
import PageHeader from '../../components/PageHeader';
import PageCardsList from '../../components/PageCardsList';
import NewsArticleItem from './NewsArticleItem';
import AnniversaryBanner from '../../components/AnniversaryBanner/AnniversaryBanner';

import newsArticleStore from '../../stores/newsArticles';
import playerViewStore from '../../stores/playerViews';
import ProgramMemberButton from '../../components/ProgramMemberButton/ProgramMemberButton';

function NewsArticlesListScreen({ markVisibleAsViewed, current_player, settings, isOnlyQuest }) {
  const {
    newsArticles,
    isLoading,
    hasMoreNewsArticles,
    fetchNewsArticles,
    fetchMoreNewsArticles,
    markAsViewed,
    markAllAsViewed,
    isMarkingAll,
  } = newsArticleStore;

  // const CableApp = {};

  // CableApp.cable = actionCable.createConsumer('/cable');

  const handleShowMore = () => fetchMoreNewsArticles();

  const handleNewsArticleBecomeVisible = (newsArticle) => {
    if (markVisibleAsViewed) {
      markAsViewed(newsArticle.id);
    }
  };

  const handleNewsArticleBecomeReadable = (newsArticle) => {
    markAsViewed(newsArticle.id);
  };

  const handleViewAllClick = () => {
    markAllAsViewed();
  };

  useEffect(() => {
    fetchNewsArticles();
    // try {
    //   CableApp.cable.subscriptions.create(
    //     {
    //       channel: 'NotificationsChannel',
    //       player_id: current_player.id,
    //     },
    //     {
    //       received: (data) => {
    //         console.log('NewsArticlesListScreen received =>', data);
    //         if (data.notification.id) {
    //           console.log('NewsArticlesListScreen data.notification.id =>', data.notification.id);
    //           playerViewStore.fetchUnread();
    //         }
    //       },
    //     },
    //   );
    //   console.log('NewsArticlesListScreen => ', CableApp);
    // } catch (error) {
    //   console.log('NewsArticlesListScreen => ', error);
    // }
  }, []);

  return (
    <>
      <NotificationNavigation activeTab={0} isOnlyQuest={isOnlyQuest} />

      <PageContentOtherBg>
        {!current_player.program_member && <ProgramMemberButton settings={settings.data} /> }

        <PageHeader>
          <h1>Новости</h1>
          <MarkAsReadButton
            label="Прочитать все"
            onClick={handleViewAllClick}
            disabled={isMarkingAll || playerViewStore.unreadNews <= 0}
          />
        </PageHeader>

        <PageCardsList>
          {
            newsArticles.map((article) => (
              <NewsArticleItem
                key={article.id}
                newsArticle={toJS(article)}
                onBecomeVisible={handleNewsArticleBecomeVisible}
                onBecomeReadable={handleNewsArticleBecomeReadable}
              />
            ))
          }
        </PageCardsList>
        {
          isLoading
          && <button type="button" className="button button_large button_fullwidth" disabled> Загружаем...</button>
        }
        {
          hasMoreNewsArticles && !isLoading
          && (
            <button type="button" className="button button_large button_fullwidth" onClick={handleShowMore}>
              Показать еще
            </button>
          )
        }
      </PageContentOtherBg>
    </>
  );
}

NewsArticlesListScreen.propTypes = {
  markVisibleAsViewed: PropTypes.bool,
  current_player: PropTypes.shape({
    id: PropTypes.number,
    program_member: PropTypes.bool,
  }).isRequired,
  current_player: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  settings: PropTypes.shape({
    data: PropTypes.shape({
      bracelet_text: PropTypes.string,
    }),
  }).isRequired,
  isOnlyQuest: PropTypes.bool,
};

NewsArticlesListScreen.defaultProps = {
  markVisibleAsViewed: false,
  isOnlyQuest: false,
};

export default observer(NewsArticlesListScreen);
