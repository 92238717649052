import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import CardTag from '../CardTag';
import styles from './CardTagsList.module.scss';

export default function CardTagsList({ tags, className }) {
  if (!tags) return null;

  return (
    <ul className={cn(className, styles.CardTagsList)}>
      {tags.map((tag) => (
        <li key={tag.id}>
          <CardTag tag={tag} />
        </li>
      ))}
    </ul>
  );
}

CardTagsList.propTypes = {
  tags: PropTypes.instanceOf(Array),
  className: PropTypes.string,
};

CardTagsList.defaultProps = {
  tags: [],
  className: null,
};
