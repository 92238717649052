import * as React from 'react';
import cn from 'classnames';

import * as styles from './AnniversaryBanner.module.scss';

export default function AnniversaryBanner({ withBottomSpace = false }) {
  return (
    <div className={cn(
      styles.AnniversaryBanner,
      { [styles.AnniversaryBanner_withBottomSpace]: withBottomSpace },
    )}
    >
      <div className={styles.AnniversaryBanner__content}>
        <a
          href="/help/game_history"
          className={styles.AnniversaryBanner__link}
          rel="norefferer"
          target="_blank"
        >
          Архив победителей
        </a>
      </div>
    </div>
  );
}
