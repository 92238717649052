import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import IconSvg from '../../../../../../../common/javascripts/components/IconSvg';

import styles from './Favourite.module.scss';

export default function EventFavourite({
  favourite, onClick, className,
}) {
  return (
    <button
      className={cn(className, styles.Favourite, styles[`Favourite_${favourite}`])}
      type="button"
      onClick={onClick}
    >
      <IconSvg icon={favourite ? 'fav-filled' : 'fav'} />
      {favourite ? 'удалить из избранного' : 'добавить в избранное'}
    </button>
  );
}

EventFavourite.propTypes = {
  favourite: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

EventFavourite.defaultProps = {
  className: null,
};
