import React from 'react';
import PropTypes from 'prop-types';
import IconSvg from '../../../../../../../common/javascripts/components/IconSvg';

import styles from './AttachmentItem.module.scss';

export default function ProductOrderAttachmentItem({ item }) {
  return (
    <a
      href={item.url}
      className={styles.AttachmentItem}
      target="_blank"
      rel="noreferrer"
      download
    >
      <IconSvg icon="download" />
      <span className={styles.AttachmentItem__url}>
        {item.name}
      </span>
    </a>
  );
}

ProductOrderAttachmentItem.propTypes = {
  item: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};
