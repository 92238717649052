import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Task from './components/Task';
import { scrollToElement } from '../../../helpers/scroll';
import TasksApi from '../../../stores/tasksApi';
import { taskStepElementId } from '../../../helpers/tasks';
import ModeratedPlayer from '../../../components/ModeratedPlayer';
import { isTaskModerator } from '../../../helpers/players';

const tasksApi = new TasksApi();

function TaskShowScreen({
  task,
  player,
  contact,
  token,
  setting,
}) {
  const [currentTask, setTask] = useState(task);

  // TODO: extract this hardcode to web ui
  // make 2 different types of contacts: for quest and general
  // const contact = {
  //   phone: '+7 (916) 666-28-48',
  //   telegram: 'RosaFest2022',
  //   whatsapp: null,
  //   email: null,
  // };

  const handleFetchTask = async () => {
    const updatedTask = await tasksApi.fetchTask(task.id);
    if (updatedTask) {
      setTask(updatedTask);
    }
  };

  const handleTaskAnswer = async () => {
    const updatedTask = await tasksApi.fetchTask(task.id);

    if (updatedTask) {
      setTask(updatedTask);

      if ((updatedTask.task_steps || []).length <= 1) return;

      const nextAvailableStep = updatedTask.task_steps.find(({ state }) => state === 'available');
      if (nextAvailableStep) {
        scrollToElement({
          getTarget: () => document.getElementById(taskStepElementId(nextAvailableStep.id)),
        });
      }
    }
  };

  return (
    <>
      {isTaskModerator(player) && <ModeratedPlayer />}
      <Task
        task={currentTask}
        token={token}
        player={player}
        onTaskFetch={handleFetchTask}
        contact={contact}
        onTaskAnswer={handleTaskAnswer}
        setting={setting}
      />
    </>
  );
}

TaskShowScreen.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  contact: PropTypes.arrayOf(PropTypes.shape({
    phone: PropTypes.string,
  })).isRequired,
  player: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  token: PropTypes.string,
  setting: PropTypes.shape({
    img_url_card: PropTypes.string.isRequired,
    title_card: PropTypes.string.isRequired,
    text_card: PropTypes.string.isRequired,
    description_card: PropTypes.string.isRequired,
    task_show_notice: PropTypes.string.isRequired,
  }).isRequired,
};

TaskShowScreen.defaultProps = {
  token: '',
};

export default TaskShowScreen;
