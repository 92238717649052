import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconSvg from '../../../../../../common/javascripts/components/IconSvg';

import styles from './Time.module.scss';

export default function TaskTime({ task }) {
  return (
    <div className={styles.TaskTime}>
      <div className={styles.TaskTime__info}>
        <div className={styles.TaskTime__infoSection}>
          <IconSvg icon="task-time-flag-start" className={cn(styles.TaskTime__icon, styles.TaskTime__icon_start)} />
          <div className={styles.TaskTime__datetime}>
            <div className={styles.TaskTime__date}>{task.start_at.date}</div>
            <div className={styles.TaskTime__time}>{task.start_at.time}</div>
          </div>
        </div>
        <div className={cn(styles.TaskTime__infoSection, styles.TaskTime__infoSection_right)}>
          <div className={styles.TaskTime__datetime}>
            <div className={styles.TaskTime__date}>{task.end_at.date}</div>
            <div className={styles.TaskTime__time}>{task.end_at.time}</div>
          </div>
          <IconSvg icon="task-time-flag-end" className={cn(styles.TaskTime__icon, styles.TaskTime__icon_end)} />
        </div>
      </div>

      <div className={styles.TaskTime__timeLine}>
        <div className={styles.TaskTime__timeSpent} style={{ width: `${task.time_passed_in_percent}%` }} />
      </div>
    </div>
  );
}

TaskTime.propTypes = {
  task: PropTypes.shape({
    start_at: PropTypes.shape({
      date: PropTypes.string,
      time: PropTypes.string,
    }),
    end_at: PropTypes.shape({
      date: PropTypes.string,
      time: PropTypes.string,
    }),
    time_passed_in_percent: PropTypes.number,
  }).isRequired,
};
