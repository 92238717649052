/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconSvg from '../../../../../../common/javascripts/components/IconSvg';
import { headersWithToken } from '../../../../../../common/javascripts/fetchOptions';

import TopPageNotice from '../../../../components/TopPageNotice';

import styles from './Booster.module.scss';

const boosterTitle = {
  teleport: 'Телепорт',
  hint: 'Подсказка',
  // eslint-disable-next-line camelcase
  second_chance: 'Второй Шанс',
};

const boosterLabel = {
  teleport: 'Введите код, чтобы активировать телепорт',
  hint: 'Введите код, чтобы активировать посказку',
  second_chance: 'Введите код, чтобы активировать второй шанс',
};

const shopPath = '/shop/products';

export default function Booster({
  taskId, taskStepId, booster, onBoosterUse,
}) {
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [notice, setNotice] = useState();

  const inputId = `booster${booster.type}_${booster.id}`;
  const isCodeEmpty = code === '';

  const handleChange = (e) => {
    setCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setNotice('');
    setIsLoading(true);

    const data = {
      task_id: taskId,
      task_step_id: taskStepId,
      code,
    };

    try {
      const response = await fetch('/task_codes/use.json', {
        method: 'PATCH',
        headers: headersWithToken(),
        body: JSON.stringify(data),
      });
      const result = await response.json();

      setIsLoading(false);

      if (response.ok) {
        setNotice(result.notice);
        if (result.status === 'ok') {
          onBoosterUse(taskId);
        }
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const buyCodeLink = (
    <a
      href={shopPath}
      target="_blank"
      rel="noreferrer"
      className={styles.Booster__inputLink}
    >
      Купить код
    </a>
  );

  const activateCodeLink = (
    <button
      onClick={handleSubmit}
      className={styles.Booster__inputLink}
      type="submit"
    >
      Активировать
    </button>
  );

  return (
    <>
      <TopPageNotice notice={notice} onClose={() => setNotice(null)} />

      <form onSubmit={handleSubmit} className={styles.Booster}>
        <div className={styles.Booster__title}>
          Суперсила
          <br />
          {boosterTitle[booster.type]}
          <IconSvg icon={`booster-${booster.type}`} />
        </div>

        <label className={styles.Booster__label} htmlFor={inputId}>
          {boosterLabel[booster.type]}
        </label>

        <div className={styles.Booster__inputWrap}>
          {isCodeEmpty ? buyCodeLink : activateCodeLink}
          <input
            type="text"
            placeholder="Код активации"
            className="input input_large"
            onChange={handleChange}
            value={code}
            disabled={isLoading}
            id={inputId}
          />
        </div>
      </form>
    </>
  );
}

Booster.propTypes = {
  taskId: PropTypes.number,
  taskStepId: PropTypes.number,
  booster: PropTypes.object,
  onBoosterUse: PropTypes.func.isRequired,
};
