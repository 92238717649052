import React from 'react';
import PropTypes from 'prop-types';

import styles from './VariantItem.module.scss';

export default function ProductOrderVariantItem({ item }) {
  return (
    <div className={styles.VariantItem}>
      <div className={styles.VariantItem__row}>
        <span className={styles.VariantItem__title}>{item.variant_short_title || item.variant_full_title}</span>
        <span className={styles.VariantItem__amount}>
          <strong>{item.amount}</strong>
          &nbsp;шт.
        </span>
      </div>
    </div>
  );
}
