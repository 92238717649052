import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BottomSlideUp from '../../../../../components/BottomSlideUp';
import PageCardsList from '../../../../../components/PageCardsList';
import ProductListCard from '../../../components/ListCard';
import ProductOrderShowScreen from '../../../OrderShow';
import { isOrdersModerator } from '../../../../../helpers/players';
import TinkoffPromoCard from '../../../../../components/TinkoffPromoCard';

import styles from './CardsList.module.scss';
import ModeratedOrder from '../../../../../components/ModeratedOrder';

export default function ProductOrderCardsList({ items, filterTagId, player, setting }) {
  const [showSlideUp, setShowSlideUp] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [currentContact, setCurrentContact] = useState(null);

  const getOrder = async (item) => {
    try {
      const response = await fetch(`/shop/product_orders/${item.id}.json`);
      const data = await response.json();

      if (data.status === 'ok') {
        setCurrentOrder(data.order);
        setCurrentContact(data.contact);
        setShowSlideUp(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClicOrder = (item) => {
    getOrder(item);
  };

  const handleCloseOrder = () => {
    setCurrentOrder(null);
    setCurrentContact(null);
    setShowSlideUp(false);
  };

  let sortedOrders = items.sort((a, _) => (a.state === 'paid' || a.state === 'paid_ready' ? -1 : 1));
  sortedOrders = items.sort((a, b) => {
    if (a.state === 'paid' || a.state === 'paid_ready') {
      return b.id - a.id;
    }
    return true;
  });
  const orderItems = sortedOrders.map((item) => {
    if (filterTagId === null || item.tags.find((obj) => obj.id === filterTagId)) {
      return (
        <li key={`cartItem_${item.id}`}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            // eslint-disable-next-line no-script-url
            href="javascript:void(0)"
            className={styles.ProductOrderCardsList__link}
            onClick={handleClicOrder.bind(null, item)}
          >
            <ProductListCard isOrder order={item} setting={setting} />
          </a>
          { isOrdersModerator(player) && <ModeratedOrder order={item} /> }
        </li>
      );
    }
    return null;
  });

  return (
    <>
      <PageCardsList>
        <li>
          <TinkoffPromoCard isBalance setting={setting} />
        </li>
        {orderItems}
      </PageCardsList>
      <BottomSlideUp show={showSlideUp} onClose={handleCloseOrder}>
        {currentOrder && (
          <ProductOrderShowScreen
            order={currentOrder}
            contact={currentContact}
          />
        )}
      </BottomSlideUp>
    </>
  );
}

ProductOrderCardsList.propTypes = {
  items: PropTypes.instanceOf(Array),
  filterTagId: PropTypes.number,
  setting: PropTypes.shape({
    img_url_card: PropTypes.string.isRequired,
    title_card: PropTypes.string.isRequired,
    text_card: PropTypes.string.isRequired,
    description_card: PropTypes.string.isRequired,
  }).isRequired,
};

ProductOrderCardsList.defaultProps = {
  items: [],
  filterTagId: null,
};
