import {
  makeObservable,
  observable,
  action,
  runInAction,
} from 'mobx';

import PlayerRanksApi from './playerRanksApi';

class PlayerRanksStore {
  playerRanks = []

  isLoading = false

  constructor(playerRanksApi) {
    makeObservable(this, {
      playerRanks: observable,
      isLoading: observable,
      fetchPlayerRanks: action,
    });
    this.playerRanksApi = playerRanksApi;
  }

  fetchPlayerRanks = async () => {
    this.playerRanks = [];
    this.isLoading = true;

    try {
      const data = await this.playerRanksApi.fetchPlayerRanks();

      runInAction(() => {
        this.isLoading = false;
        this.playerRanks = data.player_ranks;
      });
    } catch (error) {
      console.error(error);
    }
  }
}

export default new PlayerRanksStore(new PlayerRanksApi());
