import { headersWithToken } from '../../../common/javascripts/fetchOptions';

const clickCounter = async (path, elem) => {
  if (!elem) return;

  try {
    const response = await fetch(`${path}/add_counter.json`, {
      method: 'PATCH',
      headers: headersWithToken(),
    });
  } catch (error) {
    console.error('Fetch error:', error);
  }
};

export default clickCounter;
