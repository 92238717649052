import React from 'react';
import PropTypes from 'prop-types';

import Card from '../../../../../components/Card';

import TaskTitle from '../../../components/Title';
import TaskTime from '../../../components/Time';
import TaskImage from '../../../components/Image';
import TaskTags from '../../../components/Tags';
import TaskCost from '../../../components/Cost';
import TaskDescription from '../../../components/Description';
import TaskStepsProgressRing from '../../../components/StepsProgressRing';

import styles from './CardAvailable.module.scss';

function TaskCardAvailable({ task, onVisit }) {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href=""
      onClick={onVisit}
    >
      <Card className={styles.CardAvailable}>
        <div className={styles.CardAvailable__header}>
          <TaskTitle task={task} />
          <TaskStepsProgressRing
            totalSteps={task.steps_count}
            doneSteps={task.completed_steps_count}
          />
        </div>
        <TaskTime task={task} />
        <TaskImage image={task.poster_url} />
        <TaskTags tags={task.tags} />
        <TaskDescription description={task.description_html} />
        {/* <TaskStepsProgess */}
        {/* totalSteps={task.steps_count} */}
        {/* doneSteps={task.completed_steps_count} */}
        {/* /> */}
        <TaskCost task={task} />
      </Card>
    </a>
  );
}

TaskCardAvailable.propTypes = {
  task: PropTypes.shape({
    poster_url: PropTypes.string,
    description_html: PropTypes.string,
    steps_count: PropTypes.number,
    completed_steps_count: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
  }).isRequired,
  onVisit: PropTypes.func.isRequired,
};

export default TaskCardAvailable;
