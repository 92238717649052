import React from 'react';
import PropTypes from 'prop-types';

import styles from './StartStep.module.scss';

export default function TeamPlayStartStep({ onNewStepSelect, onSearchStepSelect }) {
  return (
    <div className={styles.Step}>
      <button className={styles.Step__button} onClick={onNewStepSelect} type="button">
        <u>Создать</u>
        &nbsp;команду
      </button>
      <button className={styles.Step__button} onClick={onSearchStepSelect} type="button">
        <u>Присоединиться</u>
        &nbsp;к команде
      </button>
    </div>
  );
}

TeamPlayStartStep.propTypes = {
  onNewStepSelect: PropTypes.func.isRequired,
  onSearchStepSelect: PropTypes.func.isRequired,
};
