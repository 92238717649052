import React from 'react';
import PropTypes from 'prop-types';
import IconSvg from 'common/javascripts/components/IconSvg';
import Avatar from 'client/javascripts/components/Avatar';

import styles from './CardBase.module.scss';

export default function NotificationCardBase({
  title, text, icon, avatar, avatarType,
}) {
  const showIcon = icon;
  const showAvatar = avatarType && !icon;

  return (
    <div className={styles.NotificationCardBase}>

      {showIcon && <IconSvg icon={icon} className={styles.NotificationCardBase__icon} />}
      {
        showAvatar
        && (
          <div className={styles.NotificationCardBase__avatar}>
            <Avatar type={avatarType} avatar={avatar} />
          </div>
        )
      }

      <div className={styles.NotificationCardBase__content}>
        <div className={styles.NotificationCardBase__title}>
          {title}
        </div>
        {
          text
          && (
            <div
              className={styles.NotificationCardBase__text}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )
        }
      </div>
    </div>
  );
}

NotificationCardBase.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  avatar: PropTypes.string,
  avatarType: PropTypes.string,
  text: PropTypes.string
};
