import React from 'react';
import PropTypes from 'prop-types';

TaskStepsProgress.propTypes = {
  totalSteps: PropTypes.number,
  doneSteps: PropTypes.number
};

export default function TaskStepsProgress({ totalSteps, doneSteps }) {
  const afterCurrentStepsCount = totalSteps - doneSteps - 1;
  const isAllStepsDone = totalSteps === doneSteps

  return (
    <>
      { totalSteps > 1 &&
        <div className='taskCard__steps taskSteps'>
          <div className='taskSteps__title'>
            <span> Выполнено </span>
            <strong>{doneSteps} из {totalSteps}</strong>
            <span> шагов </span>
          </div>
          <div className='taskSteps__stepsLine'>
            {[ ...Array(doneSteps) ].map((step, index) => <div className='taskSteps__step taskSteps__step_done' key={index} />)}
            {!isAllStepsDone && <div className='taskSteps__step taskSteps__step_current' />}
            {afterCurrentStepsCount > 0 && [ ...Array(afterCurrentStepsCount) ].map((step, index) => <div className='taskSteps__step' key={index}/>)}
          </div>
        </div>
      }
    </>
  );
}
