import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Card from '../../../../../components/Card';

import TaskTitle from '../../../components/Title';
import TaskTime from '../../../components/Time';
import TaskImage from '../../../components/Image';
import TaskCost from '../../../components/Cost';
import BoostersList from '../../../components/BoostersList';
import TaskDescription from '../../../components/Description';

import styles from './CardBlocked.module.scss';

import { isTaskModerator } from '../../../../../helpers/players';

function TaskCardBlocked({
  task,
  player,
  onBoosterUse,
  onScrollToTask,
  onVisit,
}) {
  const teleportBoosters = task.boosters.filter((booster) => booster.type === 'teleport');

  const handleClickDependentTask = (taskId) => (event) => {
    event.stopPropagation();
    onScrollToTask(taskId);
  };

  const handleTaskClick = () => {
    if (isTaskModerator(player)) {
      onVisit();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      onClick={handleTaskClick}
      role="button"
      tabIndex={0}
    >
      <Card className={styles.CardBlocked}>
        <TaskTitle task={task} statusIcon={task.status} />
        <TaskTime task={task} />

        <BoostersList
          taskId={task.id}
          boosters={teleportBoosters}
          onBoosterUse={() => onBoosterUse(task.id)}
        />

        <TaskImage image={task.poster_url} />

        <TaskDescription description={task.description_html} />

        <div className={styles.CardBlocked__unblockTitle}>
          <span>задание станет доступным</span>
        </div>
        {(task.blocked_notices || []).map((item, index) => {
          if (item.type === 'dependent_task') {
            return (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <div
                /* eslint-disable-next-line react/no-array-index-key */
                key={`blockNotice${index}`}
                className={
                  cn(styles.CardBlocked__unblockNotice, styles.CardBlocked__unblockNotice_link)
                }
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: item.notice }}
                onClick={handleClickDependentTask(item.task_id)}
                onKeyDown={handleClickDependentTask(item.task_id)}
                role="button"
                tabIndex={index}
              />
            );
          }

          return (
            <div
              /* eslint-disable-next-line react/no-array-index-key */
              key={`blockNotice${index}`}
              className={styles.CardBlocked__unblockNotice}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: item.notice }}
            />
          );
        })}

        <TaskCost task={task} />
      </Card>
    </div>
  );
}

TaskCardBlocked.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    poster_url: PropTypes.string,
    boosters: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
    })),
    blocked_notices: PropTypes.arrayOf(PropTypes.shape({
      notice: PropTypes.string,
      type: PropTypes.string,
      task_id: PropTypes.number,
    })),
  }).isRequired,
  player: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onBoosterUse: PropTypes.func.isRequired,
  onScrollToTask: PropTypes.func.isRequired,
  onVisit: PropTypes.func.isRequired,
};

export default TaskCardBlocked;
