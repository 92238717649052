class TasksApi {
  fetchTask = async (id) => {
    const response = await fetch(`/tasks/${id}.json`);
    const result = await response.json();

    if (response.ok) {
      return result.task;
    }

    return null;
  }
}

export default TasksApi;
