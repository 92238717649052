import React from 'react';
import PropTypes from 'prop-types';

import PlayerRatingCard from '../../PlayerRatingCard';
import NotificationCard from '../components/Card';
import NotificationTitle from '../components/Title';
import TeamRatingCard from '../../TeamRatingCard/TeamRatingCard';

export default function NotificationPlayerTeamCard({ notification }) {
  const teamData = {
    title: notification.data.team_title,
    points: notification.data.team_points,
    avatar_url: notification.team_avatar_url,
    rating_position: notification.data.team_rating,
  };

  return (
    <NotificationCard notification={notification}>
      <NotificationTitle
        titleForNoHeaderCard
        title={notification.data.title}
      />
      {
        (notification.notice_player && !notification.is_current_team_player)
        && <PlayerRatingCard noPublicLink incard player={notification.notice_player} />
      }
      {
        (notification.is_current_team_player)
        && <TeamRatingCard team={teamData} incard noPublicLink />
      }
    </NotificationCard>
  );
}

NotificationPlayerTeamCard.propTypes = {
  notification: PropTypes.object.isRequired,
};
