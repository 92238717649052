/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Fireworks } from 'fireworks-js';

import Card from '../../../../components/Card';
import CardHeader from '../../../../components/CardHeader';
import CardTag from '../../../../components/CardTag';
import RandomWinner from '../RandomWinner';
import Winner from '../Winner';

import ContentNotice from '../../../../components/ContentNotice';

import styles from './WinnerAward.module.scss';

function WinnerAward({
  award,
  isAdmin,
  group,
  winner,
  announceDate,
  showLikePlayer,
}) {
  const fireRef = useRef();
  const fireworks = useRef();
  const [fire, setFire] = useState(false);

  useEffect(() => {
    if (fireRef.current) {
      fireworks.current = new Fireworks({
        target: fireRef.current,
        hue: 200,
        startDelay: 0.1,
        minDelay: 10,
        maxDelay: 15,
        speed: 8,
        acceleration: 1.1,
        friction: 0.95,
        gravity: 1.5,
        particles: 150,
        trace: 5,
        explosion: 10,
        boundaries: {
          top: 70,
          bottom: fireRef.current.clientHeight,
          left: 0,
          right: fireRef.current.clientWidth,
        },
      });

      if (fire) {
        fireworks.current.start();
      } else {
        fireworks.current.stop();
      }
    }
  }, [fireRef, fire]);

  const isRandomizer = group === 'randomizer';

  const handleFire = () => {
    setFire(true);

    setTimeout(() => setFire(false), 60000);
  };

  return (
    <>
      {award.slide_up_image_url && (
        <Card noPadding className={styles.WinnerAwardImage}>
          <div
            className={styles.WinnerAwardImage__img}
            style={{ backgroundImage: `url(${award.slide_up_image_url})` }}
          />
        </Card>
      )}
      <Card className={styles.WinnerAward}>
        <CardHeader>
          <CardTag tag={{ title: award.group_title, important: true }} />
          <h1>
            {`«${award.title.trim()}»`}
          </h1>
        </CardHeader>

        <div className={styles.WinnerAward__winnerWrap}>
          {isRandomizer && (
            <RandomWinner
              player={winner}
              award={award}
              isAdmin={isAdmin}
              onFire={handleFire}
            />
          )}
          {!isRandomizer && (
            <Winner
              winner={winner}
              announceDate={announceDate}
              isAdmin={isAdmin}
              onFire={handleFire}
              showLikePlayer={showLikePlayer}
            />
          )}
        </div>

        <ContentNotice notClosable>
          <div
            className={styles.WinnerAward__prize}
            dangerouslySetInnerHTML={{ __html: award.what_player_win }}
          />
        </ContentNotice>
      </Card>
      { fire && (
        <div
          style={{
            position: 'absolute',
            width: `${window.innerWidth}px`,
            height: '100%',
            top: '0',
            left: '0',
            zIndex: '1',
            background: 'rgba(0,0,0,.7)',
          }}
          onClick={() => setFire(false)}
          ref={fireRef}
        />
      )}
    </>
  );
}

WinnerAward.propTypes = {
  award: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    group_title: PropTypes.string,
    what_player_win: PropTypes.string,
    cover_url: PropTypes.string,
    slide_up_image_url: PropTypes.string,
  }).isRequired,
};

export default WinnerAward;
