require('turbolinks').start();
require('@rails/ujs').start();

import ReactRailsUJS from 'react_ujs';
ReactRailsUJS.useContext(require.context('.', true)); // Support component names relative to this directory
ReactRailsUJS.detectEvents();


/* React components */

import DigitPasswordInput from './components/DigitPasswordInput';
import RailsTopPageNotice from './components/TopPageNotice/RailsTopPageNotice';
import ContentNotice from './components/ContentNotice';
import DateInput from './components/DateInput';
import AvatarUploader from './components/AvatarUploader';
import Avatar from './components/Avatar';
import TextCopy from './components/TextCopy';
import TeamsRating from './components/TeamsRating';


window.DigitPasswordInput = DigitPasswordInput;
window.RailsTopPageNotice = RailsTopPageNotice;
window.ContentNotice = ContentNotice;
window.AvatarUploader = AvatarUploader;
window.DateInput = DateInput;
window.Avatar = Avatar;
window.TextCopy = TextCopy;
window.TeamsRating = TeamsRating;


// Screens

// Auth
import AuthScreen from './screens/Auth';
window.AuthScreen = AuthScreen;
// --
// Product
import ProductsListScreen from './screens/Product/List';
window.ProductsListScreen = ProductsListScreen;

import ProductShowScreen from './screens/Product/Show';
window.ProductShowScreen = ProductShowScreen;

import ProductOrdersListScreen from './screens/Product/OrdersList';
window.ProductOrdersListScreen = ProductOrdersListScreen;

import ProductOrderShowScreen from './screens/Product/OrderShow';
window.ProductOrderShowScreen = ProductOrderShowScreen;
// --

// JS
import './profileInputInstagram';
// --
