import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import TeamInvitePlayer from '../TeamInvitePlayer';
import BottomSlideUp from '../BottomSlideUp';
import IconSvg from '../../../../common/javascripts/components/IconSvg';

import styles from './TeamInvitePlayerBtn.module.scss';

export default function TeamInvitePlayerBtn({
  labelClass, iconClass, labelUnder, withLabel,
}) {
  const [showSlideUp, setShowSlideUp] = useState(false);

  const handleAddPlayerClick = () => {
    setShowSlideUp(true);
  };

  const handleSlideUpClose = () => {
    setShowSlideUp(false);
  };

  return (
    <>
      <button
        className={cn(styles.Button, { [styles.Button_labelUnder]: labelUnder })}
        type="button"
        onClick={handleAddPlayerClick}
      >
        <IconSvg icon="avatar-add" className={iconClass} />
        {withLabel && <div className={labelClass || styles.Button__label}>добавить игрока</div>}
      </button>

      <BottomSlideUp show={showSlideUp} onClose={handleSlideUpClose}>
        <TeamInvitePlayer />
      </BottomSlideUp>
    </>
  );
}

TeamInvitePlayerBtn.propTypes = {
  labelClass: PropTypes.string,
  iconClass: PropTypes.string,
  labelUnder: PropTypes.bool,
  withLabel: PropTypes.bool,
};

TeamInvitePlayerBtn.defaultProps = {
  labelClass: null,
  iconClass: null,
  labelUnder: false,
  withLabel: false,
};
