import React from 'react';
import PropTypes from 'prop-types';

import IconSvg from '../../../../common/javascripts/components/IconSvg';

import styles from './TeamRatingPosition.module.scss';

const ratingsPath = '/ratings/teams';

export default function TeamRatingPosition({ ratingPosition }) {
  if (!ratingPosition) return null;

  return (
    <a href={ratingsPath} className={styles.TeamRatingPosition}>
      <IconSvg icon="ratings-position" className={styles.TeamRatingPosition__ratingIcon} />
      <span className={styles.TeamRatingPosition__ratingLabel}>
        <strong>{`${ratingPosition} место`}</strong>
        <br />
        &nbsp;в рейтинге
      </span>
    </a>
  );
}

TeamRatingPosition.propTypes = {
  ratingPosition: PropTypes.number,
};

TeamRatingPosition.defaultProps = {
  ratingPosition: null,
};
