import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import cn from 'classnames';
import { format } from 'date-fns';
import { ru } from 'date-fns/esm/locale';

import TopPageNotice from '../../../../../components/TopPageNotice';
import Card from '../../../../../components/Card';
import CardHeader from '../../../../../components/CardHeader';
import CardTagsList from '../../../../../components/CardTagsList';
import CardImages from '../../../../../components/CardImages';
import CardVideos from '../../../../../components/CardVideos';
import CardAudios from '../../../../../components/CardAudios';
import FromEditor from '../../../../../components/FromEditor';

import EventCoverImage from '../../../components/CoverImage';
import EventTime from '../../../components/EventTime';
import EventLocation from '../../../components/Location';
import EventAward from '../Award';
import EventFavourite from '../Favourite';
import EventProfilesList from '../ProfilesList';
import EventPartnersList from '../PartnersList';
import EventRegistration from '../Registration';
import QRCodeShow from '../QRCodeShow';
import PlayerRate from '../../../../../components/PlayerRate';

import ModeratedEvent from '../ModeratedEvent';
import moderatedEventsStore from '../../../../../stores/moderatedEvents';
import currentPlayerStore from '../../../../../stores/currentPlayerStore';
import eventStore from '../../../../../stores/events';
import { isEventsModerator } from '../../../../../helpers/players';
import { headersWithToken } from '../../../../../../../common/javascripts/fetchOptions';

import styles from './EventCard.module.scss';

export default function EventCard({ initialEvent, day }) {
  const [notice, setNotice] = useState();
  const [event, setEvent] = useState(initialEvent);
  const [isModerateOpen, setIsModerateOpen] = useState(false);

  const { player } = currentPlayerStore;

  const {
    acceptEvent,
    rejectEvent,
    eventRegistration,
    resetRegistration,
    isEventUpdating,
  } = moderatedEventsStore;

  const {
    currentTypeEvent,
    removeEventFromFavourite,
    removeEventFromRegistration,
  } = eventStore;

  const submit = async (path, data) => {
    try {
      const response = await fetch(path, {
        method: 'POST',
        headers: headersWithToken(),
        body: JSON.stringify(data),
      });
      const result = await response.json();

      if (response.ok) {
        setEvent(result.event);
        setNotice(result.meta.notice);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const handleRegistration = (action) => {
    const data = {
      events_player: {
        event_id: event.id,
        player_id: player.id,
        date: day,
      },
    };
    submit(`/events_players/${action}`, data);

    if (currentTypeEvent === 'favourite') removeEventFromRegistration(event.id, day);
  };

  const handleClickFavourite = async () => {
    const data = {
      player_event_favourite: {
        event_id: event.id,
        date: day,
      },
    };
    const action = event.favourite ? 'unfavourite' : 'favourite';
    submit(`/player_event_favourites/${action}`, data);

    if (currentTypeEvent === 'favourite') removeEventFromFavourite(event.id);
  };

  const handleAcceptEvent = (eventId, eventDay) => {
    acceptEvent(eventId, eventDay);
  };

  const handleRejectEvent = (eventId, eventDay) => {
    rejectEvent(eventId, eventDay);
  };

  const handleClickModeratorRegistration = (eventId, eventDay) => {
    eventRegistration(eventId, eventDay);
  };

  const handleClickResetRegistration = (eventId, eventDay) => {
    resetRegistration(eventId, eventDay);
  };

  const handleErrorNotice = (error) => {
    if (error) setNotice(error);
  };

  return (
    <>
      {notice && (<TopPageNotice notice={notice} onClose={() => setNotice(null)} />)}
      {(isModerateOpen && isEventsModerator(player)) && (
        <ModeratedEvent
          event={event}
          eventModerator={player}
          day={day}
          actionsDisabled={isEventUpdating}
          onClose={() => setIsModerateOpen(false)}
          onAccept={handleAcceptEvent}
          onReject={handleRejectEvent}
          onRegistration={handleClickModeratorRegistration}
          onResetRegistration={handleClickResetRegistration}
          onErrorNotice={handleErrorNotice}
        />
      )}

      {event.current_player_registered && (
        <QRCodeShow
          event={event}
          player={player}
          day={day}
          onRegistration={handleRegistration}
        />
      )}

      <Card className={styles.EventCard}>
        {isEventsModerator(player) && (
          <button
            type="button"
            className="button button_fullwidth"
            onClick={() => setIsModerateOpen(true)}
            style={{ marginBottom: '20px' }}
          >
            Модерировать мероприятие
          </button>
        )}

        <CardTagsList
          className={styles.EventCard__tags}
          tags={[{ id: 1, title: event.tag_title }]}
        />

        <CardHeader bottomSpace="m">
          <h1>{event.name}</h1>
        </CardHeader>

        <EventFavourite
          onClick={handleClickFavourite}
          favourite={event.favourite}
        />

        <EventCoverImage
          imageUrl={event.cover_url}
          inCard
        />

        <div className={styles.EventCard__dateTime}>
          <div className={styles.EventCard__date}>
            {format(new Date(day), 'd MMMM (EEEEEE)', { locale: ru })}
          </div>
          <EventTime
            startAt={event.start_at}
            endAt={event.end_at}
          />
        </div>

        <EventLocation location={event.location} />

        <FromEditor className={styles.EventCard__description}>
          {event.description}
        </FromEditor>

        <EventAward
          playerPoints={event.player_points}
          teamPoints={event.team_points}
        />

        <CardImages
          images={event.images}
          startIndex={1}
        />
        <CardVideos videos={event.videos} />
        <CardAudios audios={event.audios} />

        <EventRegistration
          event={event}
          onAction={handleRegistration}
        />

        <PlayerRate
          paths={event.rate_paths}
          rate={event.rate}
          header="Нравится?"
        />

        <div className={styles.EventCard__bottomFav}>
          <EventFavourite
            onClick={handleClickFavourite}
            favourite={event.favourite}
          />
        </div>

      </Card>

      <EventProfilesList profiles={event.profiles} />
      <EventPartnersList partners={event.partners} contact={event.contact_for_partner} />
    </>
  );
}

EventCard.propTypes = {
  initialEvent: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    tag_title: PropTypes.string,
    description: PropTypes.string,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    free_places: PropTypes.number,
    age_restriction: PropTypes.number,
    cover_url: PropTypes.string,
    player_points: PropTypes.number,
    team_points: PropTypes.number,
    have_to_register: PropTypes.bool,
    register_start: PropTypes.bool,
    favourite: PropTypes.bool,
    current_player_registered: PropTypes.bool,
    rate: PropTypes.bool,
    rate_paths: PropTypes.shape({
      player_rate_up: PropTypes.string,
      player_rate_down: PropTypes.string,
      cancel_player_rate: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      address: PropTypes.string,
      latitude: PropTypes.string,
      longitude: PropTypes.string,
    }),
    partners: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
    images: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    })).isRequired,
    videos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    })).isRequired,
    audios: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    })).isRequired,
    profiles: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      cover_url: PropTypes.string,
    })),
  }).isRequired,
  day: PropTypes.string.isRequired,
};
