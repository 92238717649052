import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import QrScanner from 'qr-scanner';

// eslint-disable-next-line import/no-unresolved,import/extensions,import/no-webpack-loader-syntax
import QrScannerWorkerPath from '!!file-loader!qr-scanner/qr-scanner-worker.min.js';

import styles from './QRCodeScanner.module.scss';

QrScanner.WORKER_PATH = QrScannerWorkerPath;

class Camera extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    document.body.appendChild(this.el);
    this.startScanner();
  }

  componentWillUnmount() {
    if (this.qrScanner) {
      this.qrScanner.stop();
    }
    document.body.removeChild(this.el);
  }

  startScanner = () => {
    this.qrScanner = new QrScanner(this.videoEl, this.handleScan);
    this.videoEl.parentNode.append(this.qrScanner.$canvas);
    this.qrScanner.start().catch(this.handleError);
  };

  handleScan = (data) => {
    const { onScan } = this.props;
    if (data) {
      onScan(data);
    }
  };

  handleError = (err) => {
    const { onError } = this.props;
    onError(err);
  }

  render() {
    const { onClose } = this.props;

    return createPortal((
      <div
        role="button"
        onClick={onClose}
        onKeyDown={onClose}
        tabIndex={0}
        className={styles.QRCodeScanner__wrapper}
      >
        <video
          muted
          ref={(el) => {
            this.videoEl = el;
          }}
          style={{ width: 0, height: 0 }}
        />
      </div>
    ), this.el);
  }
}

Camera.propTypes = {
  onClose: PropTypes.func,
  onScan: PropTypes.func,
  onError: PropTypes.func,
};

Camera.defaultProps = {
  onClose: () => {},
  onScan: () => {},
  onError: (err) => console.error(err),
};

export default Camera;
