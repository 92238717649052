import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { format, parseISO } from 'date-fns';

const dateMask = '99/99/9999';
const maskPlaceholder = 'ДД/ММ/ГГГГ';

const formatInitialDate = (date) => {
  if (date) {
    const isoDate = parseISO(date);

    return format(isoDate, 'dd/MM/yyyy');
  }
  return '';
};


export default function DateInput({ name, className, initialValue }) {
  const [date, setDate] = useState(() => formatInitialDate(initialValue));

  const dateToSubmit = () => {
    const [day, month, year] = date.split('/');

    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <input type='hidden' name={name} value={dateToSubmit()} />
      <InputMask
        mask={dateMask}
        maskPlaceholder={maskPlaceholder}
        alwaysShowMask
        onChange={(e) => setDate(e.target.value)}
        value={date}
      >
        <input
          className='input'
          type='text'
          inputMode='numeric'
        />
      </InputMask>
    </>
  );
}
