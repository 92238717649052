import { headersWithToken } from '../../../common/javascripts/fetchOptions';

class PlayerViewApi {
  fetchUnread = async () => (await fetch('/player_views/unread.json')).json()

  markAsViewed = async ({ notificationIds, newsArticleIds }) => {
    const response = await fetch('/player_views/view.json', {
      method: 'PATCH',
      body: JSON.stringify({
        /* eslint-disable camelcase */
        notification_ids: notificationIds,
        news_article_ids: newsArticleIds,
        /* eslint-enable camelcase */
      }),
      headers: headersWithToken(),
    });

    return response.json();
  }

  markAllNewsAsViewed = async () => {
    const response = await fetch('/player_views/view_all_news.json', {
      method: 'PATCH',
      headers: headersWithToken(),
    });

    return response.json();
  }

  markAllNotificationsAsViewed = async () => {
    const response = await fetch('/player_views/view_all_notifications.json', {
      method: 'PATCH',
      headers: headersWithToken(),
    });

    return response.json();
  }
}

export default PlayerViewApi;
