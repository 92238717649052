import React from 'react';
import PropTypes from 'prop-types';

import styles from './Image.module.scss';

export default function TaskAgeRestriction({ image }) {
  if (!image) {
    return null;
  }

  return (
    <div
      className={styles.Image}
      style={{ backgroundImage: `url(${image})` }}
    />
  );
}

TaskAgeRestriction.propTypes = {
  image: PropTypes.string,
};

TaskAgeRestriction.defaultProps = {
  image: null,
};
