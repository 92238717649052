import React from 'react';
import PropTypes from 'prop-types';
import Cost from '../../../../components/Cost';

import styles from './Cost.module.scss';

export default function TaskCost({ task }) {
  return (
    <div className={styles.Cost}>
      <Cost icon="player-points" amount={task.player_points} />
      <Cost icon="team-points" amount={task.team_points} />
      <Cost icon="bonus-points" amount={task.bonus_points} />
    </div>
  );
}

TaskCost.propTypes = {
  task: PropTypes.shape({
    player_points: PropTypes.number,
    team_points: PropTypes.number,
    bonus_points: PropTypes.number,
  }).isRequired,
};
