import React from 'react';
import PropTypes from 'prop-types';

import CallWriteSnippet from 'client/javascripts/components/CallWriteSnippet';
import AuthPageHeader from './../Header';

import styles from './../../Auth.module.scss';

AuthContacts.propTypes = {
  onClose: PropTypes.func,
  contact: PropTypes.arrayOf(PropTypes.shape({
    phone: PropTypes.string,
  })).isRequired,
};

export default function AuthContacts({ onClose, contact }) {
  return (
    <>
      <AuthPageHeader />

      <div className={styles.Auth__ctaText}>
        Есть вопросы?<br/>Свяжись с нами!
      </div>

      <div className={`${styles.Auth__step} ${styles.Auth__step_contacts}`}>
        <CallWriteSnippet contact={contact} />
      </div>


      <div className={styles.Auth__submit}>
        <a
          className='button button_large button_fullwidth'
          onClick={onClose}
        >
          Закрыть
        </a>
      </div>
    </>
  );
}
