import React from 'react';
import PropTypes from 'prop-types';

import CardHeader from '../../../../../components/CardHeader';
import QRCode from '../../../../../components/QRCode';

function QRCodeShow({ taskStep, player }) {
  const code = `${taskStep.id}#${player.id}`;

  return (
    <>
      <CardHeader bottomSpace="s">
        <h4>Покажи QR-код модератору</h4>
      </CardHeader>
      <center>
        <QRCode value={code} size={148} />
      </center>
    </>
  );
}

QRCodeShow.propTypes = {
  taskStep: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  player: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default QRCodeShow;
