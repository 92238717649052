import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import CardHeader from '../../../CardHeader';

import styles from './Title.module.scss';

function NotificationTitle({ title, titleForNoHeaderCard }) {
  if (!title) return null;

  return (
    <CardHeader className={cn(styles.Title,
      { [styles.Title_forNoHeaderCard]: titleForNoHeaderCard })}
    >
      <h4>{title}</h4>
    </CardHeader>
  );
}

NotificationTitle.propTypes = {
  title: PropTypes.string,
  titleForNoHeaderCard: PropTypes.bool,
};

NotificationTitle.defaultProps = {
  title: '',
  titleForNoHeaderCard: false,
};

export default NotificationTitle;
