import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TopAwardSlideUp from '../TopAwardSlideUp';

import styles from './TopAward.module.scss';
import clickCounter from '../../../../helpers/counter';

export default function TopAward({ topAward }) {
  const [showTopAward, setShowTopAward] = useState(false);

  const handleShowTopAward = () => {
    setShowTopAward(true);
    clickCounter(`/ratings/top_awards/${topAward.id}`, topAward);
  };

  return (
    <>
      { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */ }
      <div
        onClick={handleShowTopAward}
        className={styles.TopAward}
        role="button"
        tabIndex="0"
      >
        <div className={styles.TopAward__infoWrap}>
          <div className={styles.TopAward__title}>{topAward.title}</div>
          {
            topAward.subtitle
            && (
              <div className={styles.TopAward__subtitle}>{topAward.subtitle}</div>
            )
          }
          <span className={styles.TopAward__more}>Подробнее</span>
        </div>
        {topAward.cover_image_url && (
          <div
            className={styles.TopAward__image}
            style={topAward.cover_image_url && { backgroundImage: `url('${topAward.cover_image_url}')` }}
          />
        )}
      </div>
      <TopAwardSlideUp
        topAward={topAward}
        show={showTopAward}
        onClose={() => setShowTopAward(false)}
      />
    </>
  );
}

TopAward.propTypes = {
  topAward: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    cover_image_url: PropTypes.string,
  }).isRequired,
};
