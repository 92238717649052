import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { headersWithToken } from '../../../../../../../common/javascripts/fetchOptions';

import styles from './AttachmentUploader.module.scss';

const allowedExtensions = [
  'jpg',
  'jpeg',
  'png',
  'mp3',
  'wav',
  'mp4',
  'mov',
  'avi',
  'doc',
  'docx',
  'xls',
  'xlsx',
];
const allowedExtensionsRegexp = new RegExp(allowedExtensions.map((ext) => `\\.${ext}$`).join('|'), 'i');

function uploadPhotos(files) {
  const data = new FormData();
  for (let index = 0; index < files.length; index += 1) {
    data.append('files[]', files.item(index));
  }
  const options = {
    headers: headersWithToken().delete('Content-type'),
    method: 'POST',
    body: data,
  };

  return fetch('/task_answer_attachments/upload.json', options)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}

function hasAllowedExtension(file) {
  return allowedExtensionsRegexp.test(file.name);
}

function AttachmentUploader({ onUploadComplete, onError }) {
  const [isUploading, setIsUploading] = useState(false);

  const handleUploading = (event) => {
    const fileInput = event.target;

    for (let index = 0; index < fileInput.files.length; index += 1) {
      const file = fileInput.files.item(index);
      if (!hasAllowedExtension(file)) {
        onError(`Недопустимый файл: ${file.name}`);
        return;
      }
    }

    setIsUploading(true);
    uploadPhotos(fileInput.files).then((result) => {
      fileInput.value = '';
      setIsUploading(false);
      onUploadComplete(result);
    });
  };

  return (
    <button
      type="button"
      className={`${styles.AttachmentUploader}`}
      disabled={isUploading}
    >
      {isUploading && (
        <>
          <span className={`${styles.AttachmentUploader__fileUploadingSpinner}`} />
          <span>
            &nbsp;Загрузка...
          </span>
        </>
      )}
      {!isUploading && (
        <span>
          Нажмите, чтобы выбрать файлы
        </span>
      )}
      <input
        type="file"
        multiple
        disabled={isUploading}
        onChange={handleUploading}
        className={styles.AttachmentUploader__fileInput}
      />
    </button>
  );
}

AttachmentUploader.propTypes = {
  onUploadComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AttachmentUploader;
