import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
// import { range } from 'lodash';

export default function DigitPasswordInput({
  digits, name, className, onChange,
}) {
  const inputsRef = useRef([]);
  const [digitValues, setDigitValues] = useState([...Array(digits).fill('')]);

  useEffect(() => {
    onChange(digitValues.join(''));
  }, [digitValues]);

  const handleInput = (e, index) => {
    const newDigitValues = [...digitValues];
    const value = e.target.value.charAt(e.target.value.length - 1);
    const isNumber = /[0-9]/.test(value);

    if (value === '') {
      newDigitValues[index] = '';
      if (index > 0) {
        inputsRef.current[index - 1].focus();
      }
    } else if (isNumber) {
      newDigitValues[index] = value;
      if (index < digits - 1) {
        inputsRef.current[index + 1].focus();
      }
    }
    setDigitValues(newDigitValues);
  };

  const moveCursorToTheEnd = (el) => {
    el.setSelectionRange(1, 1);
  };

  const handleFocus = (e, index) => {
    moveCursorToTheEnd(inputsRef.current[index]);
  };

  const handleClick = (e, index) => {
    moveCursorToTheEnd(inputsRef.current[index]);
  };

  return (
    <div className={cn(className, 'digitPasswordInput')}>
      <input type="hidden" value={digitValues.join('')} name={name} />
      {[...Array(digits)].map((value, index) => (
        <input
          key={`input_${index}`}
          id={`${name}[${index}]`}
          className={cn('input', 'input_large', { input_filled: digitValues[index] !== '' })}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          value={digitValues[index]}
          ref={(el) => inputsRef.current[index] = el}
          onInput={(e) => handleInput(e, index)}
          onFocus={(e) => handleFocus(e, index)}
          onClick={(e) => handleClick(e, index)}
          onChange={(e) => e.preventDefault()}
        />
      ))}
    </div>
  );
}

DigitPasswordInput.propTypes = {
  digits: PropTypes.number.isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

DigitPasswordInput.defaultProps = {
  name: 'digitPasswordInput',
  className: null,
  onChange: () => {},
};
