import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BottomSlideUp from '../../../../../components/BottomSlideUp';
import InstagramButtonSlideUp from './SlideUp';

import styles from './InstagramButton.module.scss';

export default function InstagramButton({ hasProfile, onAnswer }) {
  const [showSlideUp, setShowSlideUp] = useState(false);

  const handleClick = () => {
    if (hasProfile) {
      onAnswer();
    } else {
      setShowSlideUp(true);
    }
  };

  const handleProfileSaved = () => {
    setShowSlideUp(false);
    setTimeout(() => onAnswer(), 500);
  };

  const className = `${styles.Button} button button_fullwidth button_large button_secondary`;

  return (
    <>
      <button
        type="button"
        className={className}
        onClick={handleClick}
      >
        Я выложил в Instagram
      </button>

      <BottomSlideUp show={showSlideUp} onClose={() => setShowSlideUp(false)}>
        <InstagramButtonSlideUp onPrifileSaved={handleProfileSaved} />
      </BottomSlideUp>
    </>
  );
}

InstagramButton.propTypes = {
  hasProfile: PropTypes.bool.isRequired,
  onAnswer: PropTypes.func.isRequired,
};
