import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconSvg from '../../../../../../common/javascripts/components/IconSvg';

import styles from './Title.module.scss';

export default function TaskTitle({ task, statusIcon, noBottomSpace }) {
  return (
    <div className={cn(styles.Title, { [styles.Title_noBottomSpace]: noBottomSpace })}>
      {task.title}
      <div className={styles.Task__iconsWrap}>
        {
          statusIcon
          && <IconSvg icon={`task-${statusIcon}`} className={styles.Title__icon} />
        }
      </div>
    </div>
  );
}

TaskTitle.propTypes = {
  task: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  statusIcon: PropTypes.string,
  noBottomSpace: PropTypes.bool,
};

TaskTitle.defaultProps = {
  statusIcon: null,
  noBottomSpace: false,
};
