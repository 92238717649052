import React from 'react';
import PropTypes from 'prop-types';
import IconSvg from '../../../../../../../common/javascripts/components/IconSvg';

import Cost from '../../../../../components/Cost';

import styles from './Award.module.scss';

export default function EventAward({
  playerPoints, teamPoints,
}) {
  if (!playerPoints && !teamPoints) return null;

  return (
    <div className={styles.Award}>
      <div className={styles.Award__header}>
        После визита на это мероприятие ты получишь
      </div>
      <div className={styles.Award__cost}>
        <IconSvg icon="payment-plus" className={styles.Award__costIcon} />
        <Cost icon="player-points" amount={playerPoints} />
        <Cost icon="team-points" amount={teamPoints} />
      </div>
    </div>
  );
}

EventAward.propTypes = {
  playerPoints: PropTypes.number,
  teamPoints: PropTypes.number,
};

EventAward.defaultProps = {
  playerPoints: null,
  teamPoints: null,
};
