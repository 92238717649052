import { headersWithToken } from '../../../common/javascripts/fetchOptions';

class OrdersApi {
  fetchOrders = async (playerId) => {
    const response = await fetch(`/moderator/players/${playerId}/product_orders`);
    return response.json();
  };

  completeOrder = async (playerId, orderId) => {
    const response = await fetch(`/moderator/players/${playerId}/product_orders/${orderId}/complete`, {
      method: 'PATCH',
      headers: headersWithToken(),
    });
    return response.json();
  };

  cancelOrder = async (playerId, orderId) => {
    const response = await fetch(`/moderator/players/${playerId}/product_orders/${orderId}/cancel`, {
      method: 'PATCH',
      headers: headersWithToken(),
    });
    return response.json();
  };
}

export default OrdersApi;
