import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import styles from './ModeratedPlayer.module.scss';
import { isPhoneValid, phoneMask } from '../../helpers/phone';
import PlayersApi from '../../stores/playersApi';

const playersApi = new PlayersApi();

function SearchPlayerForm({ onPlayerNotFound, onPlayerFound }) {
  const [phone, setPhone] = useState('');
  const [canSearch, setCanSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleBeforeMaskedStateChange = ({ nextState }) => {
    let { value } = nextState;
    const { enteredString } = nextState;
    const digits = enteredString ? enteredString.replace(/[^0-9]/g, '') : '';

    if (digits.length >= 10) {
      const maskPhone = digits.slice(-10);

      value = `+7 (${maskPhone.slice(0, 3)}) ${maskPhone.slice(3, 6)}-${maskPhone.slice(6, 8)}-${maskPhone.slice(8, 10)}`;
    }

    return {
      ...nextState,
      value,
    };
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleSearchClick = async () => {
    setIsLoading(true);

    try {
      const result = await playersApi.searchPlayerByPhone(phone);
      setIsLoading(false);

      if (result.status === 'ok') {
        onPlayerFound(result.player_id);
      } else {
        onPlayerNotFound();
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  useEffect(() => {
    setCanSearch(isPhoneValid(phone));
  }, [phone]);

  return (
    <form>
      <div className={styles.ModeratedPlayer__phoneInputWrap}>
        <InputMask
          mask={phoneMask}
          alwaysShowMask
          onChange={handlePhoneChange}
          value={phone}
          beforeMaskedStateChange={handleBeforeMaskedStateChange}
        >
          <input
            className="input input_attention"
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
          />
        </InputMask>
      </div>
      <button
        className="button button_fullwidth button_secondary"
        disabled={!canSearch || isLoading}
        onClick={handleSearchClick}
        type="submit"
      >
        Поиск
      </button>
    </form>
  );
}

SearchPlayerForm.propTypes = {
  onPlayerFound: PropTypes.func.isRequired,
  onPlayerNotFound: PropTypes.func.isRequired,
};

export default SearchPlayerForm;
