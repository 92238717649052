/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import IconSvg from '../../../../../../common/javascripts/components/IconSvg';

import styles from './MenuName.module.scss';

export default function TopbarMenuName({ name, linkUrl }) {
  return (
    <a href={linkUrl} className={styles.Name}>
      {name}
      <IconSvg icon="edit-circle" className={styles.Name__editIcon} />
    </a>
  );
}

TopbarMenuName.propTypes = {
  name: PropTypes.string,
  linkUrl: PropTypes.string,
};

TopbarMenuName.defaultProps = {
  name: '',
  linkUrl: null,
};
