import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import TaskStepRegular from '../StepRegular';
import TaskStepBlocked from '../StepBlocked';
import { isTaskModerator } from '../../../../../helpers/players';
import moderatedAnswersStore from '../../../../../stores/moderatedAnswers';
import moderatedPlayerStore from '../../../../../stores/moderatedPlayer';

function Steps({
  task,
  token,
  player,
  contact,
  onTaskAnswer,
  onTaskFetch,
}) {
  const {
    fetchAnswers,
  } = moderatedAnswersStore;

  const moderatedPlayer = moderatedPlayerStore.player;

  if (isTaskModerator(player)) {
    useEffect(() => {
      fetchAnswers(task.id);
    }, [moderatedPlayer]);
  }

  return task.task_steps.map((step, index) => {
    const props = {
      taskStep: step,
      task,
      stepNumber: index + 1,
      token,
      player,
      contact,
      onTaskFetch,
      onTaskAnswer,
      status: step.state,
    };
    const StepComponent = ['completed', 'pending', 'available', 'invite_completed', 'invite_pending', 'fail_invite'].includes(step.state) ? TaskStepRegular : TaskStepBlocked;

    return (
      <li key={`step${step.id}`}>
        { !isTaskModerator(player) && (
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          <StepComponent {...props} />
        )}

        { (isTaskModerator(player) && !moderatedPlayer) && (
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          <StepComponent {...props} />
        )}
      </li>
    );
  });
}

Steps.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    task_steps: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      state: PropTypes.string,
    })),
  }).isRequired,
  player: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  contact: PropTypes.arrayOf(PropTypes.shape({
    phone: PropTypes.string,
  })).isRequired,
  token: PropTypes.string.isRequired,
  onTaskFetch: PropTypes.func.isRequired,
  onTaskAnswer: PropTypes.func.isRequired,
};

export default observer(Steps);
