import React from 'react';
import PropTypes from 'prop-types';

import CardHeader from '../CardHeader';
import IconSvg from '../../../../common/javascripts/components/IconSvg';

import styles from './CardImages.module.scss';

export default function CardImages({ images, header, startIndex }) {
  if ((images || []).length === 0) {
    return null;
  }

  const imageItems = images.slice(startIndex).map((image) => (
    <div className={styles.Images__container}>
      <a
        alt="Скачать"
        href={image.url}
        download
      >
        <IconSvg icon="download" className={styles.Images__btn} />
      </a>
      <a
        rel="noreferrer"
        href={image.url}
        target="_blank"
      >
        <img
          key={`image${image.id}`}
          src={image.url}
          className={styles.Images__item}
          alt="Прикрепленное изображение"
        />
      </a>
    </div>
  ));

  return (
    <div className={styles.Images}>
      {
        header && (
          <CardHeader>
            <h4>Посмотри внимательно изображение</h4>
          </CardHeader>
        )
      }

      <div className={styles.Images__items}>
        {imageItems}
      </div>
    </div>
  );
}

CardImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
  })),
  header: PropTypes.string,
  startIndex: PropTypes.number,
};

CardImages.defaultProps = {
  images: [],
  header: null,
  startIndex: 0,
};
