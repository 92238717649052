import React from 'react';

import styles from './TagsFilterList.module.scss';

export default function TagsFilterList({ children }) {
  return (
    <ul className={styles.List}>
      {children}
    </ul>
  );
}
