import React from 'react';
import PropTypes from 'prop-types';

import styles from './Button.module.scss';

export default function AnswerSubmitButton({ onClick, disabled, label }) {
  return (
    <button
      className={`button button_fullwidth button_large ${styles.Button}`}
      onClick={onClick}
      disabled={disabled}
      type="submit"
    >
      {label}
    </button>
  );
}

AnswerSubmitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

AnswerSubmitButton.defaultProps = {
  disabled: true,
  label: 'Подвердить выполнение',
};
