import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import BottomSlideUp from '../BottomSlideUp';
import playerRanksStore from '../../stores/playerRanksStore';

import styles from './PlayerRanksSlideUp.module.scss';

function PlayerRanksSlideUp({ show, onClose }) {
  const {
    playerRanks,
    isLoading,
    fetchPlayerRanks,
  } = playerRanksStore;

  useEffect(() => {
    fetchPlayerRanks();
  }, []);

  return (
    <BottomSlideUp show={show} onClose={onClose}>
      <ul className={styles.Ranks}>
        {
          playerRanks.map((rank) => (
            <li key={rank.id}>
              <div className={styles.Ranks__title}>{rank.title}</div>
              <div
                className={styles.Ranks__description}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: rank.description }}
              />
            </li>
          ))
        }
      </ul>
      <button
        className="button button_large button_fullwidth"
        onClick={onClose}
        type="button"
      >
        OK, понятно
      </button>
    </BottomSlideUp>
  );
}

PlayerRanksSlideUp.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default observer(PlayerRanksSlideUp);
