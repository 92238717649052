import * as React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './ProgramMemberButton.module.scss';

export default function ProgramMemberButton({ settings }) {
  const handleClick = () => {
    window.open(`${settings.bracelet_url}#anchor-page-event__booking`, '_blank');
  };

  return (
    <>
      {settings.bracelet_text.length > 0 && (<div className={cn(
        styles.ProgramMemberButton,
        styles.ProgramMemberButton_content
        )}
        onClick={handleClick}
      >
        {settings.bracelet_text}
      </div>
      )}
    </>
  );
}

ProgramMemberButton.propTypes = {
  settings: PropTypes.shape({
    bracelet_text: PropTypes.string,
    bracelet_url: PropTypes.string,
  }).isRequired,
};
