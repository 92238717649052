import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Card from '../../../Card';

import styles from './Card.module.scss';

function NotificationCard({ children, notification }) {
  const cardClass = classNames(styles.NotificationCard, {
    [styles.NotificationCard_unread]: !notification.viewed,
  });

  return (
    <Card noShadow className={cardClass}>
      {
        notification.created_at
        && <div className={styles.NotificationCard__date}>{notification.created_at}</div>
      }
      {children}
    </Card>
  );
}

NotificationCard.propTypes = {
  notification: PropTypes.shape({
    created_at: PropTypes.string,
    viewed: PropTypes.bool,
  }).isRequired,
  children: PropTypes.node,
};

NotificationCard.defaultProps = {
  children: null,
};

export default NotificationCard;
