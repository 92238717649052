import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Card from '../../../../../components/Card';
import styles from './QRCodeEvent.module.scss';
import QRCodeScanner from '../../../../../components/QRCodeScanner';
import moderatedEventsStore from '../../../../../stores/moderatedEvents';

function QRCodeEvent({ currentrEventId, currentEventDay, onError }) {
  const { isEventUpdating, takeEvent } = moderatedEventsStore;

  const handleScanEvent = async (data) => {
    const [eventId, playerId, day] = data.split('#');

    if (parseInt(eventId, 10) !== parseInt(currentrEventId, 10) || day !== currentEventDay) {
      onError('Ошибка. Код от другого события');
      return;
    }

    takeEvent(playerId, eventId, day);
  };

  return (
    <Card noShadow className={styles.QRCodeEvent}>
      <QRCodeScanner onScan={handleScanEvent}>
        {({ openScanner, isCameraAvailable }) => (
          <button
            type="button"
            className="button button_fullwidth"
            disabled={isEventUpdating || !isCameraAvailable}
            onClick={openScanner}
          >
            Сканировать QR-код события
          </button>
        )}
      </QRCodeScanner>
    </Card>
  );
}

QRCodeEvent.propTypes = {
  currentrEventId: PropTypes.number.isRequired,
  currentEventDay: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
};

export default observer(QRCodeEvent);
