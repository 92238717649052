import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './CardHeader.module.scss';

export default function CardHeader({ className, children, bottomSpace }) {
  return (
    <div className={cn(className, styles.CardHeader, { [styles[`CardHeader_bottomSpace-${bottomSpace}`]]: bottomSpace })}>
      {children}
    </div>
  );
}

CardHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  bottomSpace: PropTypes.string,
};

CardHeader.defaultProps = {
  className: '',
  children: '',
  bottomSpace: null,
};
