import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Camera from './Camera';

function QRCodeScanner({ children, onScan, onError }) {
  const [isCameraAvailable, setCameraAvailable] = useState(true);
  const [isScannerVisible, setScannerVisible] = useState(false);

  const handleScannerError = (err) => {
    setCameraAvailable(false);
    setScannerVisible(false);
    onError(err);
  };

  const handleOpenScanner = () => {
    setScannerVisible(true);
  };

  const handleCloseScanner = () => {
    setScannerVisible(false);
  };

  const handleScan = (data) => {
    setScannerVisible(false);
    onScan(data);
  };

  return (
    <>
      {
        children({
          openScanner: handleOpenScanner,
          closeScanner: handleCloseScanner,
          isCameraAvailable,
        })
      }
      {
        isScannerVisible && (
          <Camera
            onScan={handleScan}
            onClose={handleCloseScanner}
            onError={handleScannerError}
          />
        )
      }
    </>
  );
}

QRCodeScanner.propTypes = {
  children: PropTypes.func.isRequired,
  onScan: PropTypes.func,
  onError: PropTypes.func,
};

QRCodeScanner.defaultProps = {
  onScan: () => {},
  onError: () => {},
};

export default QRCodeScanner;
