import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Cost from '../../../components/Cost';
import Card from '../../../components/Card';
import Avatar from '../../../components/Avatar';
import TeamRatingCard from '../../../components/TeamRatingCard';
import { headersWithToken } from '../../../../../common/javascripts/fetchOptions';
import styles from './PublicProfile.module.scss';

function PlayerPublicProfileScreen({
  player,
  viewerTeamId,
  alreadyInvited,
  canInvite,
}) {
  const isInTeam = player.team !== null;
  const isModerated = player.moderated;
  const [processing, setProcessing] = useState(false);
  const [invited, setInvited] = useState(alreadyInvited);

  const handleInvite = async () => {
    const data = { team_invitation: { player_id: player.id } };

    setProcessing(true);
    try {
      const response = await fetch(`/teams/${viewerTeamId}/team_invitations`, {
        method: 'POST',
        headers: headersWithToken(),
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setProcessing(false);
        setInvited(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card noShadow>
      <div className={styles.PlayerPublicProfile}>
        <div className={styles.PlayerPublicProfile__avatarWrap}>
          <Avatar type="player" avatar={player.avatar_url} />
        </div>
        <div className={styles.PlayerPublicProfile__name}>
          {player.public_full_name}
        </div>

        {isModerated && (
          <a
            href={`tel:${player.phone}`}
          >
            {player.phone}
          </a>
        )}

        <div className={styles.PlayerPublicProfile__rank}>
          {player.rank_title}
        </div>

        <div className={styles.PlayerPublicProfile__points}>
          <Cost icon="player-points" amount={player.balance_player_points} showZero />
          <Cost icon="team-points" amount={player.balance_team_points} showZero />
        </div>

        {
          isInTeam && (
            <div className={styles.PlayerPublicProfile__teamPlayLabel}>
              Играет за команду
            </div>
          )
        }
        {
          canInvite && (
            (!isInTeam && !invited && (
              <button
                type="button"
                className="button button_large button_fullwidth"
                onClick={handleInvite}
              >
                Пригласить в команду
              </button>
            )) || (
              !isInTeam && invited && (
                <div className={styles.PlayerPublicProfile__teamPlayLabel}>
                  Игрок приглашен в команду
                </div>
              )) || (
              processing && (
                <div className={styles.PlayerPublicProfile__teamPlayLabel}>
                  Подождите...
                </div>
              ))
          )
        }

      </div>
      {isInTeam && <TeamRatingCard team={player.team} />}
    </Card>
  );
}

PlayerPublicProfileScreen.propTypes = {
  player: PropTypes.shape({
    id: PropTypes.number,
    team: PropTypes.shape({
      id: PropTypes.number,
    }),
    avatar_url: PropTypes.string,
    public_full_name: PropTypes.string,
    rank_title: PropTypes.string,
    balance_player_points: PropTypes.number,
    balance_team_points: PropTypes.number,
    phone: PropTypes.string.isRequired,
    moderated: PropTypes.bool.isRequired,
  }).isRequired,
  viewerTeamId: PropTypes.number,
  alreadyInvited: PropTypes.bool.isRequired,
  canInvite: PropTypes.bool.isRequired,
};

PlayerPublicProfileScreen.defaultProps = {
  viewerTeamId: null,
};

export default PlayerPublicProfileScreen;
