import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { format } from 'date-fns';
import { ru } from 'date-fns/esm/locale';
import { declOfNum } from '../../../../../../../common/javascripts/helpers';

import Card from '../../../../../components/Card';
import CardHeader from '../../../../../components/CardHeader';
import CardTagsList from '../../../../../components/CardTagsList';
import EventCoverImage from '../../../components/CoverImage';
import EventTime from '../../../components/EventTime';
import EventLocation from '../../../components/Location';

import styles from './ListCard.module.scss';

export default function EventListCard({ event, day, isFinished }) {
  return (
    <Card className={cn(styles.EventListCard,
      { [styles.EventListCard_finished]: isFinished })}
    >
      {event.image_on_card && <EventCoverImage imageUrl={event.cover_url} inList />}

      {isFinished && (
        <div className={styles.EventListCard__finishedBadge}>
          Завершено
        </div>
      )}

      <div className={styles.EventListCard__infoWrap}>
        <div className={styles.EventListCard__daytime}>
          <EventTime
            startAt={event.start_at}
            endAt={event.end_at}
            ageRestriction={event.age_restriction}
          />
          <span className={styles.EventListCard__day}>
            {format(new Date(day), 'd MMMM (EEEEEE)', { locale: ru })}
          </span>
        </div>

        <div className={styles.EventListCard__tags}>
          <CardTagsList tags={[{ id: 0, title: event.tag_title }]} />
        </div>

        <CardHeader bottomSpace="s">
          {event.important ? <h2>{event.name}</h2> : <h4>{event.name}</h4>}
        </CardHeader>

        {
          event.have_to_register && event.free_places <= 15 && !isFinished
          && (
            <div className={styles.EventListCard__freePlaces}>
              {
                event.free_places === 0
                  ? 'Мест не осталось'
                  : `Осталось ${event.free_places} ${declOfNum(event.free_places, ['мест', 'места', 'мест'])}`
              }
            </div>
          )
        }
      </div>
    </Card>
  );
}

EventListCard.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    image_on_card: PropTypes.bool,
    cover_url: PropTypes.string,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    age_restriction: PropTypes.number,
    day: PropTypes.string,
    free_places: PropTypes.number,
    description: PropTypes.string,
    name: PropTypes.string,
    important: PropTypes.bool,
    tag_title: PropTypes.string,
    have_to_register: PropTypes.bool,
  }).isRequired,
  day: PropTypes.string.isRequired,
};
