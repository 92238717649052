import React, { useState, useRef, useLayoutEffect } from 'react';
import { useSpring, animated } from 'react-spring';

import styles from './TagsFilter.module.scss';

// !! height in px. Use in checking if content more then it.
const initialFilterHeight = 50;

export default function TagsFilter({ children, count }) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterContentHeight, setFilterContentHeight] = useState(initialFilterHeight);

  const filterContentRef = useRef();

  useLayoutEffect(() => {
    setFilterContentHeight(filterContentRef.current.clientHeight);
  }, [count]);

  const expand = useSpring({
    height: isFilterOpen ? `${filterContentHeight}px` : `${initialFilterHeight}px`,
  });

  return (
    <animated.div style={expand} className={styles.TagsFilter}>
      <div ref={filterContentRef}>{children}</div>

      {(filterContentHeight > initialFilterHeight) &&
        <a
          className={styles.TagsFilter__more}
          onClick={() => setIsFilterOpen(!isFilterOpen)}
        >
          Еще
        </a>
      }
    </animated.div>
  );
}
