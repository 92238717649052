import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import BannerItem from '../BannerItem/BannerItem';
import { headersWithToken } from '../../../../../common/javascripts/fetchOptions';

import styles from './BannersList.module.scss';
import clickCounter from '../../../helpers/counter';

function BannersList({ usedFor }) {
  const [banners, setBanners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const closedBanners = JSON.parse(localStorage.getItem('closedBanners') || '[]');

  const setNotClosedBanners = (prevBanners) => {
    const nextBanners = prevBanners.filter((b) => !closedBanners.includes(b.id));
    setBanners(nextBanners);
  };

  const onBannerClose = (id) => {
    closedBanners.push(id);
    localStorage.setItem('closedBanners', JSON.stringify(closedBanners));
    setNotClosedBanners(banners);
  };

  const handleBannerClick = (banner) => {
    window.open(`https://${banner.url}`, '_blank');
    clickCounter(`/banners/${banner.id}`, banner);
  };

  const fetchBanners = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`/banners?for=${usedFor}`, {
        method: 'GET',
        headers: headersWithToken(),
      });

      const result = await response.json();
      setIsLoading(false);

      if (response.ok) {
        setNotClosedBanners(result.banners);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  if (banners && banners.length === 0 && !isLoading) return null;

  return (
    <ul className={styles.List}>
      {
        banners.map((banner) => (
          <li key={banner.id} className={styles.List__item}>
            <a
              onClick={() => handleBannerClick(banner)}
              className={styles.List__link}
              rel="noreferrer"
            >
              <BannerItem
                banner={banner}
                onClose={onBannerClose}
              />
            </a>
          </li>
        ))
      }
    </ul>
  );
}

BannersList.propTypes = {
  usedFor: PropTypes.string.isRequired,
};

export default React.memo(BannersList);
