import React from 'react';
import PropTypes from 'prop-types';

import styles from './Description.module.scss';

export default function TaskDescription({ description }) {
  if (!description) return null;

  return (
    <div
      className={styles.Description}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
}

TaskDescription.propTypes = {
  description: PropTypes.string,
};

TaskDescription.defaultProps = {
  description: null,
};
