import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import BuyHint from '../BuyHint';
import SubmitButton from '../AnswerSubmit';
import TopPageNotice from '../../../../../components/TopPageNotice';
import CardHeader from '../../../../../components/CardHeader';

import { headersWithToken } from '../../../../../../../common/javascripts/fetchOptions';

import * as styles from './AnswerGeo.module.scss';

export default function AnswerGeo({ taskStep, onTaskAnswer }) {
  const [notice, setNotice] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const locationOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const locationError = (err) => {
    setNotice(`ERROR(${err.code}): ${err.message}`);
    setIsLoading(false);
  };

  const submitAnswer = async (pos) => {
    console.log(pos.coords);

    const answer = `${pos.coords.latitude}, ${pos.coords.longitude}`;

    const data = {
      task_step_id: taskStep.id,
      task_answer: { answer },
    };

    try {
      const response = await fetch('/task_answers.json', {
        method: 'POST',
        headers: headersWithToken(),
        body: JSON.stringify(data),
      });
      const result = await response.json();

      setIsLoading(false);

      if (response.ok) {
        setNotice(result.notice);
        setTimeout(() => onTaskAnswer(), 450);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const handleSubmitClick = () => {
    setNotice('');
    setIsLoading(true);
    navigator.geolocation.getCurrentPosition(
      submitAnswer, locationError, locationOptions,
    );
  };

  const isDisabled = isLoading || taskStep.attempts_left <= 0;

  return (
    <div className={styles.AnswerGeo}>
      <TopPageNotice notice={notice} onClose={() => setNotice(null)} />

      <CardHeader bottomSpace="s">
        <h4>
          Если Вы в нужном месте, то нажмите кнопку ниже и разрешите доступ к геоданным.
        </h4>
      </CardHeader>

      <BuyHint text={taskStep.human_attempts_left} />
      <SubmitButton
        disabled={isDisabled}
        onClick={handleSubmitClick}
        label="Подтверждаю геолокацию"
      />
    </div>
  );
}

AnswerGeo.propTypes = {
  taskStep: PropTypes.object.isRequired,
  onTaskAnswer: PropTypes.func.isRequired,
};
