import React from 'react';
import PropTypes from 'prop-types';

import styles from './PageContentOtherBg.module.scss';

export default function PageContentOtherBg({ children }) {
  return (
    <div className={styles.OtherBg}>
      {children}
    </div>
  );
}

PageContentOtherBg.propTypes = {
  children: PropTypes.node.isRequired,
};
