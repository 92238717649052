import React from 'react';
import PropTypes from 'prop-types';

import CardHeader from '../CardHeader';

import styles from './CardVideos.module.scss';

export default function CardVideos({ videos, header }) {
  if ((videos || []).length === 0) {
    return null;
  }

  const videoItems = videos.map((video) => (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      key={`video${video.id}`}
      src={video.url}
      className={styles.Videos__video}
      autoPlay={false}
      controls
    />
  ));

  return (
    <div className={styles.Videos}>
      {
        header && (
          <CardHeader>
            <h4>{header}</h4>
          </CardHeader>
        )
      }

      <div className={styles.Videos__items}>
        {videoItems}
      </div>
    </div>
  );
}

CardVideos.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
  })),
  header: PropTypes.string,
};

CardVideos.defaultProps = {
  videos: [],
  header: null,
};
