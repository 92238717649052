import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PageHeader from '../../../components/PageHeader/PageHeader';
import TasksCardsList from './components/CardsList';
import TasksListFilter from './components/Filter';
import ContentNotice from '../../../components/ContentNotice';
import BannersList from '../../../components/Banner/BannersList';
import { removeDuplicates } from '../../../../../common/javascripts/helpers';
import ModeratedPlayer from '../../../components/ModeratedPlayer/ModeratedPlayer';
import { isTaskModerator } from '../../../helpers/players';
import QRCodeAnswer from '../../../components/QRCodeAnswer';

function TasksListScreen({ tasks, player, setting }) {
  const [filter, setFilter] = useState({ system: null, tagId: null });

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const collectedTags = removeDuplicates(tasks.flatMap((item) => item.tags), 'id')
    .sort((a, b) => ((a.position > b.position) ? 1 : -1));

  const filteredTasks = tasks.filter((item) => (
    (filter.tagId === null && filter.system === null) // all tag
    || (filter.tagId !== null && item.tags.find((tag) => tag.id === filter.tagId)) // task tag id
    || (filter.system === 'favourites' && item.favourite === true) // favourites
    || (filter.system === 'postpend' && item.postpend === true) // postpend
    || (filter.system === 'endToday' && item.end_today === true) // end today
    || (filter.system !== null && item.status === filter.system) // statuses
  ));

  return (
    <>
      {/* {isTaskModerator(player) && <ModeratedPlayer />} */}
      {isTaskModerator(player) && <QRCodeAnswer />}

      <BannersList usedFor="quest" />

      {/* <ContentNotice uid="tasksListWelcome" outline bottomSpace>
        Ознакомься с
        {' '}
        <a href="/help/faq" target="_blank" rel="noreferrer">правилами квеста</a>
        &nbsp;и начинай выполнять задания! Призы сами себя не выиграют!
      </ContentNotice> */}

      <ContentNotice uid="tasksListWelcome" outline bottomSpace>
        <div
          // className={styles.WinnerAward__prize}
          dangerouslySetInnerHTML={{ __html: setting?.task_notice || 'Нажми на карточку, чтобы прочитать подробнее о призе и купить его' }}
        />
      </ContentNotice>

      <PageHeader>
        <h1>Задания</h1>
      </PageHeader>

      <TasksListFilter
        filter={filter}
        taskTags={collectedTags}
        player={player}
        onFilterChange={handleFilterChange}
        setting={setting}
      />

      <TasksCardsList
        filter={filter}
        items={tasks}
        tasks={filteredTasks}
        player={player}
        setting={setting}
      />
    </>
  );
}

TasksListScreen.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  player: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  setting: PropTypes.shape({
    img_url_card: PropTypes.string.isRequired,
    title_card: PropTypes.string.isRequired,
    text_card: PropTypes.string.isRequired,
    description_card: PropTypes.string.isRequired,
    task_notice: PropTypes.string.isRequired,
  }).isRequired,
};

export default TasksListScreen;
