import React from 'react';

import PageContentOtherBg from '../../../components/PageContentOtherBg';
import PartnerInfoCard from './components/InfoCard';

export default function PartnerShowScreen({ partner }) {
  return (
    <PageContentOtherBg>
      <PartnerInfoCard partner={partner} />
    </PageContentOtherBg>
  );
}
