import React, { useState } from 'react';
import TeamRatingCard from 'client/javascripts/components/TeamRatingCard';
import ContentNotice from 'client/javascripts/components/ContentNotice';

export default function TeamsRating({my_team, my_rating_index, settings}) {
  const [items, setItems] = useState([]);
  const [offset, setOffset] = useState(0);
  const [initialized, setInitialized] = useState(false);
  const [isUpdating, setIsUpdating] = useState(true);
  const [noMoreRecords, setNoMoreRecords] = useState(false);

  const more = async () => {
    try {
      const response = await fetch("/teams/top?offset=" + offset, {
        method: 'GET'
      });

      setIsUpdating(true);
      const result = await response.json();

      if (response.ok) {
        if (result.length < offset + 15) {
          setNoMoreRecords(true);
        }
        setItems(result);
        setOffset(offset + 15);
      }
      setIsUpdating(false);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const constructor = () => {
    if (initialized) return;
    more();
    setInitialized(true);
  };

  constructor();

  return (
    <>
      <ul className='pageContent__cardsList'>
        {items.map((item, index) => {
          return (
            <>
              <li key={item.id}>
                <TeamRatingCard team={item} forceRating={index + 1} />
              </li>
              { (index === 2 && my_rating_index > 5) && (
                <li key={`myTeam${my_team.id}`}>
                  <div className='teamRatingCard__myRatingNotice'> Твоя команда на {my_rating_index} месте </div>
                  <TeamRatingCard team={my_team} highlighted />
                  <br />
                </li>
              )}
            </>
          );
        })}
      </ul>

      {(!noMoreRecords) &&
        <button
          className='button button_large button_fullwidth'
          onClick={more}
          disabled={isUpdating}
        >
          Загрузить еще
        </button>
      }

      {(noMoreRecords && items.length === 0) &&
        <ContentNotice
          outline
          notClosable
        >
          <div
            // className={styles.WinnerAward__prize}
            dangerouslySetInnerHTML={{ __html: settings?.teams_raiting_notice || 'Пока нет команд в рейтинге' }}
          />
        </ContentNotice>
      }
    </>
  );
}
