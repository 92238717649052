class CurrentPlayerStore {
  player = null

  constructor() {
    document.addEventListener('turbolinks:load', () => {
      if (window.initData) {
        this.player = window.initData.currentPlayer;
      }
    });
  }
}

export default new CurrentPlayerStore();
