import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { format } from 'date-fns';
import { ru } from 'date-fns/esm/locale';
import AttentionMessage from '../../../../../components/AttentionMessage';
import IconSvg from '../../../../../../../common/javascripts/components/IconSvg';
import { declOfNum } from '../../../../../../../common/javascripts/helpers';

import styles from './Registration.module.scss';

export default function EventRegistration({ event, onAction, onlyButton }) {
  const isRegistred = event.current_player_registered;
  const canRegister = event.register_start && event.is_free_places;
  const inWaitingList = event.current_player_in_waiting_list;
  const isUnlimetedPlaces = event.unlimited;
  const isBraceletRequired = event.bracelet;
  const isAccepted = event.current_player_accepted;

  const handleButtonClick = () => {
    const action = isRegistred ? 'unregistration' : 'registration';
    onAction(action);
  };

  const buttonClassName = cn(
    styles.EventRegistration__button,
    'button button_large button_fullwidth',
    { button_secondary: isRegistred },
  );

  const actionButton = (
    <button
      type="button"
      onClick={handleButtonClick}
      className={buttonClassName}
      disabled={(!canRegister && !isRegistred && inWaitingList) || isAccepted}
    >
      {isRegistred && 'Отменить запись'}
      {((!isRegistred && !inWaitingList && !isAccepted && canRegister) || isUnlimetedPlaces) && 'Да, я пойду'}
      {(!isRegistred && !inWaitingList && event.register_start && event.free_places <= 0) && 'Добавить в лист ожидания'}
      {(inWaitingList) && 'Ожидаю'}
    </button>
  );

  if (!event.have_to_register) return null;

  if (onlyButton && !isAccepted) return actionButton;

  return (
    <div className={styles.EventRegistration}>

      {isBraceletRequired && (
        <>
          <IconSvg icon="event-registration" className={styles.EventRegistration__image} />

          <div className={styles.EventRegistration__preText}>
            Для доступа на мероприятие
          </div>
          <div className={styles.EventRegistration__text}>
            {'необходимо предварительно записаться и '}
            <span className={styles.EventRegistration__textAttention}>предъявить</span>
            <a
              rel="noreferrer"
              className={styles.EventRegistration__link}
              href="https://spotway.ru/rosafest#anchor-page-event__booking"
              target="_blank"
            >
              {' браслет участника '}
            </a>
            {' при входе'}
          </div>
        </>
      )}

      {event.register_start && actionButton}

      {
        (!isRegistred && event.free_places <= 15) && (
          <AttentionMessage>
            {
              event.free_places === 0
                ? 'К сожалению, мест не осталось'
                : `Поторопись! Осталось ${event.free_places} ${declOfNum(event.free_places, ['мест', 'места', 'мест'])}`
            }
          </AttentionMessage>
        )
      }

      {
        !event.register_start && (
          <AttentionMessage>
            {`Регистрация начнется ${format(new Date(event.start_register_date), 'd MMMM', { locale: ru })}`}
          </AttentionMessage>
        )
      }
    </div>
  );
}

EventRegistration.propTypes = {
  event: PropTypes.shape({
    free_places: PropTypes.number,
    have_to_register: PropTypes.bool,
    current_player_registered: PropTypes.bool,
    current_player_in_waiting_list: PropTypes.bool,
    register_start: PropTypes.bool,
    start_register_date: PropTypes.string,
    is_free_places: PropTypes.bool,
    unlimited: PropTypes.bool,
    bracelet: PropTypes.bool,
    current_player_accepted: PropTypes.bool,
  }).isRequired,
  onAction: PropTypes.func.isRequired,
  onlyButton: PropTypes.bool,
};

EventRegistration.defaultProps = {
  onlyButton: false,
};
