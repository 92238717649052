import React from 'react';
import PropTypes, { shape } from 'prop-types';

import FromEditor from '../../../../components/FromEditor';
import CardHeader from '../../../../components/CardHeader';
import BottomSlideUp from '../../../../components/BottomSlideUp';
import WinnerProfile from '../../../Winner/components/WinnerProfile';

import styles from './TopAwardSlideUp.module.scss';

function TopAwardSlideUp({ topAward, show, onClose }) {
  const hasPublishedWinner = topAward.winner_published;
  const winners = topAward.winners.length === 0 ? null : topAward.winners.map((winner) => {
    return (
      <div
        className={styles.Award__winner}
        key={`winner_${winner.name}`}
      >
          <WinnerProfile winner={winner} />
      </div>
    );
  });

  return (
    <BottomSlideUp show={show} onClose={onClose}>
      <div className={styles.Award}>
        <CardHeader bottomSpace="m">
          <h2><center>{topAward.title}</center></h2>
        </CardHeader>
        {
          topAward.slide_up_image_url
          && (
            <div className={styles.Award__image}>
              <img src={topAward.slide_up_image_url} alt="Top Award" />
            </div>
          )
        }
        <FromEditor>{topAward.description}</FromEditor>
      </div>
      {((topAward.can_be_announced && !winners) || (topAward.can_be_announced && !hasPublishedWinner)) && (
        <h4>
          <strong>
            Победитель будет объявлен:
            <br />
            {topAward.winners_published_at}
          </strong>
        </h4>
      )}
      {(winners && hasPublishedWinner) && (
        <div className={styles.Award__winnerWrap}>
          {winners}
        </div>
      )}
      <button
        className="button button_large button_fullwidth"
        onClick={onClose}
        type="button"
      >
        OK, спасибо
      </button>
    </BottomSlideUp>
  );
}

TopAwardSlideUp.propTypes = {
  topAward: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    slide_up_image_url: PropTypes.string,
    can_be_announced: PropTypes.bool,
    winners_published_at: PropTypes.string,
    winner_published: PropTypes.bool,
    winners: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      phone: PropTypes.string,
    })),
  }).isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TopAwardSlideUp;
