import React, { useState } from 'react';


export default function TextCopy({label, labelAfterCopy, textToCopy, className}) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1500);
    }, () => {
      console.log('Error copying to clipboard');
    });
  };


  return (
    <>
      <span className={className} onClick={handleCopy}>
        {isCopied ? labelAfterCopy : label}
      </span>
    </>
  );
}
