import React from 'react';
import PropTypes from 'prop-types';

import Cost from '../../../../../components/Cost';
import Card from '../../../../../components/Card';
import TeamRatingPosition from '../../../../../components/TeamRatingPosition';
import Avatar from '../../../../../components/Avatar';

import styles from './TeamPreview.module.scss';

export default function TeamPlayTeamPreview({ team }) {
  return (
    <Card>
      <div className={styles.Team}>
        <div className={styles.Team__info}>
          <div className={styles.Team__rating}>
            <TeamRatingPosition ratingPosition={team.rating_position} />
          </div>
          <div className={styles.Team__avatar}>
            <Avatar type="team" avatar={team.avatar_url} />
          </div>
          <div className={styles.Team__points}>
            <Cost icon="team-points" amount={team.points} showZero />
          </div>
        </div>
        <div className={styles.Team__title}>
          {team.title}
        </div>
      </div>
    </Card>
  );
}

TeamPlayTeamPreview.propTypes = {
  team: PropTypes.shape({
    rating_position: PropTypes.number,
    avatar_url: PropTypes.string,
    points: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
};
