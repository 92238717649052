import React, { useState } from 'react';
import PropTypes from 'prop-types';

import NotificationCard from '../components/Card';
import NotificationCardBase from '../components/CardBase';
import { headersWithToken } from '../../../../../common/javascripts/fetchOptions';
import { visitOrRedirectTo } from '../../../../../common/javascripts/helpers';

import styles from './InviteToTeam.module.scss';

export default function NotificationInviteToTeamCard({ notification }) {
  const [rejected, setRejected] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleAccept = async () => {
    setProcessing(true);
    try {
      const response = await fetch(notification.team_accept_url, {
        method: 'PATCH',
        headers: headersWithToken(),
      });
      if (response.ok) {
        visitOrRedirectTo(`/teams/${notification.data.team_id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleReject = async () => {
    setProcessing(true);
    try {
      const response = await fetch(notification.team_reject_url, {
        method: 'PATCH',
        headers: headersWithToken(),
      });
      if (response.ok) {
        setProcessing(false);
        setRejected(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <NotificationCard notification={notification}>
      <NotificationCardBase
        title={notification.data.title}
        text={notification.data.body}
        avatar={notification.team_avatar_url}
        avatarType="team"
      />
      {!rejected && !processing && (
        <div className={styles.Actions}>
          <button
            type="button"
            className="button button_large button_fullwidth"
            onClick={handleAccept}
          >
            Принять
          </button>
          <button
            type="button"
            className="button button_large button_fullwidth button_secondary"
            onClick={handleReject}
          >
            Отклонить
          </button>
        </div>
      )}
      {rejected && !processing && (
        <div className={styles.Status}>
          Предложение отклонено
        </div>
      )}
      {processing && (
        <div className={styles.Status}>
          Подождите...
        </div>
      )}
    </NotificationCard>
  );
}

NotificationInviteToTeamCard.propTypes = {
  notification: PropTypes.shape({
    created_at: PropTypes.string,
    data: PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
      team_id: PropTypes.number,
    }),
    team_avatar_url: PropTypes.string,
    team_accept_url: PropTypes.string,
    team_reject_url: PropTypes.string,
  }).isRequired,
};
