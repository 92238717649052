import {
  makeObservable,
  observable,
  action,
  runInAction,
  toJS,
} from 'mobx';

import EventApi from './eventsApi';

class EventStore {
  events = {}

  filteredEvents = {}

  tagFilter = 0

  dayFilter = null

  currentTab = 'days'

  isLoading = false

  currentPage = 1

  // hasMoreEvents = false

  currentTypeEvent = null;

  isInit = true;

  constructor(eventApi) {
    makeObservable(this, {
      events: observable,
      filteredEvents: observable,
      currentPage: observable,
      currentTab: observable,
      tagFilter: observable,
      dayFilter: observable,
      isLoading: observable,
      // hasMoreEvents: observable,
      currentTypeEvent: observable,
      isInit: observable,

      fetchEvents: action,
      // fetchMoreEvents: action,
      filterEventsByTag: action,
      filterEventsByDay: action,
      onFilterChange: action,
      setFiltersFromQuery: action,
      setFilters: action,
      removeEventFromFavourite: action,
      removeEventFromRegistration: action,
    });
    this.eventApi = eventApi;
  }

  fetchEvents = async (typeEvent, tagFilter, dayFilter, currentTab = 0) => {
    this.events = {};
    this.filteredEvents = {};
    this.isLoading = true;
    this.currentTab = currentTab;

    try {
      const data = await this.eventApi.fetchEvents(typeEvent, 1, tagFilter, dayFilter);

      runInAction(() => {
        this.events = data.events;
        this.currentPage = 1;
        this.tagFilter = tagFilter;
        this.dayFilter = dayFilter;
        // this.hasMoreEvents = !!(data.meta || {}).next_page;
        this.currentTypeEvent = typeEvent;

        this.filterEvents();
        this.isLoading = false;
        this.isInit = false;
      });
    } catch (error) {
      console.error(error);
    }
  };

  /*
  fetchMoreEvents = async () => {
    this.currentPage += 1;
    this.isLoading = true;

    try {
      const data = await this.eventApi.fetchEvents(this.currentTypeEvent, this.currentPage, this.tagFilter, this.dayFilter);

      runInAction(() => {
        this.isLoading = false;
        this.events = [...this.events, ...data.events];
        this.hasMoreEvents = !!(data.meta || {}).next_page;
      });
    } catch (error) {
      console.error(error);
    }
  };
  */

  filterEventsByTag = async () => {
    this.isLoading = true;
    this.filteredEvents = [];

    const { tagFilter } = this;
    const currentEvents = toJS(this.events);
    let currentFilteredEvents = {};

    if (tagFilter === 0) {
      currentFilteredEvents = currentEvents;
    } else {
      currentFilteredEvents = Object.entries(currentEvents).reduce((acc, [key, value]) => { acc[key] = value.filter((event) => { return event.tag_id === tagFilter; }); return acc; }, {});
    }

    runInAction(() => {
      this.filteredEvents = currentFilteredEvents;
      this.isLoading = false;
    });
  };

  filterEventsByDay = async () => {
    this.isLoading = true;
    this.filteredEvents = [];

    const { dayFilter } = this;
    const currentEvents = toJS(this.events);
    const currentFilteredEvents = {};

    if (dayFilter && dayFilter.length > 0) {
      currentFilteredEvents[dayFilter] = currentEvents[dayFilter];
    }

    runInAction(() => {
      this.filteredEvents = currentFilteredEvents;
      this.isLoading = false;
    });
  };

  setFilters = (filters = {}) => {
    const { tab, day, tag } = filters;

    this.currentTab = tab || this.currentTab;
    this.dayFilter = day || this.dayFilter;
    this.tagFilter = tag ? parseInt(tag, 10) : 0;
  }

  filterEvents = () => {
    if (this.currentTab === 'tags' && this.tagFilter >= 0) {
      this.filterEventsByTag();
    }
    if (this.currentTab === 'days' && this.dayFilter) {
      this.filterEventsByDay();
    }
  }

  onFilterChange = (filters) => {
    this.setFilters(filters);
    this.filterEvents();

    if (window === undefined) return;

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('tab', this.currentTab);
    queryParams.set('day', this.dayFilter);
    queryParams.set('tag', this.tagFilter);

    window.history.pushState({}, '', `?${queryParams.toString()}`);
  };

  setFiltersFromQuery = () => {
    const queryParams = new URLSearchParams(window.location.search);

    this.setFilters(Object.fromEntries(queryParams));
    this.filterEvents();
  }

  removeEventFromFavourite = (eventId) => {
    const currentEvents = toJS(this.events);
    const newEvents = Object.entries(currentEvents).reduce((acc, [key, value]) => { acc[key] = value.filter((event) => { return event.id !== eventId; }); return acc; }, {});

    const currentFilteredEvents = toJS(this.filteredEvents);
    const newFilteredEvents = Object.entries(currentFilteredEvents).reduce((acc, [key, value]) => { acc[key] = value.filter((event) => { return event.id !== eventId; }); return acc; }, {});

    runInAction(() => {
      this.events = newEvents;
      this.filteredEvents = newFilteredEvents;
      this.isLoading = false;
    });
  }

  removeEventFromRegistration = (eventId, eventDay) => {
    const currentEvents = toJS(this.events);
    const newEvents = {};
    newEvents[eventDay] = currentEvents[eventDay].filter((event) => event.id !== eventId);

    const currentFilteredEvents = toJS(this.filteredEvents);
    const newFilteredEvents = {};
    newFilteredEvents[eventDay] = currentFilteredEvents[eventDay].filter((event) => event.id !== eventId);

    runInAction(() => {
      this.events = { ...currentEvents, ...newEvents };
      this.filteredEvents = { ...currentFilteredEvents, ...newFilteredEvents };
      this.isLoading = false;
    });
  }
}

const store = new EventStore(new EventApi());

export default store;
