import { headersWithToken } from '../../../common/javascripts/fetchOptions';

class EventApi {
  fetchEvents = async (typeEvent, page, tagFilter, dayFilter) => {
    const response = await fetch(`/events/${typeEvent}.json?page=${page}&tag=${tagFilter}&day=${dayFilter}`);

    return response.json();
  }

  acceptEvent = async (playerId, eventId, day) => {
    const data = {
      events_player: {
        event_id: eventId,
        player_id: playerId,
        date: day,
      },
    };
    const response = await fetch('/events_players/accept', {
      method: 'PATCH',
      headers: headersWithToken(),
      body: JSON.stringify(data),
    });
    return response.json();
  }

  rejectEvent = async (playerId, eventId, day) => {
    const data = {
      events_player: {
        event_id: eventId,
        player_id: playerId,
        date: day,
      },
    };
    const response = await fetch('/events_players/reject', {
      method: 'PATCH',
      headers: headersWithToken(),
      body: JSON.stringify(data),
    });
    return response.json();
  }

  eventRegistration = async (playerId, eventId, day) => {
    const data = {
      events_player: {
        event_id: eventId,
        player_id: playerId,
        date: day,
      },
    };
    const response = await fetch('/events_players/registration', {
      method: 'POST',
      headers: headersWithToken(),
      body: JSON.stringify(data),
    });
    return response.json();
  }

  takeEvent = async (playerId, eventId, day) => {
    const data = {
      events_player: {
        event_id: eventId,
        player_id: playerId,
        date: day,
      },
    };

    const response = await fetch('/events_players/take', {
      method: 'POST',
      headers: headersWithToken(),
      body: JSON.stringify(data),
    });
    return response.json();
  }

  resetRegistration = async (eventId, day) => {
    const data = {
      events_player: {
        event_id: eventId,
        date: day,
      },
    };
    const response = await fetch('/events_players/reset_registration', {
      method: 'POST',
      headers: headersWithToken(),
      body: JSON.stringify(data),
    });
    return response.json();
  }
}

export default EventApi;
