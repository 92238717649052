import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import IconSvg from '../../../../../../common/javascripts/components/IconSvg';

import styles from './Location.module.scss';

export default function EventLocation({ location, className }) {
  const handleClick = (e) => {
    e.preventDefault();
    window.open(location.location_url, '_blank');
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={cn(styles.Location, className)} onClick={handleClick}>
      <IconSvg icon="location" />
      {location.name}
    </div>
  );
}

EventLocation.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    location_url: PropTypes.string,

  }),
  className: PropTypes.string,
};

EventLocation.defaultProps = {
  location: {
    id: 1,
    name: 'Кафе "Райдер с ложкой"',
    address: 'Роза-Хутор',
    latitude: '1.2323',
    longitude: '2.1212',
    location_url: 'https://maps.google.com',
  },
  className: null,
};
