import {
  makeObservable,
  observable,
  action,
  runInAction,
} from 'mobx';

import ProductsApi from './productsApi';
import { removeDuplicates } from '../../../common/javascripts/helpers';

class ProductStore {
  products = []

  collectedTags = []

  currentPage = 1

  isLoading = false

  hasMoreProducts = false

  constructor(productsApi) {
    makeObservable(this, {
      products: observable,
      collectedTags: observable,
      currentPage: observable,
      isLoading: observable,
      hasMoreProducts: observable,
      fetchProducts: action,
      fetchMoreProducts: action,
    });

    this.productsApi = productsApi;
  }

  fetchProducts = async () => {
    this.isLoading = true;

    try {
      const data = await this.productsApi.fetchProducts(1);

      runInAction(() => {
        this.isLoading = false;
        this.products = data.products;
        this.collectedTags = data.collectedTags;
        this.currentPage = 1;
        this.hasMoreProducts = !!(data.meta || {}).next_page;
      });
    } catch (error) {
      console.error(error);
    }
  }

  fetchMoreProducts = async () => {
    this.currentPage += 1;
    this.isLoading = true;

    try {
      const data = await this.productsApi.fetchProducts(this.currentPage);

      runInAction(() => {
        this.isLoading = false;
        this.products = [...this.products, ...data.products];
        this.collectedTags = [...this.collectedTags, ...data.collectedTags];
        this.collectedTags = removeDuplicates(this.collectedTags, 'id');
        this.hasMoreProducts = !!(data.meta || {}).next_page;
      });
    } catch (error) {
      console.error(error);
    }
  }
}

export default new ProductStore(new ProductsApi());
