import React, { useState } from 'react';
import { observer } from 'mobx-react';

import styles from './ModeratedPlayer.module.scss';
import Card from '../Card';
import SearchPlayerForm from './SearchPlayerForm';
import TopPageNotice from '../TopPageNotice';
import moderatedPlayerStore from '../../stores/moderatedPlayer';
import QRCodeScanner from '../QRCodeScanner';
import PlayerRatingCard from '../PlayerRatingCard';

function ModeratedPlayer() {
  const {
    player,
    resetPlayer,
    isPlayerLoading,
    fetchPlayer,
    fetchPlayerByPhone,
  } = moderatedPlayerStore;

  const [notice, setNotice] = useState(null);
  const [playerSelection, setPlayerSelection] = useState(true);

  const handlePlayerSelectionClick = () => {
    setPlayerSelection(true);
  };

  const handleResetPlayerClick = () => {
    resetPlayer();
  };

  const handlePlayerFound = (playerId) => {
    fetchPlayer(playerId);
  };

  const handlePlayerNotFound = () => {
    setNotice('Игрок не найден');
  };

  const handleScanPlayerCode = async (phone) => {
    const qrPlayer = await fetchPlayerByPhone(phone);
    if (!qrPlayer) {
      setNotice('Игрок не найден');
    }
  };

  return (
    <Card noShadow className={styles.ModeratedPlayer}>
      <TopPageNotice notice={notice} onClose={() => setNotice(null)} />

      {isPlayerLoading && 'Загрузка...'}

      { player && (
        <div className={styles.ModeratedPlayer__info}>
          <PlayerRatingCard player={player} />
          <button
            type="button"
            className="button button_secondary"
            onClick={handleResetPlayerClick}
          >
            Выход
          </button>
        </div>
      )}

      { !player && !playerSelection && (
        <button
          type="button"
          className="button button_fullwidth button_secondary"
          onClick={handlePlayerSelectionClick}
        >
          Выбрать игрока
        </button>
      )}
      { !player && playerSelection && (
        <>
          <h1 className={styles.ModeratedPlayer__title}>Поиск игрока</h1>
          <QRCodeScanner onScan={handleScanPlayerCode}>
            {
              ({ openScanner, isCameraAvailable }) => (
                <button
                  type="button"
                  disabled={!isCameraAvailable}
                  className="button button_fullwidth button_tertiary"
                  onClick={openScanner}
                >
                  Сканировать QR-код игрока
                </button>
              )
            }
          </QRCodeScanner>
          <SearchPlayerForm
            onPlayerFound={handlePlayerFound}
            onPlayerNotFound={handlePlayerNotFound}
          />
        </>
      )}
    </Card>
  );
}

export default observer(ModeratedPlayer);
