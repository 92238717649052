/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { isEventsModerator } from '../../../../../helpers/players';
import moderatedEventStore from '../../../../../stores/moderatedEvents';
import moderatedPlayerStore from '../../../../../stores/moderatedPlayer';
import ModeratedPlayer from '../../../../../components/ModeratedPlayer';
import PageHeader from '../../../../../components/PageHeader';
import Card from '../../../../../components/Card';

import IconSvg from '../../../../../../../common/javascripts/components/IconSvg';

// import Card from '../Card';
import EventPlayers from '../EventPlayers';
import QRCodeEvent from '../QRCodeEvent';
import styles from './ModeratedEvent.module.scss';

function ModeratedEvent({
  eventModerator,
  event,
  day,
  actionsDisabled,
  onClose,
  onAccept,
  onReject,
  onRegistration,
  onResetRegistration,
  onErrorNotice,
}) {
  if (!event) return null;

  const { moderatedEvent } = moderatedEventStore;
  const currentEvent = Object.entries(toJS(moderatedEvent)).length > 0 ? toJS(moderatedEvent) : event;
  const registeredPlayers = currentEvent.registered_players;
  const acceptedPlayers = currentEvent.accepted_players;
  const rejectedPlayers = currentEvent.rejected_players;
  const absentedPlayers = currentEvent.absented_players;
  const playersInWaitingList = currentEvent.players_in_waiting_list;
  const isFreePlaces = currentEvent.is_free_places;
  const freePlaces = currentEvent.free_places;
  const maxRegistredUsers = currentEvent.max_registred_users;
  const { unlimited } = currentEvent;

  const { player } = moderatedPlayerStore;
  const playerId = player ? toJS(player).id : null;
  const isRegistered = currentEvent.registered_player_ids.some((id) => id === playerId);
  const isAccepted = currentEvent.accepted_player_ids.some((id) => id === playerId);
  const isRejected = currentEvent.rejected_player_ids.some((id) => id === playerId);
  const isAbsented = currentEvent.absented_player_ids.some((id) => id === playerId);
  const inWaitingList = currentEvent.players_in_waiting_list_ids.some((id) => id === playerId);
  const isNotRegistered = !isRegistered && !isAccepted && !isRejected && !isAbsented && playerId;

  const handleAcceptClick = () => {
    onAccept(event.id, day);
  };

  const handleRejectClick = () => {
    onReject(event.id, day);
  };

  const handleRegistartionClick = () => {
    onRegistration(event.id, day);
  };

  const handleResetRegistartionClick = () => {
    onResetRegistration(event.id, day);
  };

  const handleEventReportClick = () => {
    window.location = `/events/${event.id}/moderator_report`;
  };

  const handleError = (error) => {
    onErrorNotice(error);
  };

  if (!isEventsModerator(eventModerator)) return null;

  return (
    <div className={styles.ModeratedEvent}>
      <div className={styles.ModeratedEvent__inner}>
        <div className={styles.ModeratedEvent__close} onClick={onClose}>
          <IconSvg icon="close" />
        </div>

        <Card className={styles.ModeratedEvent__playerActions}>
          {isRegistered && (
            <>
              <button
                type="button"
                className="button"
                disabled={actionsDisabled}
                onClick={handleAcceptClick}
              >
                Готово!
              </button>
              {/* <button */}
                {/* type="button" */}
                {/* disabled={actionsDisabled} */}
                {/* className="button button_secondary" */}
                {/* onClick={handleRejectClick} */}
              {/* > */}
                {/* Провалить */}
              {/* </button> */}
            </>
          )}

          {(isNotRegistered && !inWaitingList) && (
            <button
              type="button"
              className="button"
              disabled={actionsDisabled}
              onClick={handleRegistartionClick}
            >
              {(!inWaitingList && !unlimited && !isFreePlaces) ? 'Добавить в лист ожидания' : 'Зарегистрировать'}
            </button>
          )}
        </Card>

        <ModeratedPlayer />

        <QRCodeEvent currentrEventId={event.id} currentEventDay={day} onError={handleError} />

        <Card noShadow className={styles.ModeratedEvent__lists}>
          <PageHeader bottomSpace="m"><h4>{`Всего мест – ${unlimited ? 'Без ограничений' : maxRegistredUsers}`}</h4></PageHeader>

          {!unlimited && (
            <PageHeader bottomSpace="m"><h4>{`Свободно – ${freePlaces}`}</h4></PageHeader>
          )}

          <PageHeader bottomSpace="s"><h4>{`Пришли – ${acceptedPlayers.length}`}</h4></PageHeader>
          <div className={styles.ModeratedEvent__list}>
            <EventPlayers players={acceptedPlayers} />
          </div>

          <PageHeader><h4>{`Занято – ${registeredPlayers.length}`}</h4></PageHeader>
          <div className={styles.ModeratedEvent__list}>
            <EventPlayers players={registeredPlayers} />
          </div>

          {(playersInWaitingList && playersInWaitingList.length > 0) && (
            <>
              <PageHeader bottomSpace="s"><h4>{`Не посетили - ${absentedPlayers.length}`}</h4></PageHeader>
              <div className={styles.ModeratedEvent__list}>
                <EventPlayers players={absentedPlayers} />
              </div>
            </>
          )}

          {(playersInWaitingList && playersInWaitingList.length > 0) && (
            <>
              <PageHeader bottomSpace="s"><h4>{`Лист ожидания - ${playersInWaitingList.length}`}</h4></PageHeader>
              <div className={styles.ModeratedEvent__list}>
                <EventPlayers players={playersInWaitingList} />
              </div>
            </>
          )}

          <div className={styles.ModeratedEvent__list}>
            <button
              type="button"
              className="button button_fullwidth button_secondary"
              disabled={actionsDisabled}
              onClick={handleEventReportClick}
            >
              Отчёт
            </button>
          </div>

          {/* <PageHeader bottomSpace="s"><h4>{`Отменил модератор - ${rejectedPlayers.length}`}</h4></PageHeader> */}
          {/* <div className={styles.ModeratedEvent__list}> */}
          {/* <EventPlayers players={rejectedPlayers} /> */}
          {/* </div> */}

          { ((registeredPlayers && registeredPlayers.length > 0) && (playersInWaitingList && playersInWaitingList.length > 0)) && (
            <button
              type="button"
              className="button button_fullwidth button_secondary"
              disabled={actionsDisabled}
              onClick={handleResetRegistartionClick}
            >
              Сбросить всех, кто не пришел
            </button>
          )}
        </Card>
      </div>
    </div>
  );
}

ModeratedEvent.propTypes = {
  eventModerator: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  event: PropTypes.shape({
    id: PropTypes.number,
    registered_player_ids: PropTypes.arrayOf(PropTypes.number),
    registered_players: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      public_full_name: PropTypes.string,
    })),
    accepted_players: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      public_full_name: PropTypes.string,
    })),
    rejected_players: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      public_full_name: PropTypes.string,
    })),
    absented_players: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      public_full_name: PropTypes.string,
    })),
  }),
  day: PropTypes.string,
  actionsDisabled: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onRegistration: PropTypes.func.isRequired,
  onResetRegistration: PropTypes.func.isRequired,
  onErrorNotice: PropTypes.func.isRequired,
};

ModeratedEvent.defaultProps = {
  event: null,
  day: '1970-01-01',
};

export default observer(ModeratedEvent);
