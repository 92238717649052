import React from 'react';
import PropTypes from 'prop-types';

import IconSvg from '../../../../common/javascripts/components/IconSvg';

import styles from './MarkAsReadButton.module.scss';

export default function MarkAsReadButton({
  label, disabled, onClick,
}) {
  return (
    <button
      className={styles.Button}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {label}
      <IconSvg icon="mark-all-read" />
    </button>
  );
}

MarkAsReadButton.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
