import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Card.module.scss';

export default function Card({ children, noShadow, noPadding, className }) {
  return (
    <div
      className={
        cn(className, styles.Card,
          { [styles.Card_shadow]: !noShadow },
          { [styles.Card_padding]: !noPadding })
      }
    >
      {children}
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.node,
  noShadow: PropTypes.bool,
  noPadding: PropTypes.bool,
  className: PropTypes.string,
};

Card.defaultProps = {
  children: null,
  noShadow: false,
  noPadding: false,
  className: '',
};
