import React from 'react';
import PropTypes from 'prop-types';

const IconSvg = ({ icon, className, iconPrefix }) => (
  <svg className={className}>
    <use xlinkHref={`#${iconPrefix}${icon}`} />
  </svg>
);

IconSvg.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconPrefix: PropTypes.string,
};

IconSvg.defaultProps = {
  className: '',
  iconPrefix: 'icon__',
};

export default IconSvg;
