/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '../../../Avatar';

import styles from './MenuAvatar.module.scss';

export default function TopbarMenuAvatar({ avatarUrl, avatarType, linkUrl }) {
  return (
    <a href={linkUrl} className={styles.Avatar}>
      <Avatar type={avatarType} avatar={avatarUrl} />
    </a>
  );
}

TopbarMenuAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  avatarType: PropTypes.string,
  linkUrl: PropTypes.string,
};

TopbarMenuAvatar.defaultProps = {
  avatarUrl: null,
  avatarType: null,
  linkUrl: null,
};
