import React from 'react';
import PropTypes from 'prop-types';

import WinnerAward from './components/WinnerAward';
import PageCardsList from '../../components/PageCardsList';

function WinnersScreen({ isAdmin, prizes, showLikePlayer }) {
  const prizeItems = prizes.map(({
    group,
    award,
    winner,
    announce_date: announceDate,
  }) => (
    <li key={`${group}_${award.id}`}>
      <WinnerAward
        award={award}
        isAdmin={isAdmin}
        group={group}
        winner={winner}
        announceDate={announceDate}
        showLikePlayer={showLikePlayer}
      />
    </li>
  ));

  return (
    <PageCardsList>
      {prizeItems}
    </PageCardsList>
  );
}

WinnersScreen.propTypes = {
  prizes: PropTypes.arrayOf(PropTypes.shape({
    group: PropTypes.string,
    award: PropTypes.shape({
      id: PropTypes.number,
    }),
    winner: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
    })),
    announce_date: PropTypes.string,
  })).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  showLikePlayer: PropTypes.bool,
};

WinnersScreen.defaultProps = {
  showLikePlayer: false,
};

export default WinnersScreen;
