import React from 'react';
import PropTypes from 'prop-types';

import styles from './Header.module.scss';

export default function NotificationCardHeader({ id, statusText }) {
  return (
    <div className={styles.NotificationCardHeader}>
      {id && (
        <div className={styles.NotificationCardHeader__id}>
          {`#${id}`}
        </div>
      )}
      { statusText && (
        <div className={styles.NotificationCardHeader__statusText}>{statusText}</div>
      )}
    </div>
  );
}

NotificationCardHeader.propTypes = {
  id: PropTypes.number,
  statusText: PropTypes.string,
};

NotificationCardHeader.defaultProps = {
  id: null,
  statusText: '',
};
