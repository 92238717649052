import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './CoverImage.module.scss';

export default function EventCoverImage({ imageUrl, inList, inCard }) {
  if (!imageUrl) return null;

  const className = cn(
    styles.CoverImage,
    { [styles.CoverImage_inList]: inList },
    { [styles.CoverImage_inCard]: inCard },
  );

  return (
    <>
      {
        inList && (
          <div
            className={className}
            style={{ backgroundImage: `url(${imageUrl})` }}
          />
        )
      }
      {
        inCard && (
          <div className={styles.CoverImage}>
            <img
              className={styles.CoverImage__img}
              src={imageUrl}
              alt="Event"
            />
          </div>
        )
      }

    </>
  );
}

EventCoverImage.propTypes = {
  imageUrl: PropTypes.string,
  inList: PropTypes.bool,
  inCard: PropTypes.bool,
};

EventCoverImage.defaultProps = {
  imageUrl: null,
  inList: false,
  inCard: false,
};
