import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { phoneMask, isPhoneValid } from '../../../../helpers/phone';
import { visitOrRedirectTo } from '../../../../../../common/javascripts/helpers';

import IconSvg from '../../../../../../common/javascripts/components/IconSvg';

import styles from '../../InvitePlayer.module.scss';

const playerSearchPath = '/players/search';
const playerProfilePath = (id) => `/players/${id}`;

export default function TeamPlayerSearch({ onPlayerNotFound }) {
  const [phone, setPhone] = useState('');
  const [playerId, setPlayerId] = useState('');

  const [canSearch, setCanSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleBeforeMaskedStateChange = ({ nextState }) => {
    let { value } = nextState;
    const { enteredString } = nextState;
    const digits = enteredString ? enteredString.replace(/[^0-9]/g, '') : '';

    if (digits.length >= 10) {
      const maskPhone = digits.slice(-10);

      value = `+7 (${maskPhone.slice(0, 3)}) ${maskPhone.slice(3, 6)}-${maskPhone.slice(6, 8)}-${maskPhone.slice(8, 10)}`;
    }

    return {
      ...nextState,
      value,
    };
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handlePlayerIdChange = (e) => {
    setPhone('');
    setPlayerId(e.target.value);
  };

  useEffect(() => {
    const valid = isPhoneValid(phone) || playerId;
    setCanSearch(valid);
  }, [phone, playerId]);

  const handleSearchClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const url = `${playerSearchPath}?phone=${phone}&player_id=${playerId}`;
      const response = await fetch(url, {
        method: 'GET',
      });
      const result = await response.json();

      setIsLoading(false);

      if (response.ok) {
        if (result.status === 'ok') {
          visitOrRedirectTo(playerProfilePath(result.player_id));
        } else if (result.status === 'not_found') {
          onPlayerNotFound(phone);
        }
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  return (
    <form noValidate className={styles.TeamInvitePlayer}>
      <IconSvg icon="team-invite-player" className={styles.TeamInvitePlayer__icon} />
      <h1 className={styles.TeamInvitePlayer__title}>Пригласить игрока в команду</h1>
      <div className={styles.TeamInvitePlayer__phoneInputWrap}>
        <InputMask
          mask={phoneMask}
          alwaysShowMask
          onChange={handlePhoneChange}
          value={phone}
          beforeMaskedStateChange={handleBeforeMaskedStateChange}
        >
          <input
            className="input input_large input_attention"
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
          />
        </InputMask>

        <div className={styles.TeamInvitePlayer__orDivider}>
          или
        </div>

        <input
          className="input input_large input_attention"
          type="text"
          pattern="[0-9]*"
          inputMode="numeric"
          placeholder="ID игрока"
          onChange={handlePlayerIdChange}
          valuer={playerId}
        />
      </div>

      <button
        className="button button_large button_fullwidth"
        disabled={!canSearch || isLoading}
        onClick={handleSearchClick}
        type="submit"
      >
        Поиск
      </button>
    </form>
  );
}

TeamPlayerSearch.propTypes = {
  onPlayerNotFound: PropTypes.func.isRequired,
};
