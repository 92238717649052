import React from 'react';
import PropTypes from 'prop-types';
import IconSvg from 'common/javascripts/components/IconSvg';


TaskFailedMessage.propTypes = {
};

export default function TaskFailedMessage() {
  return (
    <>

    </>
  );
}
