import React from 'react';
import PropTypes from 'prop-types';

import ProductInfo from '../components/ProductInfo';
import ProductOrderInfo from './components/OrderInfo';
// import ProductVariantCodes from '../components/ProductVariantCodes';
import ProductPartnersList from '../components/PartnersList';
import TaskCallWriteSnippet from '../../Task/Show/components/CallWriteSnippet';

export default function ProductOrderShowScreen({ order, contact }) {
  const text = {
    iconText: 'Если возникла проблема',
    cardHeaderText: contact.header_card || 'Выдача призов',
  };

  return (
    <>
      <ProductOrderInfo order={order} />
      <ProductInfo product={order.product} isAward={order.award_for_registration} />
      {/* <ProductVariantCodes codes={order.product_variant_codes} /> */}
      <TaskCallWriteSnippet contact={contact} textContent={text} />
      <ProductPartnersList partners={order.partners} />
    </>
  );
}

ProductOrderShowScreen.propTypes = {
  contact: PropTypes.arrayOf(PropTypes.shape({
    phone: PropTypes.string,
  })).isRequired,
  order: PropTypes.shape({
    id: PropTypes.number,
    award_for_registration: PropTypes.bool,
    product: PropTypes.shape({
      id: PropTypes.number,
    }),
    partners: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
  }).isRequired,
};
