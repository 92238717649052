import React from 'react';
import PropTypes from 'prop-types';

import styles from './ProductVariantCodes.module.scss';


ProductVariantCodes.propTypes = {
  codes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      end_at: PropTypes.string,
      state: PropTypes.string
    })
  )
};


export default function ProductVariantCodes({ codes }) {
  return (
    <div className={styles.ProductVariantCodes}>
      <div className={styles.ProductVariantCodes__title}>
        Коды
      </div>
      <div className={styles.ProductVariantCodes__descriptionWrap}>
        {codes.map((code) =>
          <div key={code.id}>
            <div>{code.code}</div>
          </div>
        )}
      </div>
    </div>
  );
}
