import React from 'react';
import PropTypes from 'prop-types';
import FromEditor from '../../../../../components/FromEditor';
import CardHeader from '../../../../../components/CardHeader';
import Card from '../../../../../components/Card';

// import IconSvg from '../../../../../../../common/javascripts/components/IconSvg';

import CardVideos from '../../../../../components/CardVideos';
import CardAudios from '../../../../../components/CardAudios';
import CardImages from '../../../../../components/CardImages';

import styles from './Hint.module.scss';

export default function Hint({ hint }) {
  return (
    <Card noshadow className={styles.TaskHint}>
      <div className={styles.TaskHint__header}>
        {/* <IconSvg icon="booster-hint" /> */}
        <CardHeader>
          <h2>{hint.title}</h2>
        </CardHeader>
      </div>
      <FromEditor>
        {hint.description}
      </FromEditor>
      <div className={styles.TaskHint__attachments}>
        <CardImages images={hint.images} header="Посмотри внимательно изображение" />
        <CardVideos videos={hint.videos} header="Посмотри внимательно видео" />
        <CardAudios audios={hint.audios} header="Послушай внимательно аудио" />
      </div>
    </Card>
  );
}

Hint.propTypes = {
  hint: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
    videos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
    audios: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
  }).isRequired,
};
