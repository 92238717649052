import {
  makeObservable,
  observable,
  computed,
  runInAction,
  onBecomeObserved,
} from 'mobx';

import PlayerViewApi from './playerViewsApi';

class PlayerViewStore {
  unreadNotifications = 0

  unreadNews = 0

  constructor(playerViewApi) {
    makeObservable(this, {
      unreadNotifications: observable,
      unreadNews: observable,
      unread: computed,
    });
    this.playerViewApi = playerViewApi;
    onBecomeObserved(this, 'unread', this.fetchUnread);

    this.viewTimeout = null;
    this.markableNotificationIds = [];
    this.markableNewsArticleIds = [];
  }

  get unread() {
    return this.unreadNotifications + this.unreadNews;
  }

  fetchUnread = async () => {
    try {
      const data = await this.playerViewApi.fetchUnread();

      runInAction(() => {
        this.unreadNotifications = data.unread_notifications;
        this.unreadNews = data.unread_news;
      });
    } catch (error) {
      console.error(error);
    }
  };

  markNotificationAsViewed = (notificationId) => {
    this.markableNotificationIds = [...this.markableNotificationIds, notificationId];
    this.enqueueRequest();
  };

  markNewsArticleAsViewed = (newsArticleId) => {
    this.markableNewsArticleIds = [...this.markableNewsArticleIds, newsArticleId];
    this.enqueueRequest();
  };

  enqueueRequest = () => {
    if (this.viewTimeout) {
      clearTimeout(this.viewTimeout);
    }
    this.viewTimeout = setTimeout(this.markRecordsAsViewed, 300);
  }

  markRecordsAsViewed = async () => {
    try {
      if (this.markableNotificationIds.length === 0 && this.markableNewsArticleIds.length === 0) {
        return;
      }

      const data = await this.playerViewApi.markAsViewed({
        notificationIds: this.markableNotificationIds,
        newsArticleIds: this.markableNewsArticleIds,
      });

      this.markableNotificationIds = [];
      this.markableNewsArticleIds = [];

      this.setUnreadCounters(data);
    } catch (error) {
      console.error(error);
    }
  };

  setUnreadCounters = (response) => {
    runInAction(() => {
      this.unreadNotifications = response.unread_notifications;
      this.unreadNews = response.unread_news;
    });
  }

  markAllNewsArticlesAsViewed = async () => {
    try {
      const data = await this.playerViewApi.markAllNewsAsViewed();
      this.setUnreadCounters(data);
    } catch (error) {
      console.error(error);
    }
  }

  markAllNotificationsAsViewed = async () => {
    try {
      const data = await this.playerViewApi.markAllNotificationsAsViewed();
      this.setUnreadCounters(data);
    } catch (error) {
      console.error(error);
    }
  }
}

const store = new PlayerViewStore(new PlayerViewApi());

export default store;
