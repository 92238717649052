import React from 'react';
import PropTypes from 'prop-types';

import PageHeader from '../../components/PageHeader';
import PlayerRatingCard from '../../components/PlayerRatingCard';

import styles from './TopByTagPlayers.module.scss';

function TopByTagPlayersScreen({ players, tag }) {
  console.log(players);

  return (
    <div className={styles.TopPlayersList}>
      <PageHeader bottomSpace="m">
        <h1>{`#${tag}`}</h1>
      </PageHeader>
      {
        players.map((player) => (
          <div className={styles.TopPlayersList__item} key={player.id}>
            <PlayerRatingCard player={player} />
          </div>
        ))
      }
    </div>
  );
}

TopByTagPlayersScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  players: PropTypes.array.isRequired,
  tag: PropTypes.string.isRequired,
};

TopByTagPlayersScreen.defaultProps = {
};

export default TopByTagPlayersScreen;
