import { action, makeObservable, observable, runInAction } from 'mobx';

import moderatedPlayerStore from './moderatedPlayer';
import OrdersApi from './ordersApi';

class ModeratedOrders {
  orders = []

  isLoading = false

  isOrderUpdating = false;

  constructor(playerStore, ordersApi) {
    makeObservable(this, {
      orders: observable,
      isLoading: observable,
      isOrderUpdating: observable,
      fetchOrders: action,
      completeOrder: action,
      cancelOrder: action,
      replaceOrder: action,
    });
    this.ordersApi = ordersApi;
    this.moderatedPlayerStore = playerStore;
  }

  fetchOrders = async () => {
    const { player } = this.moderatedPlayerStore;
    if (player) {
      this.isLoading = true;
      const result = await this.ordersApi.fetchOrders(player.id);
      runInAction(() => {
        this.isLoading = false;
        this.orders = result.product_orders;
      });
    } else {
      this.orders = [];
    }
  }

  completeOrder = async (orderId) => {
    const { player } = this.moderatedPlayerStore;
    if (player) {
      this.isOrderUpdating = true;
      const result = await this.ordersApi.completeOrder(player.id, orderId);
      this.replaceOrder(result.product_order);
    }
  }

  cancelOrder = async (orderId) => {
    const { player } = this.moderatedPlayerStore;
    if (player) {
      this.isOrderUpdating = true;
      const result = await this.ordersApi.cancelOrder(player.id, orderId);
      this.replaceOrder(result.product_order);
    }
  }

  replaceOrder = (updatedOrder) => {
    if (updatedOrder) {
      const orderIndex = this.orders.findIndex((a) => a.id === updatedOrder.id);

      if (orderIndex >= 0) {
        this.orders = [
          ...this.orders.slice(0, orderIndex),
          updatedOrder,
          ...this.orders.slice(orderIndex + 1, this.orders.length),
        ];
      }
    }

    this.isOrderUpdating = false;
  }
}

export default new ModeratedOrders(moderatedPlayerStore, new OrdersApi());
