import React from 'react';
import PropTypes from 'prop-types';
import IconSvg from '../../../../common/javascripts/components/IconSvg';
import styles from './Cost.module.scss';

export default function Cost({
  icon, amount, name, showZero,
}) {
  const amountToShow = amount || 0;

  if (amountToShow === 0 && !showZero) return null;

  return (
    <div className={styles.Cost}>
      <IconSvg icon={icon} className={styles.Cost__icon} />
      <span className={styles.Cost__amount}>{amountToShow}</span>
      {name && <span className={styles.Cost__name}>{name}</span>}
    </div>
  );
}

Cost.propTypes = {
  icon: PropTypes.oneOf(['player-points', 'team-points', 'bonus-points']),
  amount: PropTypes.number,
  name: PropTypes.string,
  showZero: PropTypes.bool,
};

Cost.defaultProps = {
  icon: 'player-points',
  amount: 0,
  name: null,
  showZero: false,
};
