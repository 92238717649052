import React from 'react';
import PropTypes from 'prop-types';

import styles from './Footer.module.scss';

export default function NotificationCardFooter({ children }) {
  return (
    <div className={styles.NotificationCardFooter}>
      {children}
    </div>
  );
}
NotificationCardFooter.propTypes = {
  children: PropTypes.node.isRequired,
};
