import React from 'react';
import PropTypes from 'prop-types';
import TagsFilter from '../../../../../components/TagsFilter';
import TagsFilterList from '../../../../../components/TagsFilterList';
import TagsFilterButton from '../../../../../components/TagsFilterButton';

export default function TasksListFilter({ filter, taskTags, player, onFilterChange }) {
  const handleSystemTagClick = (newSystem) => {
    onFilterChange({
      system: newSystem,
      tagId: null,
    });
  };

  const handleTaskTagClick = (newTagId) => {
    onFilterChange({
      system: null,
      tagId: newTagId,
    });
  };

  const handleAllTagClick = () => {
    onFilterChange({
      system: null,
      tagId: null,
    });
  };

  const allTag = (
    <TagsFilterButton
      label={`Все (${player.tasks_all})`}
      onClick={handleAllTagClick}
      active={filter.tagId === null && filter.system === null}
    />
  );
  const favouritesTag = (
    <TagsFilterButton
      label={`Избранные (${player.tasks_favourite})`}
      onClick={() => handleSystemTagClick('favourites')}
      active={filter.system === 'favourites'}
    />
  );
  const postpendTag = (
    <TagsFilterButton
      label={`Позже (${player.tasks_postponed})`}
      onClick={() => handleSystemTagClick('postpend')}
      active={filter.system === 'postpend'}
    />
  );
  const completedTag = (
    <TagsFilterButton
      label={`Выполненные (${player.tasks_complete})`}
      onClick={() => handleSystemTagClick('completed')}
      active={filter.system === 'completed'}
    />
  );
  const pendingTag = (
    <TagsFilterButton
      label={`Проверяется (${player.tasks_pending})`}
      onClick={() => handleSystemTagClick('pending')}
      active={filter.system === 'pending'}
    />
  );
  const failedTag = (
    <TagsFilterButton
      label={`Проваленные (${player.tasks_failed})`}
      onClick={() => handleSystemTagClick('failed')}
      active={filter.system === 'failed'}
    />
  );
  const endTodayTag = (
    <TagsFilterButton
      label={`Закрывается сегодня (${player.tasks_end_today})`}
      onClick={() => handleSystemTagClick('endToday')}
      active={filter.system === 'endToday'}
    />
  );

  // eslint-disable-next-line no-unused-vars
  const blockedTag = (
    <TagsFilterButton
      label={`Заблокированные (${player.tasks_blocked})`}
      onClick={() => handleSystemTagClick('blocked')}
      active={filter.system === 'blocked'}
    />
  );
  const taskTagItems = taskTags.map((tag) => (
    <li key={`tagItem${tag.id}`}>
      <TagsFilterButton
        label={tag.count === -1 ? tag.title : `${tag.title} (${tag.count})`}
        onClick={() => handleTaskTagClick(tag.id)}
        active={filter.tagId === tag.id}
      />
    </li>
  ));

  return (
    <TagsFilter>
      <TagsFilterList>
        <li>{allTag}</li>
        <li>{favouritesTag}</li>
        <li>{postpendTag}</li>
        <li>{endTodayTag}</li>
        <li>{completedTag}</li>
        <li>{pendingTag}</li>
        <li>{failedTag}</li>
        {/* <li>{blockedTag}</li> */}
        {taskTagItems}
      </TagsFilterList>
    </TagsFilter>
  );
}

TasksListFilter.propTypes = {
  filter: PropTypes.shape({
    system: PropTypes.string,
    tagId: PropTypes.number,
  }),
  taskTags: PropTypes.instanceOf(Array),
  player: PropTypes.shape({
    tasks_blocked: PropTypes.number,
    tasks_complete: PropTypes.number,
    tasks_end_today: PropTypes.number,
    tasks_failed: PropTypes.number,
    tasks_pending: PropTypes.number,
    tasks_favourite: PropTypes.number,
    tasks_all: PropTypes.number,
    tasks_postponed: PropTypes.number,
  }),
  onFilterChange: PropTypes.func.isRequired,
};

TasksListFilter.defaultProps = {
  filter: {
    system: null,
    tagId: null,
  },
  player: {
    tasks_blocked: 0,
    tasks_complete: 0,
    tasks_end_today: 0,
    tasks_failed: 0,
    tasks_pending: 0,
    tasks_favourite: 0,
    tasks_all: 0,
  },
  taskTags: [],
};
