import React from 'react';
import PropTypes from 'prop-types';
import PageCardsList from '../../components/PageCardsList';
import TopAwardGroup from './components/Group';

// import styles from './TopAwards.module.scss';

export default function TopAwardsScreen({ awardGroups, isOnlyQuest }) {
  return (
    <PageCardsList>
      {
        awardGroups.map((group) => (
          <TopAwardGroup
            key={group.id}
            group={group}
            isOnlyQuest={isOnlyQuest}
          />
        ))
      }
    </PageCardsList>
  );
}

TopAwardsScreen.propTypes = {
  awardGroups: PropTypes.instanceOf(Array).isRequired,
  isOnlyQuest: PropTypes.bool,
};

TopAwardsScreen.defaultProps = {
  isOnlyQuest: false,
};
