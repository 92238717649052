// Styles
import './stylesheets/index.scss';

// Js
import './javascripts';

// Media
const importAll = (r) => { r.keys().map(r); };
// icons for sprite
importAll(require.context('./icons/', true, /\.svg$/));
// images
importAll(require.context('./images/', true));
importAll(require.context('common/images/', true));
