import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconSvg from '../../../../common/javascripts/components/IconSvg';

import styles from './AttentionMessage.module.scss';

export default function AttentionMessage({ message, children, isHint, className }) {
  if (!message && !children) {
    return null;
  }

  return (
    <div className={cn(className, styles.Message, { [styles.Message_isHint]: isHint })}>
      <IconSvg icon="attention" className={styles.Message__icon} />
      <span className={styles.Message__content}>{children || message}</span>
    </div>
  );
}

AttentionMessage.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node,
  isHint: PropTypes.bool,
  className: PropTypes.string,
};

AttentionMessage.defaultProps = {
  message: null,
  children: null,
  isHint: false,
  className: null,
};
