import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../../../components/Avatar';

import styles from './WinnerProfile.module.scss';

function WinnerProfile({ winner }) {
  if (!winner) return null;

  const showPhone = !(winner.type === 'team');

  return (
    <div className={styles.WinnerProfile}>
      <div className={styles.WinnerProfile__avatar}>
        <Avatar type={winner.type} avatar={winner.avatar} />
      </div>
      <div className={styles.WinnerProfile__info}>
        {winner.name}
        {showPhone && (
          <>
            <br />
            {winner.phone}
          </>
        )}
      </div>
    </div>
  );
}

WinnerProfile.propTypes = {
  winner: PropTypes.shape({
    type: PropTypes.oneOf(['team', 'player']),
    avatar: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
  }),
};

WinnerProfile.defaultProps = {
  winner: null,
};

export default WinnerProfile;
