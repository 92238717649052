import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import IconSvg from '../../../../../common/javascripts/components/IconSvg';
import NotificationCard from '../components/Card';
import NotificationCardHeader from '../components/Header';
import NotificationCardFooter from '../components/Footer';
import NotificationCardPoints from '../components/Points';
import NotificationTitle from '../components/Title';

import styles from './Task.module.scss';

const icons = {
  task_pending: 'task-pending',
  task_step_pending: 'task-pending',
  task_invite_pending: 'task-pending',
  task_completed: 'task-completed',
  task_step_completed: 'task-completed',
  task_invite_completed: 'task-completed',
  task_failed: 'task-failed',
  task_step_failed: 'task-failed',
  task_fail_invite: 'task-failed',
  task_rejected: 'task-failed',
  task_pending_to_available: 'task-pending',
};

const pointsKinds = {
  task_completed: 'income',
  task_rejected: 'expense',
};

// const notificationText = {
//   task_failed: 'Используй приз Второй шанс, чтобы повторно пройти задание.',
//   task_pending: 'В течение 24 часов мы проверим твой ответ и поменяем статус задания.',
// };

export default function NotificationTaskCard({ notification }) {
  if (!notification) return null;

  const { action } = notification;
  const {
    player_points: playerPoints,
    team_points: teamPoints,
    bonus_points: bonusPoints,
  } = notification.data;

  const isAnyPoints = !!(playerPoints || teamPoints || bonusPoints);
  const showFooter = (action === 'task_completed' || action === 'task_rejected' || action === 'task_invite_completed') && isAnyPoints;
  const taskBody = (action === 'task_fail_invite' || action === 'task_invite_pending') ? notification.data.body.match(/для[\s.]*\+7[\s.]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g)[0] : notification.data.body;

  return (
    <NotificationCard notification={notification}>
      <NotificationCardHeader
        id={notification.data.task_id}
        statusText={notification.data.title}
      />

      <div className={styles.TaskBase}>
        <IconSvg
          icon={icons[action]}
          className={cn(styles.TaskBase__icon, styles[`TaskBase__icon_${action}`])}
        />

        <div className={styles.TaskBase__content}>
          <NotificationTitle title={notification.data.task_title} />
          {
            taskBody
            && (
              <div className={styles.TaskBase__text}>{taskBody}</div>
            )
          }
        </div>
      </div>

      {
        showFooter
        && (
          <NotificationCardFooter>
            <NotificationCardPoints
              playerPoints={playerPoints}
              teamPoints={teamPoints}
              bonusPoints={bonusPoints}
              kind={pointsKinds[action]}
            />
          </NotificationCardFooter>
        )
      }
    </NotificationCard>
  );
}

NotificationTaskCard.propTypes = {
  notification: PropTypes.object.isRequired,
};
