import React from 'react';
import PropTypes from 'prop-types';
import Card from '../../../../components/Card';

import AuthPageHeader from '../Header';

import styles from './../../Auth.module.scss';

export default function AuthRulesAndInfo({ onClose }) {
  return (
    <Card noShadow>
      <div className={styles.Auth}>
        <div className={styles.Auth__ctaText}>
          добро пожаловать<br />на борт, юнга
        </div>

        <div className={styles.Auth__step}>
          <div className={styles.Auth__rulesAndInfoRow}>
            <div className={styles.Auth__rulesAndInfoCounter}>1</div>
            <div className={styles.Auth__rulesAndInfoItem}>Регистрируйся в <strong>бесплатном</strong> квесте</div>
          </div>
          <div className={styles.Auth__rulesAndInfoRow}>
            <div className={styles.Auth__rulesAndInfoCounter}>2</div>
            <div className={styles.Auth__rulesAndInfoItem}>Разгадывай загадки, выполняй креативные задания один или с друзьями</div>
          </div>
          <div className={styles.Auth__rulesAndInfoRow}>
            <div className={styles.Auth__rulesAndInfoCounter}>3</div>
            <div className={styles.Auth__rulesAndInfoItem}>Выигрывай участие в парусной регате, полет на воздушном шаре, вейксерф и другие призы</div>
          </div>
        </div>


        <div className={styles.Auth__submit}>
          <a
            className='button button_large button_fullwidth'
            onClick={onClose}
          >
            Ок, мне понятно
          </a>
        </div>
      </div>
    </Card>
  );
}


AuthRulesAndInfo.propTypes = {
  onClose: PropTypes.func
};
