import React, { useEffect } from 'react';

const SCROLL_ELEMENT_NAME = 'scrollToElement';

const getDefaultContainer = () => document.getElementById('js-scroll-container');

function saveScrollElementToHistory(elementId) {
  window.history.replaceState({
    ...(window.history.state || {}),
    [SCROLL_ELEMENT_NAME]: elementId,
  }, document.title);
}

const scrollElementFromHistory = () => {
  const scrollElementId = (window.history.state || {})[SCROLL_ELEMENT_NAME];
  if (scrollElementId) {
    return document.getElementById(scrollElementId);
  }
  return null;
};

const scrollElementFromParam = (param) => {
  const queryParams = new URLSearchParams(window.location.search);
  const scrollElementId = queryParams.get(param);
  if (scrollElementId) {
    return document.getElementById(scrollElementId);
  }
  return null;
};

function scrollToElement({
  getTarget,
  getContainer = getDefaultContainer,
  offsetX = 0,
  offsetY = -30,
}) {
  const el = getTarget();
  const scrollContainer = getContainer();
  if (scrollContainer && el) {
    const containerRect = scrollContainer.getBoundingClientRect();
    const elRect = el.getBoundingClientRect();
    const scrollOffsetX = elRect.left - containerRect.left + offsetX + scrollContainer.scrollLeft;
    const scrollOffsetY = elRect.top - containerRect.top + offsetY + scrollContainer.scrollTop;
    scrollContainer.scrollTo(scrollOffsetX, scrollOffsetY);
  }
}

function horizontalScrollElementToCenter({
  getTarget,
  getContainer,
}) {
  const el = getTarget();
  const scrollContainer = getContainer();
  const scrollLeft = (el.offsetLeft + el.offsetWidth / 2) - scrollContainer.offsetWidth / 2;
  scrollContainer.scrollLeft = scrollLeft;
}

function scrollOnMount({
  getTarget = scrollElementFromHistory,
  getContainer = getDefaultContainer,
  offsetX = 0,
  offsetY = 0,
} = {
  getTarget: scrollElementFromHistory,
  getContainer: getDefaultContainer,
  offsetX: 0,
  offsetY: 0,
}) {
  return function enhance(WrappedComponent) {
    return function EnhancedComponent(props) {
      useEffect(() => {
        scrollToElement({
          getTarget,
          getContainer,
          offsetX,
          offsetY,
        });
      }, []);

      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...props} />;
    };
  };
}

export {
  scrollToElement,
  horizontalScrollElementToCenter,
  scrollOnMount,
  scrollElementFromHistory,
  scrollElementFromParam,
  saveScrollElementToHistory,
};
