import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../../../../components/PageHeader';
import Card from '../../../../../components/Card';
import BottomSlideUp from '../../../../../components/BottomSlideUp';
import ProfileShowScreen from '../../../../Profile/Show';

import styles from './ProfilesList.module.scss';
import clickCounter from '../../../../../helpers/counter';

export default function EventProfilesList({ profiles }) {
  const [showSlideUp, setShowSlideUp] = useState(false);
  const [currentProfile, setCurrentProfile] = useState({});

  if (profiles && profiles.length === 0) return null;

  const handleClickProfile = (profile) => {
    clickCounter(`/profiles/${profile.id}`, profile);

    if (profile.redirect_url) {
      window.open(`https://${profile.redirect_url}`, '_blank');
      return;
    }
    setCurrentProfile(profile);
    setShowSlideUp(!showSlideUp);
  };

  return (
    <div className={styles.ProfilesList}>
      <PageHeader>
        <h2>Лица проекта</h2>
      </PageHeader>
      <ul className={styles.ProfilesList__list}>
        {
          profiles.map((profile) => (
            <li key={profile.id}>
              <a onClick={handleClickProfile.bind(null, profile)} className={styles.ProfilesList__link}>
                <Card className={styles.ProfilesList__profile}>
                  <div
                    className={styles.ProfilesList__profileImage}
                    style={{ backgroundImage: `url(${profile.cover_url})` }}
                  />
                  <div className={styles.ProfilesList__profileName}>
                    {profile.name}
                  </div>
                </Card>
              </a>
            </li>
          ))
          }
      </ul>
      <BottomSlideUp show={showSlideUp} onClose={() => setShowSlideUp(false)}>
        <ProfileShowScreen profile={currentProfile} />
      </BottomSlideUp>
    </div>
  );
}

EventProfilesList.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    cover_url: PropTypes.string,
    redirect_url: PropTypes.string,
  })).isRequired,
};
