import React from 'react';
import PropTypes from 'prop-types';

import CardHeader from '../../../../../components/CardHeader';
import IconSvg from '../../../../../../../common/javascripts/components/IconSvg';

import styles from './StepHeader.module.scss';

export default function StepHeader({
  stepTitle,
  stepId,
  stepNumber,
  isOneStepTask,
  isBlocked,
  isCompleted,
  isPending,
  toggleCollapsed,
}) {
  return (
    <div
      role="button"
      className={styles.StepHeader}
      tabIndex={0}
      onClick={toggleCollapsed}
      onKeyDown={toggleCollapsed}
      id={stepId}
    >
      <div>
        {
          !isOneStepTask && (
            <div className={styles.StepHeader__stepNumber}>
              {`Шаг ${stepNumber}`}
            </div>
          )
        }
        {
          stepTitle && (
            <CardHeader>
              <h2>{stepTitle}</h2>
            </CardHeader>
          )
        }
      </div>

      {
        isCompleted
        && (
          <div className={styles.StepHeader__status}>
            выполнено
            <IconSvg icon="task-completed" />
          </div>
        )
      }

      {
        isBlocked
        && (
          <div className={styles.StepHeader__status}>
            заблокирован
            <IconSvg icon="task-blocked" />
          </div>
        )
      }

      {
        isPending
        && (
          <div className={styles.StepHeader__status}>
            проверяется
            <IconSvg icon="task-pending" />
          </div>
        )
      }
    </div>
  );
}

StepHeader.propTypes = {
  stepTitle: PropTypes.string,
  stepId: PropTypes.string,
  stepNumber: PropTypes.number.isRequired,
  isBlocked: PropTypes.bool,
  isOneStepTask: PropTypes.bool,
  isCompleted: PropTypes.bool,
  isPending: PropTypes.bool,
  toggleCollapsed: PropTypes.func,
};

StepHeader.defaultProps = {
  stepTitle: null,
  stepId: 'stepBlocked',
  isOneStepTask: false,
  isCompleted: false,
  isBlocked: false,
  isPending: false,
  toggleCollapsed: () => {},
};
