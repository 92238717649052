class PlayersApi {
  getPlayer = async (id) => {
    const response = await fetch(`/players/${id}.json`);
    if (response.ok) {
      return response.json();
    }
    return null;
  }

  searchPlayerByPhone = async (phone) => {
    const response = await fetch(`/players/search?phone=${phone}`);
    return response.json();
  }
}

export default PlayersApi;
