import React from 'react';
import PropTypes from 'prop-types';

import BottomSlideUp from '../../../BottomSlideUp';

// import currentPlayerStore from '../../stores/currentPlayerStore';

// import AACardImage from '../../../images/card-aa.png';
import styles from './SlideUp.module.scss';

export default function TinkoffPromoSlideUp({ isBalance, show, onClose, setting }) {
  return (
    <BottomSlideUp show={show} onClose={onClose}>
      <div className={styles.SlideUp}>
        <div className={styles.SlideUp__text}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: setting.description_card }} />
        </div>
      </div>
      <button
        className="button button_large button_fullwidth"
        onClick={onClose}
        type="button"
      >
        OK, супер
      </button>
    </BottomSlideUp>
  );
}

TinkoffPromoSlideUp.propTypes = {
  isBalance: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setting: PropTypes.shape({
    mg_url_card: PropTypes.string,
    title_card: PropTypes.string,
    text_card: PropTypes.string,
    description_card: PropTypes.string.isRequired,
  }).isRequired,
};

TinkoffPromoSlideUp.defaultProps = {
  isBalance: false,
};
