/* eslint-disable import/no-unresolved */
import React, { useState, useRef, useLayoutEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';
import cn from 'classnames';
import FromEditor from 'client/javascripts/components/FromEditor';
import IconSvg from 'common/javascripts/components/IconSvg';

import styles from './Card.module.scss';
import clickCounter from '../../../../../helpers/counter';

export default function FaqCard({ faq, question, answerHtml }) {
  const [isOpen, setIsOpen] = useState(false);
  const [answerHeight, setAnswerHeight] = useState('0px');
  const answerElem = useRef();

  useLayoutEffect(() => {
    setAnswerHeight(answerElem.current.clientHeight);
  }, []);

  const expand = useSpring({
    height: isOpen ? `${answerHeight}px` : '10px',
  });

  const handleClick = () => {
    setIsOpen(!isOpen);
    clickCounter(`/help/faq/${faq.id}`, faq);
  };

  return (
    <div className={cn(styles.Card, { [styles.Card_isOpen]: isOpen })}>
      <button
        className={styles.Card__questionWrap}
        onClick={handleClick}
        type="button"
      >
        <div className={styles.Card__question}>{question}</div>
        <IconSvg icon="down-circle" className={styles.Card__toggleIcon} />
      </button>

      <animated.div className={styles.Card__accordion} style={expand}>
        <div ref={answerElem} className={styles.Card__answer}>
          <FromEditor>
            {answerHtml}
          </FromEditor>
        </div>
      </animated.div>
    </div>
  );
}

FaqCard.propTypes = {
  faq: PropTypes.shape({
    id: PropTypes.number,
  }),
  question: PropTypes.string,
  answerHtml: PropTypes.string,
};

FaqCard.defaultProps = {
  faq: {},
  question: '',
  answerHtml: '',
};
