import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import IconSvg from '../../../../../common/javascripts/components/IconSvg';
import DigitPasswordInput from '../../DigitPasswordInput';
import AttentionMessage from '../../AttentionMessage';
import { visitOrRedirectTo } from '../../../../../common/javascripts/helpers';
import { headersWithToken } from '../../../../../common/javascripts/fetchOptions';

import styles from './ProgramLink.module.scss';

export default function ProgramLinkSlideUpContent({
  programPath,
  requestProgramAccessPath,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [programAccessCode, setProgramAccessCode] = useState('');
  const [error, setError] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const data = {
      program_access_code: programAccessCode,
    };

    try {
      const response = await fetch(requestProgramAccessPath, {
        method: 'PATCH',
        headers: headersWithToken(),
        body: JSON.stringify(data),
      });
      const result = await response.json();
      setIsLoading(false);
      if (result.status === 'ok') {
        visitOrRedirectTo(programPath);
      } else {
        setError(result.error);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const isButtonDisabled = isLoading || programAccessCode.length < 4;

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <DigitPasswordInput
        digits={4}
        onChange={(code) => setProgramAccessCode(code)}
        className={styles.Link__slideUpCodeInput}
      />

      {
        error && (
          <AttentionMessage message={error} className={styles.Link__error} />
        )
      }

      <button
        className="button button_large button_fullwidth"
        type="submit"
        onClick={submit}
        disabled={isButtonDisabled}
      >
        Готово
      </button>
    </form>
  );
}

ProgramLinkSlideUpContent.propTypes = {
  programPath: PropTypes.string.isRequired,
  requestProgramAccessPath: PropTypes.string.isRequired,
};
