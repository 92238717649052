import React, { useState } from 'react';
import { observer } from 'mobx-react';

import Card from '../Card';
import styles from './QRCodeAnswer.module.scss';
import QRCodeScanner from '../QRCodeScanner';
import TopPageNotice from '../TopPageNotice';
import moderatedAnswersStore from '../../stores/moderatedAnswers';

function QRCodeAnswer() {
  const [notice, setNotice] = useState(null);
  const { isAnswerUpdating } = moderatedAnswersStore;

  const handleScanAnswer = async (data) => {
    const [taskStepId, playerId] = data.split('#');
    const answer = await moderatedAnswersStore.takeAnswer(playerId, taskStepId);
    if (answer) {
      setNotice('Ответ принят');
    } else {
      setNotice('Ответ не принят');
    }
  };

  return (
    <Card noShadow className={styles.QRCodeAnswer}>
      <TopPageNotice notice={notice} onClose={() => setNotice(null)} />
      <QRCodeScanner onScan={handleScanAnswer}>
        {({ openScanner, isCameraAvailable }) => (
          <button
            type="button"
            className="button button_fullwidth"
            disabled={isAnswerUpdating || !isCameraAvailable}
            onClick={openScanner}
          >
            Принять по QR-коду
          </button>
        )}
      </QRCodeScanner>
    </Card>
  );
}

export default observer(QRCodeAnswer);
