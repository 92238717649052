import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../Avatar';
import Cost from '../Cost';

import styles from './PlayerRatingCard.module.scss';

const playerPath = (id) => `/players/${id}`;

export default function PlayerRatingCard({
  player, highlighted, incard, noPublicLink,
}) {
  const playerPoints = player.player_points || player.balance_player_points;
  const teamPoints = player.team_points || player.balance_team_points;

  const cardContent = (
    <>
      <span className={styles.PlayerRatingCard__player}>
        <span className={styles.PlayerRatingCard__avatar}>
          <Avatar type="player" avatar={player.avatar_url} />
        </span>
        <span className={styles.PlayerRatingCard__title}>{player.public_full_name}</span>
      </span>
      <span className={styles.PlayerRatingCard__points}>
        <Cost icon="player-points" amount={playerPoints} showZero />
        <Cost icon="team-points" amount={teamPoints} showZero />
      </span>

    </>
  );

  const className = [
    styles.PlayerRatingCard,
    styles[`PlayerRatingCard_highlighted_${highlighted}`],
    styles[`PlayerRatingCard_incard_${incard}`],
  ].join(' ');

  if (noPublicLink) {
    return <div className={className}>{cardContent}</div>;
  }
  return <a href={playerPath(player.id)} className={className}>{cardContent}</a>;
}

PlayerRatingCard.propTypes = {
  player: PropTypes.shape({
    id: PropTypes.number,
    player_points: PropTypes.number,
    team_points: PropTypes.number,
    balance_player_points: PropTypes.number,
    balance_team_points: PropTypes.number,
    public_full_name: PropTypes.string,
    avatar_url: PropTypes.string,
  }).isRequired,
  noPublicLink: PropTypes.bool,
  highlighted: PropTypes.bool,
  incard: PropTypes.bool,
};

PlayerRatingCard.defaultProps = {
  noPublicLink: false,
  highlighted: false,
  incard: false,
};
