import React from 'react';
import PropTypes from 'prop-types';
import CardTagslist from '../../../../components/CardTagsList';

import styles from './Tags.module.scss';

export default function TaskTags({ tags }) {
  if (!tags) return null;

  return (
    <div className={styles.Tags}>
      <CardTagslist tags={tags} />
    </div>
  );
}

TaskTags.propTypes = {
  tags: PropTypes.instanceOf(Array),
};

TaskTags.defaultProps = {
  tags: [],
};
