import { headersWithToken } from '../../../common/javascripts/fetchOptions';

class TaskAnswersApi {
  createAnswer = async (taskStepId, answer) => this.postAnswer(JSON.stringify({
    task_step_id: taskStepId,
    task_answer: { answer },
  }), headersWithToken());

  createAttachAnswer = async (taskStepId, attachmentCacheNames) => {
    const data = new FormData();
    data.append('task_step_id', taskStepId);
    attachmentCacheNames.forEach(
      (cacheName, index) => data.append(`task_answer[attachments_attributes][${index}][file_cache]`, cacheName),
    );

    return this.postAnswer(data, headersWithToken().delete('Content-type'));
  }

  postAnswer = async (body, headers) => {
    const response = await fetch('/task_answers.json', {
      method: 'POST',
      headers,
      body,
    });

    if (response.ok) {
      return response.json();
    }

    return null;
  }
}

export default TaskAnswersApi;
