import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import AvatarTeamImage from '../../../images/avatar_team.png';
import AvatarPlayerImage from '../../../images/avatar_player.png';
// import AvatarProductImage from 'client/images/avatar_product.png';

import styles from './Avatar.module.scss';

export const getAvatarImage = (type, avatar) => {
  if (type.toLowerCase() === 'team') {
    return avatar || AvatarTeamImage;
  }
  if (type.toLowerCase() === 'player') {
    return avatar || AvatarPlayerImage;
  }
  // if (type.toLowerCase() === 'product') {
  //  return avatar;
  // }
  if (avatar) {
    return avatar;
  }
  return null;
};

export default function Avatar({ type, avatar, className }) {
  const image = getAvatarImage(type, avatar);

  if (!image) return null;

  return (
    <span
      className={cn(className, styles.Avatar)}
      style={{ backgroundImage: `url(${image})` }}
    />
  );
}

Avatar.propTypes = {
  type: PropTypes.string,
  avatar: PropTypes.string,
  className: PropTypes.string,
};

Avatar.defaultProps = {
  type: 'player',
  avatar: null,
  className: null,
};
