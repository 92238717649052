import {
  makeObservable,
  observable,
  action,
  runInAction,
} from 'mobx';

import NewsArticlesApi from './newsArticlesApi';
import playerViewStore from './playerViews';

class NewsArticleStore {
  newsArticles = []

  currentPage = 1

  isLoading = false

  hasMoreNewsArticles = false

  isMarkingAll = false

  constructor(newsArticlesApi, viewStore) {
    makeObservable(this, {
      newsArticles: observable,
      currentPage: observable,
      isLoading: observable,
      isMarkingAll: observable,
      hasMoreNewsArticles: observable,
      fetchNewsArticles: action,
      fetchMoreNewsArticles: action,
      markAsViewed: action,
      markAllAsViewed: action,
    });
    this.newsArticlesApi = newsArticlesApi;
    this.playerViewStore = viewStore;
  }

  fetchNewsArticles = async () => {
    this.notifications = [];
    this.isLoading = true;

    try {
      const data = await this.newsArticlesApi.fetchNewsArticles(1);

      runInAction(() => {
        this.isLoading = false;
        this.newsArticles = data.news_articles;
        this.currentPage = 1;
        this.hasMoreNewsArticles = !!(data.meta || {}).next_page;
      });
    } catch (error) {
      console.error(error);
    }
  }

  fetchMoreNewsArticles = async () => {
    this.currentPage += 1;
    this.isLoading = true;

    try {
      const data = await this.newsArticlesApi.fetchNewsArticles(this.currentPage);

      runInAction(() => {
        this.isLoading = false;
        this.newsArticles = [...this.newsArticles, ...data.news_articles];
        this.hasMoreNewsArticles = !!(data.meta || {}).next_page;
      });
    } catch (error) {
      console.error(error);
    }
  }

  markAsViewed = (newsArticleId) => {
    this.playerViewStore.markNewsArticleAsViewed(newsArticleId);

    const newsArticleIndex = this.newsArticles.findIndex((n) => n.id === newsArticleId);

    if (newsArticleIndex >= 0) {
      this.newsArticles = [
        ...this.newsArticles.slice(0, newsArticleIndex),
        { ...this.newsArticles[newsArticleIndex], viewed: true },
        ...this.newsArticles.slice(newsArticleIndex + 1, this.newsArticles.length),
      ];
    }
  }

  markAllAsViewed = async () => {
    this.isMarkingAll = true;
    await this.playerViewStore.markAllNewsArticlesAsViewed();

    runInAction(() => {
      this.newsArticles = this.newsArticles.map((article) => ({ ...article, viewed: true }));
      this.isMarkingAll = false;
    });
  }
}

export default new NewsArticleStore(new NewsArticlesApi(), playerViewStore);
