import React from 'react';
import PropTypes from 'prop-types';

import NotificationCard from '../components/Card';
import NotificationCardHeader from '../components/Header';
import NotificationTitle from '../components/Title';

import styles from './Event.module.scss';

export default function NotificationEventCard({ notification }) {
  if (!notification) return null;

  return (
    <NotificationCard notification={notification}>
      <NotificationCardHeader
        id={notification.event_id}
        statusText="Программа"
      />
      <div className={styles.EventBase}>
        <div className={styles.EventBase__content}>
          <NotificationTitle title={notification.data.title} />
          <a
            href={notification.data.event_link}
          >
            {notification.data.event_name}
          </a>
          {
            notification.data.body
            && (
              <div className={styles.EventBase__text}>{notification.data.body}</div>
            )
          }
        </div>
      </div>
    </NotificationCard>
  );
}

NotificationEventCard.propTypes = {
  notification: PropTypes.object.isRequired,
};
