import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PageCardsList from '../../../../components/PageCardsList';
import PartnerListCard from '../../../../components/Partner/ListCard';
import BottomSlideUp from '../../../../components/BottomSlideUp';
import PartnerShowScreen from '../../../Partner/Show/Show';

import styles from './PartnersList.module.scss';
import clickCounter from '../../../../helpers/counter';

export default function ProductPartnersList({ partners }) {
  const [showSlideUp, setShowSlideUp] = useState(false);
  const [elem, setElem] = useState({});

  const handleClickCard = (item) => {
    setElem(item);
    setShowSlideUp(!showSlideUp);
    clickCounter(`/partners/${item.id}`, item);
  };

  const partnerItems = partners.map((partner) => (
    <li key={partner.id}>
      <a className={styles.ProductPartnersList__link} onClick={handleClickCard.bind(null, partner)}>
        <PartnerListCard partner={partner} />
      </a>
    </li>
  ));

  return (
    <div className={styles.ProductPartnersList}>
      <PageCardsList>{partnerItems}</PageCardsList>
      <BottomSlideUp show={showSlideUp} onClose={() => setShowSlideUp(false)}>
        <PartnerShowScreen partner={elem} />
      </BottomSlideUp>
    </div>
  );
}

ProductPartnersList.propTypes = {
  partners: PropTypes.instanceOf(Array),
};

ProductPartnersList.defaultProps = {
  partners: [],
};
