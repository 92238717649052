import React from 'react';
import PropTypes from 'prop-types';

import Card from '../../../../../components/Card';
import StepHeader from '../StepHeader';

import styles from './StepBlocked.module.scss';

export default function TaskStepBlocked({ stepNumber }) {
  return (
    <Card className={styles.StepBlocked}>
      <StepHeader
        stepNumber={stepNumber}
        isBlocked
      />

      <div className={styles.StepBlocked__unblockTitle}>
        Для разблокировки
      </div>
      <div className={styles.StepBlocked__unblockNotice}>
        {'Выполни '}
        <strong>{`ШАГ ${stepNumber - 1}`}</strong>
        {' этого задания'}
      </div>
    </Card>
  );
}

TaskStepBlocked.propTypes = {
  stepNumber: PropTypes.number.isRequired,
};
