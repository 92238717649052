import React, { useState } from 'react';
import PropTypes from 'prop-types';

import QRCodeScanner from '../../../../../components/QRCodeScanner';
import TaskAnswersApi from '../../../../../stores/taskAnswersApi';
import TopPageNotice from '../../../../../components/TopPageNotice';
import CardHeader from '../../../../../components/CardHeader';
import BuyHint from '../BuyHint/BuyHint';

const answersApi = new TaskAnswersApi();

function QRCodeScan({ taskStep, onTaskAnswer }) {
  const [isLoading, setIsLoading] = useState(false);
  const [notice, setNotice] = useState();
  const isPlayerQrCode = ['qrcode_scan30', 'qrcode_scan60', 'qrcode_scan90', 'qrcode_scan3', 'qrcode_scan6', 'qrcode_scan9'].includes(taskStep.answer_type);

  const handleScan = async (data) => {
    setNotice('');
    setIsLoading(true);

    try {
      const result = await answersApi.createAnswer(taskStep.id, data);
      setIsLoading(false);
      if (result) {
        setNotice(result.notice);
        setTimeout(() => onTaskAnswer(), 450);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleScanError = () => {
    setNotice('Камера недоступна');
  };

  return (
    <>
      <TopPageNotice notice={notice} onClose={() => setNotice(null)} />

      <CardHeader bottomSpace="s">
        {isPlayerQrCode && (
          <h4>Знакомьтесь с другими игроками и сканируте их QR-код в профиле.</h4>
        )}
        {!isPlayerQrCode && (
          <h4>Если найден верный код, то нажмите кнопку ниже и разрешите доступ к камере.</h4>
        )}
      </CardHeader>

      <QRCodeScanner onScan={handleScan} onError={handleScanError}>
        {
          ({ openScanner, isCameraAvailable }) => {
            const isDisabled = isLoading || taskStep.attempts_left <= 0 || !isCameraAvailable;

            return (
              <>
                {isPlayerQrCode && (
                  <BuyHint text={taskStep.human_player_qrcode_left} />
                )}
                {!isPlayerQrCode && (
                  <BuyHint text={taskStep.human_attempts_left} />
                )}
                <button
                  type="button"
                  disabled={isDisabled}
                  className="button button_fullwidth button_large"
                  onClick={openScanner}
                >
                  Отсканировать QR-код
                  {isPlayerQrCode && ' игрока'}
                </button>
              </>
            );
          }
        }
      </QRCodeScanner>
    </>
  );
}

QRCodeScan.propTypes = {
  taskStep: PropTypes.shape({
    id: PropTypes.number,
    attempts_left: PropTypes.number,
    answer_type: PropTypes.string,
    human_player_qrcode_left: PropTypes.string,
    human_attempts_left: PropTypes.string,
  }).isRequired,
  onTaskAnswer: PropTypes.func.isRequired,
};

export default QRCodeScan;
