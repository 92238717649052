function isTaskModerator(player) {
  if (!player) return false;

  return (player.roles || []).includes('task_moderator');
}

function isOrdersModerator(player) {
  if (!player) return false;

  return (player.roles || []).includes('orders_moderator');
}

function isEventsModerator(player) {
  if (!player) return false;

  return (player.roles || []).includes('events_moderator');
}

export { isTaskModerator, isOrdersModerator, isEventsModerator };
