import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import InputMask from 'react-input-mask';
import { isPhoneValid, phoneMask } from '../../../../../helpers/phone';

import styles from './AnswerInvite.module.scss';

function AnswerInvitePlayer({ onInvatedPlayerClick }) {
  const [phone, setPhone] = useState('');
  const [canSearch, setCanSearch] = useState(false);

  const handleBeforeMaskedStateChange = ({ nextState }) => {
    let { value } = nextState;
    const { enteredString } = nextState;
    const digits = enteredString ? enteredString.replace(/[^0-9]/g, '') : '';

    if (digits.length >= 10) {
      const maskPhone = digits.slice(-10);

      value = `+7 (${maskPhone.slice(0, 3)}) ${maskPhone.slice(3, 6)}-${maskPhone.slice(6, 8)}-${maskPhone.slice(8, 10)}`;
    }

    return {
      ...nextState,
      value,
    };
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleInviteClick = async () => {
    onInvatedPlayerClick(phone);
  };

  useEffect(() => {
    setCanSearch(isPhoneValid(phone));
  }, [phone]);

  return (
    <>
      <div className={styles.AnswerInvite__phoneInputWrap}>
        <InputMask
          mask={phoneMask}
          alwaysShowMask
          onChange={handlePhoneChange}
          value={phone}
          beforeMaskedStateChange={handleBeforeMaskedStateChange}
        >
          <input
            className="input input_large input_attention"
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
          />
        </InputMask>
      </div>
      <button
        className="button button_large button_fullwidth"
        disabled={!canSearch}
        onClick={handleInviteClick}
        type="submit"
      >
        Отправить приглашение
      </button>
    </>
  );
}

AnswerInvitePlayer.propTypes = {
  onInvatedPlayerClick: PropTypes.func.isRequired,
};

export default AnswerInvitePlayer;
