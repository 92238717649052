import React from 'react';
import PropTypes from 'prop-types';
import TaskCardAvailable from '../CardAvailable';
import TaskCardBlocked from '../CardBlocked';
import TaskCardCompleted from '../CardCompleted';
import TaskCardPending from '../CardPending';
import TaskCardFailed from '../CardFailed';

import TinkoffPromoCard from '../../../../../components/TinkoffPromoCard';
import PageCardsList from '../../../../../components/PageCardsList';
import ContentNotice from '../../../../../components/ContentNotice';
import { visitOrRedirectTo } from '../../../../../../../common/javascripts/helpers';
import { saveScrollElementToHistory, scrollOnMount, scrollToElement } from '../../../../../helpers/scroll';

import styles from './CardsList.module.scss';

const CARD_COUNT_FOR_PROMO = 8;

const TASK_CARDS = {
  available: TaskCardAvailable,
  blocked: TaskCardBlocked,
  completed: TaskCardCompleted,
  pending: TaskCardPending,
  failed: TaskCardFailed,
  ended: TaskCardFailed,
};

const taskPath = (id) => `/tasks/${id}`;

const taskElementId = (id) => `task-${id}`;

function TasksCardsList({ tasks, player, setting }) {
  const handleVisitTask = (taskId) => () => {
    saveScrollElementToHistory(taskElementId(taskId));
    visitOrRedirectTo(taskPath(taskId));
  };

  const handleScrollToTask = (taskId) => {
    scrollToElement({
      getTarget: () => document.getElementById(taskElementId(taskId)),
    });
  };

  const handleBoosterUse = (taskId) => {
    visitOrRedirectTo(taskPath(taskId));
  };

  const renderTaskCard = (task) => {
    const visitHandler = handleVisitTask(task.id);
    const CardComponent = TASK_CARDS[task.status];

    if (CardComponent) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <CardComponent
          task={task}
          player={player}
          onVisit={visitHandler}
          onScrollToTask={handleScrollToTask}
          onBoosterUse={handleBoosterUse}
          setting={setting}
        />
      );
    }

    return null;
  };

  return (
    <PageCardsList>
      {
        tasks.map((task, index) => (
          <React.Fragment key={task.id}>
            <li id={taskElementId(task.id)} className={styles.TaskItem}>
              {renderTaskCard(task)}
            </li>
            {
              ((index + 1) % CARD_COUNT_FOR_PROMO === 0 || index + 1 === tasks.length)
              && (
                <li><TinkoffPromoCard setting={setting} /></li>
              )
            }
          </React.Fragment>
        ))
      }
      {tasks.length === 0 && (
        <ContentNotice
          outline
          notClosable
        >
          <div
            // className={styles.WinnerAward__prize}
            dangerouslySetInnerHTML={{ __html: setting?.task_by_status_notice || 'ЗАПОЛНИ В ОСНОВНЫХ НАСТРОЙКАХ' }}
          />
        </ContentNotice>
      )}
    </PageCardsList>
  );
}

TasksCardsList.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  player: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  setting: PropTypes.shape({
    img_url_card: PropTypes.string.isRequired,
    title_card: PropTypes.string.isRequired,
    text_card: PropTypes.string.isRequired,
    description_card: PropTypes.string.isRequired,
    task_by_status_notice: PropTypes.string.isRequired,
  }).isRequired,
};

export default scrollOnMount()(TasksCardsList);
