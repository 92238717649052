import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './CardCoverImage.module.scss';

export default function CardCoverImage({ imageUrl, inList, inCard }) {
  //if (!imageUrl) return null;

  const className = cn(
    styles.CoverImage,
    { [styles.CoverImage_inList]: inList },
    { [styles.CoverImage_inCard]: inCard },
  );

  return (
    <div
      className={className}
      style={{ backgroundImage: `url(${imageUrl})` }}
    />
  );
}

CardCoverImage.propTypes = {
  imageUrl: PropTypes.string,
  inList: PropTypes.bool,
  inCard: PropTypes.bool,
};

CardCoverImage.defaultProps = {
  imageUrl: null,
  inList: false,
  inCard: false,
};
