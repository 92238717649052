import React from 'react';
import PropTypes from 'prop-types';
import IconSvg from '../../../../common/javascripts/components/IconSvg';
import CardHeader from '../CardHeader';

import styles from './CallWriteSnippet.module.scss';

export default function CallWriteSnippet({ contact }) {
  const hasEmail = contact.email && contact.email.length !== 0;
  const hasWhatsapp = contact.whatsapp && contact.whatsapp.length !== 0;
  const hasTelegram = contact.telegram && contact.telegram.length !== 0;

  return (
    <div className={styles.Snippet}>
      <CardHeader className={styles.Snippet__contentHeader}>
        <h2>{contact.header_card}</h2>
      </CardHeader>
      <div className={styles.Snippet__phoneHeader}>
        Звони нам
        {contact.phone_work_start ? ` c ${contact.phone_work_start}` : ''}
        {contact.phone_work_end ? ` до ${contact.phone_work_end}` : ''}
      </div>
      <a className={styles.Snippet__phone} href={`tel:${contact.phone}`}>
        {contact.phone}
      </a>

      {
        (hasWhatsapp || hasEmail || hasTelegram) && (
          <>
            <div className={styles.Snippet__writeUsDivider}>или пиши</div>

            <div className={styles.Snippet__messageWrap}>
              {
                hasEmail && (
                  <a
                    className={styles.Snippet__messageChannel}
                    href={`mailto:${contact.email}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconSvg icon="contact-mail" className={styles.Snippet__messageIcon} />
                    <span className={styles.Snippet__messageLabel}>на почту</span>
                  </a>
                )
              }

              {
                hasWhatsapp && (
                  <a
                    className={styles.Snippet__messageChannel}
                    href={`https://${contact.whatsapp}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconSvg icon="contact-whatsapp" className={styles.Snippet__messageIcon} />
                    <span className={styles.Snippet__messageLabel}>в WhatsApp</span>
                  </a>
                )
              }

              {
                hasTelegram && (
                  <a
                    className={styles.Snippet__messageChannel}
                    href={`https://${contact.telegram}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconSvg icon="contact-telegram" className={styles.Snippet__messageIcon} />
                    <span className={styles.Snippet__messageLabel}>в Telegram</span>
                  </a>
                )
              }
            </div>
          </>
        )
      }
    </div>
  );
}

CallWriteSnippet.propTypes = {
  contact: PropTypes.shape({
    header_card: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    whatsapp: PropTypes.string,
    telegram: PropTypes.string,
    phone_work_start: PropTypes.string,
    phone_work_end: PropTypes.string,
  }).isRequired,
};
