import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Card from '../../components/Card';
import PageContentOtherBg from '../../components/PageContentOtherBg';
import FromEditor from '../../components/FromEditor';

import styles from './NewsArticle.module.scss';

function NewsArticleShowScreen({ newsArticle }) {
  const article = JSON.parse(newsArticle);
  return (
    <PageContentOtherBg>
      <Card noShadow className={cn(styles.Article, styles.Article_full)}>
        <div className={styles.Article__date}>{article.publication_date}</div>
        <div className={styles.Article__title}>{article.title}</div>
        <FromEditor>{article.text}</FromEditor>
      </Card>
    </PageContentOtherBg>
  );
}

NewsArticleShowScreen.propTypes = {
  newsArticle: PropTypes.string.isRequired,
};

export default NewsArticleShowScreen;
