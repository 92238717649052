import React from 'react';
import PropTypes from 'prop-types';

import Card from '../../../../../components/Card';
import TaskTitle from '../../../components/Title';
import BoostersList from '../../../components/BoostersList';
import { isTaskModerator } from '../../../../../helpers/players';


import styles from './CardFailed.module.scss';

function TaskCardFailed({ task, player, onBoosterUse, onVisit }) {
  const secondChanceBoosters = task.boosters.filter((booster) => booster.type === 'second_chance');

  const handleTaskClick = () => {
    if (isTaskModerator(player)) {
      onVisit();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      onClick={handleTaskClick}
      role="button"
      tabIndex={0}
    >
      <Card className={styles.CardFailed}>
        <TaskTitle
          noBottomSpace
          task={task}
          statusIcon={task.status}
        />

        {task.notice && (
          <span className={styles.CardFailed__notice}>{task.notice}</span>
        )}

        <BoostersList
          taskId={task.id}
          boosters={secondChanceBoosters}
          onBoosterUse={() => onBoosterUse(task.id)}
        />
      </Card>
    </div>
  );
}

TaskCardFailed.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    notice: PropTypes.string,
    boosters: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
    })),
  }).isRequired,
  player: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onBoosterUse: PropTypes.func.isRequired,
  onVisit: PropTypes.func.isRequired,
};

export default TaskCardFailed;
