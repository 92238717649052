import React from 'react';
import PropTypes from 'prop-types';

TaskAgeRestriction.propTypes = {
  restriction: PropTypes.number
};

export default function TaskAgeRestriction({ restriction }) {
  return (
    <>
      { restriction &&
        <div className='ageRestriction'>{restriction}&nbsp;+</div>
      }
    </>
  );
}
