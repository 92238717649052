import React from 'react';
import PropTypes from 'prop-types';

import styles from './SocialButton.module.scss';

function SocialButton({
  children,
  isDisabled,
  hasProfile,
  onClick,
  authUrl,
}) {
  const className = `${styles.SocialButton} button button_fullwidth button_large button_secondary`;
  return (
    <>
      {hasProfile && (
        <button
          type="button"
          className={className}
          onClick={onClick}
          disabled={isDisabled}
        >
          {children}
        </button>
      )}
      {!hasProfile && (
        <a
          href={authUrl}
          className={className}
        >
          {children}
        </a>
      )}
    </>
  );
}

SocialButton.propTypes = {
  children: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  hasProfile: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  authUrl: PropTypes.string.isRequired,
};

export default SocialButton;
