import React from 'react';
import PropTypes from 'prop-types';

import BecomePartnerButton from '../../../components/BecomePartnerButton';
import PageHeader from '../../../components/PageHeader';
import PageContentOtherBg from '../../../components/PageContentOtherBg';

import PartnerCardsList from './components/CardsList';

import styles from './List.module.scss';

export default function PartnersListScreen({ partners, header }) {
  return (
    <>
      <BecomePartnerButton top bottom={false} />
      <PageContentOtherBg>
        <PageHeader><h1>{header}</h1></PageHeader>
        <PartnerCardsList items={partners} />
        <BecomePartnerButton />
      </PageContentOtherBg>
    </>
  );
}

PartnersListScreen.propTypes = {
  header: PropTypes.string,
};

PartnersListScreen.defaultProps = {
  header: 'Партнеры',
};
