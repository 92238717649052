import React from 'react';
import PropTypes from 'prop-types';
// import cn from 'classnames';

import TagsFilter from '../../../../../components/TagsFilter';
import TagsFilterList from '../../../../../components/TagsFilterList';
import TagsFilterButton from '../../../../../components/TagsFilterButton';

export default function EventTagsFilter({ filter, tags, onFilterChange }) {
  if ((tags || []).length === 0) return null;

  const allEventsCount = tags.reduce((sum, current) => sum + current.count, 0);

  const handleFilterClick = (newFilter) => {
    if (newFilter !== filter) {
      onFilterChange(newFilter);
    }
  };

  return (
    <TagsFilter>
      <TagsFilterList>
        <li>
          <TagsFilterButton
            label={`Все (${allEventsCount})`}
            onClick={() => handleFilterClick(0)}
            active={filter === null || filter === 0}
          />
        </li>
        {tags.map((tag) => (
          <li key={tag.id}>
            <TagsFilterButton
              label={`${tag.title} (${tag.count})`}
              onClick={() => handleFilterClick(tag.id)}
              active={filter === tag.id}
            />
          </li>
        ))}
      </TagsFilterList>
    </TagsFilter>
  );
}

EventTagsFilter.propTypes = {
  filter: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    count: PropTypes.number,
  })),
  onFilterChange: PropTypes.func.isRequired,
};

EventTagsFilter.defaultProps = {
  filter: 0,
  tags: [],
};
