import * as React from 'react';

import TeamRatingCard from '../../components/TeamRatingCard';
import PageCardsList from '../../components/PageCardsList';
import PageHeader from '../../components/PageHeader';

import * as styles from './GameHistory.module.scss';

const startYear = 2018;

const teamWinners = [
  {
    id: 0,
    points: null,
    title: 'Команжа',
    avatar_url: null,
    rating_position: 1,
    winYear: 2022,
  },
  {
    id: 0,
    points: null,
    title: 'Ладно, давай',
    avatar_url: null,
    rating_position: 1,
    winYear: 2021,
  },
  {
    id: 0,
    points: null,
    title: 'Darts Farts',
    avatar_url: null,
    rating_position: 1,
    winYear: 2020,
  },
  {
    id: 0,
    points: null,
    title: 'ЛА',
    avatar_url: null,
    rating_position: 1,
    winYear: 2019,
  },
  {
    id: 0,
    points: null,
    title: 'ЛА',
    avatar_url: null,
    rating_position: 1,
    winYear: 2018,
  },
];

export default function GameHistory() {
  return (
    <div className={styles.GameHistory}>
      <PageHeader>
        <h1>Победители</h1>
      </PageHeader>

      <PageCardsList>
        {
          teamWinners.map((team) => (
            <li key={`winnerCard-${team.winYear}`}>
              <TeamRatingCard
                team={team}
                noPublicLink
                noPoints
                forceRating={`${team.winYear} год`}
              />
            </li>
          ))
        }
      </PageCardsList>

    </div>
  );
}
