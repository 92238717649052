import React from 'react';
import PropTypes from 'prop-types';
import ProgressRing from '../../../../components/ProgressRing';

import styles from './StepsProgressRing.module.scss';

export default function TaskStepsProgressRing({ totalSteps, doneSteps }) {
  const isAllStepsDone = totalSteps === doneSteps;
  const stepPercentWeight = (1 / totalSteps) * 100;
  const doneStepsPercent = doneSteps * stepPercentWeight;
  const currentStepPercent = isAllStepsDone ? 0 : doneStepsPercent + stepPercentWeight;

  return (
    totalSteps > 1 && (
      <div className={styles.ProgressRing}>
        <ProgressRing
          rings={[
            { r: 15, strokeWidth: 1, stroke: '#f5f5f5', percent: 100 },
            { r: 15, strokeWidth: 4, stroke: '#EAEAEA', percent: currentStepPercent },
            { r: 15, strokeWidth: 4, stroke: '#73D694', percent: doneStepsPercent },
          ]}
        />
        <div className={styles.ProgressRing__text}>
          {`${doneSteps}/${totalSteps}`}
        </div>
      </div>
    )
  );
}

TaskStepsProgressRing.propTypes = {
  totalSteps: PropTypes.number,
  doneSteps: PropTypes.number,
};
