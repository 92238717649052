import React from 'react';
import PropTypes from 'prop-types';

import CardHeader from '../CardHeader';

import styles from './CardAudios.module.scss';

export default function CardAudios({ audios, header }) {
  if ((audios || []).length === 0) {
    return null;
  }

  const audioItems = audios.map((audio) => (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio controls key={`audio${audio.id}`} className={styles.Audios__audio}>
      <source src={audio.url} />
      <p>
        Ваш браузер не поддерживает HTML5 аудио. Вот взамен
        <a href={audio.url}>ссылка на аудио</a>
      </p>
    </audio>
  ));

  return (
    <div className={styles.Audios}>
      {
        header && (
          <CardHeader>
            <h4>{header}</h4>
          </CardHeader>
        )
      }

      <div className={styles.Audios__items}>
        {audioItems}
      </div>
    </div>
  );
}

CardAudios.propTypes = {
  audios: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
  })),
  header: PropTypes.string,
};

CardAudios.defaultProps = {
  audios: [],
  header: null,
};
