/* eslint-disable import/no-unresolved */
import React from 'react';
import IconSvg from 'common/javascripts/components/IconSvg';

import styles from './CloseMenu.module.scss';

export default function TopbarCloseMenu() {
  return (
    <>
      <div className={styles.CloseMenu} />
      <IconSvg icon="close-circle" className={styles.CloseMenu__icon} />
    </>
  );
}
