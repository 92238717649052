import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BottomSlideUp from '../../../../../components/BottomSlideUp';
import CardHeader from '../../../../../components/CardHeader';
import TopPageNotice from '../../../../../components/TopPageNotice';
import AnswerInvitePlayer from './InvatePlayer';

const HEADERS = (token) => (
  {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'X-CSRF-Token': token,
  }
);

function AnswerInvite({ taskStep, token, onTaskAnswer }) {
  const [notice, setNotice] = useState();
  const [showSlideUp, setShowSlideUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invitations, setInvitations] = useState(taskStep.attemts_invite);

  const invitItems = (invitations || []).map((item, index) => (
    <div
      key={`invite-${index}`}
    >
      {item}
    </div>
  ));

  const handleAddPlayerClick = () => {
    setShowSlideUp(true);
  };

  const handleSlideUpClose = () => {
    setShowSlideUp(false);
  };

  const handleInvatedPlayerClick = async (phone) => {
    setIsLoading(true);
    setShowSlideUp(false);
    setNotice('');
    setIsLoading(true);

    const data = {
      task_step_id: taskStep.id,
      task_answer: { answer: phone },
    };

    try {
      const response = await fetch('/task_answers.json', {
        method: 'POST',
        headers: HEADERS(token),
        body: JSON.stringify(data),
      });
      const result = await response.json();

      setIsLoading(false);

      if (response.ok) {
        setNotice(result.notice);
        setInvitations(result.attempts.attempts_invite);
        setTimeout(() => onTaskAnswer(), 450);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const isDisabled = isLoading;

  return (
    <>
      <TopPageNotice notice={notice} onClose={() => setNotice(null)} />

      <CardHeader bottomSpace="s">
        <h4>
          Пригласи в квест НОВОГО ИГРОКА и после его регистрации задание засчитается автоматически
        </h4>
      </CardHeader>

      <CardHeader bottomSpace="s">
        {invitItems}
      </CardHeader>

      <button
        className="button button_large button_fullwidth"
        type="button"
        onClick={handleAddPlayerClick}
        disabled={isDisabled}
      >
        {(invitations || []).length === 0 ? 'Пригласить' : 'Пригласить ещё друга'}
      </button>

      <BottomSlideUp show={showSlideUp} onClose={handleSlideUpClose}>
        <AnswerInvitePlayer onInvatedPlayerClick={handleInvatedPlayerClick} />
      </BottomSlideUp>
    </>
  );
}

AnswerInvite.propTypes = {
  taskStep: PropTypes.shape({
    id: PropTypes.number,
    attempts_left: PropTypes.number,
    human_attempts_left: PropTypes.string,
    can_instagram: PropTypes.bool,
    can_facebook: PropTypes.bool,
    can_vkontakte: PropTypes.bool,
    attemts_invite: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  token: PropTypes.string.isRequired,
  onTaskAnswer: PropTypes.func.isRequired,
};

export default AnswerInvite;
