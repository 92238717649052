import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { headersWithToken } from '../../../../../../../common/javascripts/fetchOptions';
import { visitOrRedirectTo } from '../../../../../../../common/javascripts/helpers';

import AttentionMessage from '../../../../../components/AttentionMessage';
import IconSvg from '../../../../../../../common/javascripts/components/IconSvg';
import QRCodeScanner from '../../../../../components/QRCodeScanner';
import TeamPlayTeamPreview from '../TeamPreview';

import styles from './SearchStep.module.scss';

function TeamPlaySearchStep({ setNotice }) {
  const [uniqNum, setUniqNum] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [team, setTeam] = useState(null);

  const handleChangeUniqNum = ({ target: { value } }) => {
    setUniqNum(value);
    if (team) {
      setTeam(null);
    }

    if (value.length >= 1) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleSearchTeam = (num) => {
    fetch(`/teams/search.json?uniq_num=${num}`)
      .then((res) => res.json())
      .then(
        (result) => {
          setNotice(result.notice);
          if (result.status === 'ok') {
            setTeam(result.team);
            setIsValid(true);
            setUniqNum(result.team.uniq_num);
          } else {
            setTeam(null);
          }
        },
      );
  };

  const handleJoinTeam = async () => {
    try {
      const response = await fetch(`/teams/${team.id}/player_teams.json`, {
        method: 'POST',
        headers: headersWithToken(),
      });
      const result = await response.json();

      if (result.status === 'ok') {
        visitOrRedirectTo(`/teams/${team.id}`);
      } else {
        setNotice(result.notice);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();

    if (team) {
      handleJoinTeam();
    } else {
      handleSearchTeam(uniqNum);
    }
  };

  const handleScan = (data) => {
    handleSearchTeam(data);
  };

  return (
    <>
      <form className={styles.Step}>
        <div className={styles.Step__inputWrap}>
          <input
            className="input input_large input_darkBorder"
            type="text"
            id="uniqNum"
            value={uniqNum}
            placeholder="Введите ID команды"
            onChange={handleChangeUniqNum}
          />
          <QRCodeScanner onScan={handleScan}>
            {
              ({ openScanner, isCameraAvailable }) => {
                if (!isCameraAvailable) return null;

                return (
                  <span className={styles.Step__inputAddon}>
                    <button
                      type="button"
                      className={styles.Step__inputAddonBtn}
                      onClick={openScanner}
                    >
                      или отсканируй код
                      <IconSvg icon="camera" className={styles.Step__inputAddonIcon} />
                    </button>
                  </span>
                );
              }
            }
          </QRCodeScanner>
        </div>

        {
          team
          && (
            <a href={`/teams/${team.id}`} className={styles.Step__teamLink} target="_blank" rel="noreferrer">
              <TeamPlayTeamPreview team={team} />
            </a>
          )
        }

        <button
          type="submit"
          className="button button_large button_fullwidth"
          onClick={handleSubmitClick}
          disabled={!isValid}
        >
          {team ? 'Присоединиться' : 'Далее'}
        </button>
      </form>
      <AttentionMessage
        isHint
        message="в течение 24 часов ты можешь выйти из команды"
      />
    </>
  );
}

TeamPlaySearchStep.propTypes = {
  setNotice: PropTypes.func.isRequired,
};

export default TeamPlaySearchStep;
