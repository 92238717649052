import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import PageCardsList from '../../../../../components/PageCardsList';
import ProductListCard from '../../../components/ListCard';
import ProductStore from '../../../../../stores/products';
import BottomSlideUp from '../../../../../components/BottomSlideUp';
import ProductShowScreen from '../../../Show/Show';
import ProductOrderShowScreen from '../../../OrderShow';

// import { visitOrRedirectTo } from '../../../../../../../common/javascripts/helpers';
// import { saveScrollElementToHistory, scrollOnMount } from '../../../../../helpers/scroll';

import styles from './CardsList.module.scss';

// const productPath = (id) => `/shop/products/${id}`;
const productElementId = (id) => `product-${id}`;

function ProductCardsList({ items, filterTagId, player }) {
  // const handleVisitProduct = (product) => {
  //   saveScrollElementToHistory(productElementId(product.id));
  //   visitOrRedirectTo(productPath(product.id));
  // };

  const {
    products,
    isLoading,
    hasMoreProducts,
    fetchProducts,
    fetchMoreProducts,
  } = ProductStore;

  const [showSlideUp, setShowSlideUp] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentPlayer, setCurrentPlayer] = useState(player);
  const [orderProductResult, setOrderProductResult] = useState(null);

  const getProduct = async (item) => {
    try {
      const response = await fetch(`/shop/products/${item.id}.json`);
      const data = await response.json();

      if (data.status === 'ok') {
        setCurrentProduct(data.product);
        setShowSlideUp(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleShowMore = () => fetchMoreProducts();

  const handleClickProduct = (item) => {
    getProduct(toJS(item));
  };

  const handleCloseProduct = () => {
    setCurrentProduct(null);
    setOrderProductResult(null);
    setShowSlideUp(false);
  };

  const handleShowOrder = (result) => {
    if (result.status !== 'ok') return;

    setOrderProductResult(result);
    setCurrentPlayer(result.player);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const cardItems = products.map((item) => {
    if (filterTagId === null || item.tags.find((obj) => obj.id === filterTagId)) {
      return (
        <li id={productElementId(item.id)} key={item.id}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            // eslint-disable-next-line no-script-url
            href="javascript:void(0)"
            className={styles.ProductCardsList__link}
            onClick={handleClickProduct.bind(null, toJS(item))}
          >
            <ProductListCard product={toJS(item)} />
          </a>
        </li>
      );
    }
    return null;
  });

  return (
    <>
      <PageCardsList>
        {cardItems}
        {
          isLoading
          && <button type="button" className="button button_large button_fullwidth" disabled> Загружаем...</button>
        }
        {
          hasMoreProducts && !isLoading
          && (
            <button type="button" className="button button_large button_fullwidth" onClick={handleShowMore}>
              Показать еще
            </button>
          )
        }
      </PageCardsList>
      <BottomSlideUp show={showSlideUp} onClose={handleCloseProduct}>
        {currentProduct && !orderProductResult && (
          <ProductShowScreen
            product={currentProduct}
            player={currentPlayer}
            onShowOrder={handleShowOrder}
          />
        )}
        {currentProduct && orderProductResult && (
          <ProductOrderShowScreen
            order={orderProductResult.order}
            contact={orderProductResult.contact}
          />
        )}
      </BottomSlideUp>
    </>
  );
}

ProductCardsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  filterTagId: PropTypes.number,
  player: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

ProductCardsList.defaultProps = {
  items: [],
  filterTagId: null,
};

export default observer(ProductCardsList);
