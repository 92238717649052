import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../Avatar';
import Cost from '../Cost';

import styles from './TeamRatingCard.module.scss';

const teamPath = (id) => `/teams/${id}`;

export default function TeamRatingCard({
  team, highlighted, incard, noPublicLink, forceRating, action, noPoints,
}) {
  const rating = forceRating || team.rating_position;
  const isTeamCreatedAction = action === 'team_created';

  const cardContent = (
    <>
      {rating && <span className={styles.TeamRatingCard__rating}>{rating}</span>}
      <span className={styles.TeamRatingCard__team}>
        <span className={styles.TeamRatingCard__avatar}>
          <Avatar type="team" avatar={team.avatar_url} />
        </span>
        <span className={styles.TeamRatingCard__title}>{team.title}</span>
      </span>
      {(!isTeamCreatedAction && !noPoints) && (
        <span className={styles.TeamRatingCard__points}>
          <Cost icon="team-points" amount={team.points} showZero />
        </span>
      )}
    </>
  );

  const className = [
    styles.TeamRatingCard,
    styles[`TeamRatingCard_highlighted_${highlighted}`],
    styles[`TeamRatingCard_incard_${incard}`],
  ].join(' ');

  if (noPublicLink) {
    return <div className={className}>{cardContent}</div>;
  }
  return <a href={teamPath(team.id)} className={className}>{cardContent}</a>;
}

TeamRatingCard.propTypes = {
  team: PropTypes.shape({
    id: PropTypes.number,
    points: PropTypes.number,
    title: PropTypes.string,
    avatar_url: PropTypes.string,
    rating_position: PropTypes.number,
  }).isRequired,
  noPublicLink: PropTypes.bool,
  highlighted: PropTypes.bool,
  forceRating: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  incard: PropTypes.bool,
  action: PropTypes.string,
  noPoints: PropTypes.bool,
};

TeamRatingCard.defaultProps = {
  noPublicLink: false,
  highlighted: false,
  forceRating: null,
  incard: false,
  action: '',
  noPoints: false,
};
