import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconSvg from '../../../../common/javascripts/components/IconSvg';

import styles from './ContentNotice.module.scss';

export default function ContentNotice({
  uid, notice, children, outline, bottomSpace, notClosable, className,
}) {
  const closedContentNotices = JSON.parse(localStorage.getItem('closedContentNotices') || '[]');

  const [isOpen, setIsOpen] = useState(() => {
    if (uid) {
      return !closedContentNotices.includes(uid);
    }
    return true;
  });

  const onClose = () => {
    if (uid) {
      if (!closedContentNotices.includes(uid)) {
        closedContentNotices.push(uid);
      }
      localStorage.setItem('closedContentNotices', JSON.stringify(closedContentNotices));
    }
    setIsOpen(false);
  };

  const classNames = cn(
    className,
    styles.ContentNotice,
    { [styles.ContentNotice_outline]: outline },
    { [styles.ContentNotice_bottomSpace]: bottomSpace },
  );

  if (!isOpen) return null;

  return (
    <div className={classNames}>
      <div className={styles.ContentNotice__notice}>
        {notice || children}
      </div>
      {
        !notClosable
        && (
          <button className={styles.ContentNotice__closeIconBtn} onClick={onClose} type="button">
            <IconSvg
              icon="close"
              className={styles.ContentNotice__closeIcon}
            />
          </button>
        )
      }
    </div>
  );
}

ContentNotice.propTypes = {
  uid: PropTypes.string,
  notice: PropTypes.string,
  children: PropTypes.node,
  outline: PropTypes.bool,
  bottomSpace: PropTypes.bool,
  notClosable: PropTypes.bool,
  className: PropTypes.string,
};

ContentNotice.defaultProps = {
  uid: '',
  notice: '',
  children: '',
  outline: false,
  bottomSpace: false,
  notClosable: false,
  className: null,
};
