import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// import PageTopNotice from '../../../../components/TopPageNotice';
import IconSvg from '../../../../../../common/javascripts/components/IconSvg';
import TaskIdPostPendStatus from '../IdPostPendStatus';

import styles from './IdFavStatus.module.scss';

export default function TaskIdFavStatus({ task, bottomSpace }) {
  const [isFavourite, setFavourite] = useState(task.favourite);
  // const [showFavNotice, setShowFavNotice] = useState(false);

  const submitFavourite = async (newFavourite) => {
    let action = 'add';

    if (!newFavourite) {
      action = 'remove';
    }

    const url = `/favourites/${action}?task_id=${task.id}`;

    try {
      const response = await fetch(url, {
        method: 'POST',
      });
      // const result = await response.json();

      if (response.ok) {
        setFavourite(newFavourite);
        // setShowFavNotice(true);
        // setTimeout(() => setShowFavNotice(false), 3000);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const handleToggleFavourites = () => {
    submitFavourite(!isFavourite);
  };

  return (
    <div className={cn(styles.IdFavStatus, { [styles.IdFavStatus_bottomSpace]: bottomSpace })}>
      {
        // showFavNotice
        // && (
        // <PageTopNotice
        //  notice={isFavourite ? 'Добавлено в избранное' : 'Удалено из избранного'}
        // />
        // )
      }

      <span className={styles.IdFavStatus__id}>
        <IconSvg icon="task-id" className={styles.IdFavStatus__idIcon} />
        {task.id}
      </span>

      <button
        className={styles.IdFavStatus__fav}
        onClick={handleToggleFavourites}
        type="button"
      >
        <IconSvg
          icon={isFavourite ? 'fav-filled' : 'fav'}
          className={cn(
            styles.IdFavStatus__favIcon,
            { [styles.IdFavStatus__favIcon_filled]: isFavourite },
          )}
        />
        &nbsp;В ИЗБРАННОЕ
      </button>

      <TaskIdPostPendStatus bottomSpace task={task} />

      <span className={styles.IdFavStatus__status}>
        {task.status !== 'available' && (
          <>
            <IconSvg
              icon={`task-${task.status}`}
              className={cn(styles.IdFavStatus__statusIcon, styles[`IdFavStatus__statusIcon_${task.status}`])}
            />
            {task.human_status}
          </>
        )}
      </span>
    </div>
  );
}

TaskIdFavStatus.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    human_status: PropTypes.string,
    favourite: PropTypes.bool,
  }).isRequired,
  bottomSpace: PropTypes.bool,
};

TaskIdFavStatus.defaultProps = {
  bottomSpace: false,
};
