import React from 'react';
import PropTypes from 'prop-types';

import styles from './CoverImage.module.scss';

export default function PartnerCoverImage({ partner }) {
  return (
    <div className={styles.PartnerCoverImage}>
      <div
        className={styles.PartnerCoverImage__image}
        style={ { backgroundImage: `url(${partner.cover_image})` } }
      />
    </div>
  );
}
