import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TopPageNotice from '../../../../../components/TopPageNotice/TopPageNotice';
import CardHeader from '../../../../../components/CardHeader/CardHeader';
import BuyHint from '../BuyHint/BuyHint';
import AnswerSubmitButton from '../AnswerSubmit';

import styles from './AnswerOwnSocial.module.scss';

const HEADERS = (token) => (
  {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'X-CSRF-Token': token,
  }
);

const AnswerOwnSocial = ({ taskStep, token, onTaskAnswer }) => {
  const [answer, setAnswer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [notice, setNotice] = useState();

  const handleChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleSubmitClick = async (e) => {
    e.preventDefault();
    setNotice('');
    setIsLoading(true);

    const data = {
      task_step_id: taskStep.id,
      task_answer: { answer },
    };

    try {
      const response = await fetch('/task_answers.json', {
        method: 'POST',
        headers: HEADERS(token),
        body: JSON.stringify(data),
      });
      const result = await response.json();

      setIsLoading(false);

      if (response.ok) {
        setNotice(result.notice);
        setTimeout(() => onTaskAnswer(), 450);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const isDisabled = isLoading || taskStep.attempts_left <= 0 || (answer?.length || 0) === 0;

  return (
    <>
      <TopPageNotice notice={notice} onClose={() => setNotice(null)} />
      <CardHeader bottomSpace="s">
        <h4>
          Сделай пост у себя в главной соц.сети согласно условиям задания.
        </h4>
      </CardHeader>
      <input
        className={`input input_attention ${styles.AnswerOwnSocial__input}`}
        type="text"
        placeholder="Укажи ссылку на пост или аккаунт"
        onChange={handleChange}
        id="textInput"
      />
      <BuyHint text={taskStep.human_attempts_left} />
      <AnswerSubmitButton disabled={isDisabled} onClick={handleSubmitClick} />
    </>
  );
};

AnswerOwnSocial.propTypes = {
  taskStep: PropTypes.shape({
    id: PropTypes.number,
    attempts_left: PropTypes.number,
    human_attempts_left: PropTypes.string,
    can_instagram: PropTypes.bool,
    can_facebook: PropTypes.bool,
    can_vkontakte: PropTypes.bool,
  }).isRequired,
  token: PropTypes.string.isRequired,
  onTaskAnswer: PropTypes.func.isRequired,
};

export default AnswerOwnSocial;
