import {
  action,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import moderatedPlayerStore from './moderatedPlayer';
import EventsApi from './eventsApi';

class ModeratedEvents {
  moderatedEvent = {}

  isEventUpdating = false

  constructor(playerStore, eventsApi) {
    makeObservable(this, {
      moderatedEvent: observable,
      isEventUpdating: observable,

      acceptEvent: action,
      rejectEvent: action,
      eventRegistration: action,
    });
    this.moderatedPlayerStore = playerStore;
    this.eventsApi = eventsApi;
  }

  acceptEvent = async (eventId, eventDay) => {
    const { player } = this.moderatedPlayerStore;
    if (player) {
      this.isEventUpdating = true;

      const result = await this.eventsApi.acceptEvent(player.id, eventId, eventDay);

      runInAction(() => {
        this.isEventUpdating = false;
        this.moderatedEvent = result.event;
      });
    }
  }

  rejectEvent = async (eventId, eventDay) => {
    const { player } = this.moderatedPlayerStore;
    if (player) {
      this.isEventUpdating = true;

      const result = await this.eventsApi.rejectEvent(player.id, eventId, eventDay);

      runInAction(() => {
        this.isEventUpdating = false;
        this.moderatedEvent = result.event;
      });
    }
  }

  eventRegistration = async (eventId, eventDay) => {
    const { player } = this.moderatedPlayerStore;
    if (player) {
      this.isEventUpdating = true;

      const result = await this.eventsApi.eventRegistration(player.id, eventId, eventDay);

      runInAction(() => {
        this.isEventUpdating = false;
        this.moderatedEvent = result.event;
      });
    }
  }

  takeEvent = async (playerId, eventId, eventDay) => {
    this.isEventUpdating = true;

    const result = await this.eventsApi.takeEvent(playerId, eventId, eventDay);

    runInAction(() => {
      this.isEventUpdating = false;
      this.moderatedEvent = result.event;
    });
  }

  resetRegistration = async (eventId, eventDay) => {
    this.isEventUpdating = true;

    const result = await this.eventsApi.resetRegistration(eventId, eventDay);

    runInAction(() => {
      this.isEventUpdating = false;
      this.moderatedEvent = result.event;
    });
  }

  setModeratedEvent = (event) => {
    runInAction(() => {
      this.moderatedEvent = event;
    });
  };
}

export default new ModeratedEvents(moderatedPlayerStore, new EventsApi());
