export function lockLayout() {
  const scrollTop = window.pageYOffset;

  document.body.style.position = 'fixed';
  document.body.style.overflow = 'hidden';
  document.body.style.top = `${-scrollTop}px`;
  document.body.style.right = '0px';
  document.body.style.bottom = '0px';
  document.body.style.left = '0px';
}

export function unLockLayout() {
  const lockedScrollTop = -1 * parseInt(document.body.style.top, 10);

  document.body.style.position = '';
  document.body.style.overflow = '';
  document.body.style.top = '';
  document.body.style.right = '';
  document.body.style.bottom = '';
  document.body.style.left = '';
  window.scrollTo(0, lockedScrollTop);
}

export function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

export function visitOrRedirectTo(url) {
  if (Turbolinks) {
    Turbolinks.visit(url);
  } else {
    window.location.href = url;
  }
};

export function declOfNum(n, titles) {
  // eslint-disable-next-line max-len, no-nested-ternary
  return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
}
