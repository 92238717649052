import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconSvg from '../../../../../../common/javascripts/components/IconSvg';

import styles from './IdPostPendStatus.module.scss';

export default function TaskIdPostPendStatus({ task, bottomSpace }) {
  const [isPostPend, setPostPend] = useState(task.postpend);

  const submitPostPend = async (newPostPend) => {
    let action = 'add';

    if (!newPostPend) {
      action = 'remove';
    }

    const url = `/postpend/${action}?task_id=${task.id}`;

    try {
      const response = await fetch(url, {
        method: 'POST',
      });

      if (response.ok) {
        setPostPend(newPostPend);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const handleToggleFavourites = () => {
    submitPostPend(!isPostPend);
  };

  return (
    <button
      className={styles.IdFavStatus__fav}
      onClick={handleToggleFavourites}
      type="button"
    >
      <IconSvg
        icon={isPostPend ? 'bookmark-filled' : 'bookmark'}
        className={cn(
          styles.IdFavStatus__favIcon,
          { [styles.IdFavStatus__favIcon_filled]: isPostPend },
        )}
      />
      &nbsp;ПОЗЖЕ
    </button>
  );
}

TaskIdPostPendStatus.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    human_status: PropTypes.string,
    postpend: PropTypes.bool,
  }).isRequired,
  bottomSpace: PropTypes.bool,
};

TaskIdPostPendStatus.defaultProps = {
  bottomSpace: false,
};
