import React from 'react';
import { observer } from 'mobx-react';

import playerViewStore from '../../stores/playerViews';

const UnreadNotificationsCounter = observer(() => {
  if (playerViewStore.unread < 1) {
    return null;
  }

  return (
    <div className="bottomMenu__itemBadge">
      {playerViewStore.unread}
    </div>
  );
});

export default UnreadNotificationsCounter;
