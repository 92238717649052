import React from 'react';
import PropTypes from 'prop-types';

import Logo from '../../../../../images/rf_logo_auth.png';

import styles from './Header.module.scss';

export default function AuthHeader({ welcomeText }) {
  return (
    <div className={styles.Header}>
      <div className={styles.Header__logo}>
        {!welcomeText.logo_url && <img src={Logo} alt="Rosafest Logo" />}
        {welcomeText.logo_url && <img src={welcomeText.logo_url} alt="Rosafest Logo" />}
      </div>
    </div>
  );
}

AuthHeader.propTypes = {
  welcomeText: PropTypes.shape({
    logo_url: PropTypes.string,
  }).isRequired,
};
