import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './FromEditor.module.scss';

export default function FromEditor({ children, className }) {
  return (
    <>
      <div
        className={cn(styles.FromEditor, className)}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: children }}
      />
    </>
  );
}

FromEditor.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
};

FromEditor.defaultProps = {
  children: '',
  className: '',
};
