import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SubmitButton from '../AnswerSubmit';

import CardHeader from '../../../../../components/CardHeader';
import BuyHint from '../BuyHint';

import TopPageNotice from '../../../../../components/TopPageNotice';

import styles from './AnswerText.module.scss';

const HEADERS = (token) => (
  {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'X-CSRF-Token': token,
  }
);

export default function AnswerTextInput({ taskStep, token, onTaskAnswer }) {
  const [answer, setAnswer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [notice, setNotice] = useState();

  const handleChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleSubmitClick = async (e) => {
    e.preventDefault();
    setNotice('');
    setIsLoading(true);

    const data = {
      task_step_id: taskStep.id,
      task_answer: { answer },
    };

    try {
      const response = await fetch('/task_answers.json', {
        method: 'POST',
        headers: HEADERS(token),
        body: JSON.stringify(data),
      });
      const result = await response.json();

      setIsLoading(false);

      if (response.ok) {
        setNotice(result.notice);
        setTimeout(() => onTaskAnswer(), 450);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const isDisabled = isLoading || taskStep.attempts_left <= 0 || answer === '';

  return (
    <form className={styles.AnswerText}>
      <TopPageNotice notice={notice} onClose={() => setNotice(null)} />
      <label htmlFor="textInput">
        <CardHeader bottomSpace="s">
          <h4>Формат ответа: текст и/или цифры. Регистр и пробелы не важно, а опечатки и неверный язык это ошибка.</h4>
        </CardHeader>
        <input
          className={`input input_attention ${styles.AnswerText__input}`}
          type="text"
          placeholder="Введите свой ответ"
          onChange={handleChange}
          id="textInput"
        />
      </label>

      <BuyHint text={taskStep.human_attempts_left} />
      <SubmitButton disabled={isDisabled} onClick={handleSubmitClick} />
    </form>
  );
}

AnswerTextInput.propTypes = {
  taskStep: PropTypes.object,
  token: PropTypes.string,
  onTaskAnswer: PropTypes.func,
};
