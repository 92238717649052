import React, { useState, useRef } from 'react';

import TeamPlayerSearch from './components/PlayerSearch';
import TeamPlayerNotFound from './components/PlayerNotFound';

export default function TeamInvitePlayer() {
  const [playerNotFound, setPlayerNotFound] = useState(false);
  const phone = useRef();

  const handlePlayerNotFound = (searchedPhone) => {
    phone.current = searchedPhone;
    setPlayerNotFound(true);
  };

  const handleShowPlayerSearch = () => setPlayerNotFound(false);

  if (playerNotFound) {
    return <TeamPlayerNotFound phone={phone.current} onShowPlayerSearch={handleShowPlayerSearch} />;
  }

  return <TeamPlayerSearch onPlayerNotFound={handlePlayerNotFound} />;
}
