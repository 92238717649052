import React, { useState } from 'react';
import TeamPlayStepHeader from './components/StepHeader';
import TeamPlayStartStep from './components/StartStep';
import TeamPlayNewStep from './components/NewStep';
import TeamPlaySearchStep from './components/SearchStep';

import AttentionMessage from '../../../components/AttentionMessage';
import TopPageNotice from '../../../components/TopPageNotice';

// import styles from './TeamPlay.module.scss';

const steps = {
  start: 'start',
  new: 'new',
  search: 'search',
  join: 'join',
};

export default function TeamPlayScreen({ initialStep, url, id }) {
  const [currentStep, setCurrentStep] = useState(steps[initialStep] || steps.start);
  const [notice, setNotice] = useState('');

  const handleNewStepSelect = () => {
    window.history.pushState({}, '', `${window.location.href}?step=new`);
    setCurrentStep(steps.new);
  };

  const handleSearchStepSelect = () => {
    window.history.pushState({}, '', `${window.location.href}?step=search`);
    setCurrentStep(steps.search);
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case steps.start:
        return (
          <TeamPlayStartStep
            onNewStepSelect={handleNewStepSelect}
            onSearchStepSelect={handleSearchStepSelect}
          />
        );
      case steps.new:
        return (
          <TeamPlayNewStep
            url={url}
            setNotice={setNotice}
            userId={id}
          />
        );
      case steps.search:
        return (
          <TeamPlaySearchStep setNotice={setNotice} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <TopPageNotice notice={notice} onClose={() => setNotice(null)} />
      <TeamPlayStepHeader step={currentStep} />
      {renderCurrentStep()}
    </>
  );
}
