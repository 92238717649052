import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './CardTag.module.scss';

export default function CardTag({ tag }) {
  return (
    <span className={cn(styles.CardTag, { [styles.CardTag_hot]: tag.important })}>
      {`#${tag.title}`}
    </span>
  );
}

CardTag.propTypes = {
  tag: PropTypes.object
};
