import React from 'react';
import PropTypes from 'prop-types';

import Card from '../../../../components/Card';
import CardHeader from '../../../../components/CardHeader';
import Cost from '../../../../components/Cost';
import CardTagsList from '../../../../components/CardTagsList';
import FromEditor from '../../../../components/FromEditor';

import styles from './ProductInfo.module.scss';

export default function ProductInfo({ product, isAward }) {
  const hasTags = product.tags && product.tags.length > 0;
  const hasImage = product.images && product.images[1];
  const hasSellInstructions = (product.sell_instructions ||[]).length > 0;

  return (
    <Card noShadow className={styles.ProductInfo}>
      <CardHeader className={styles.ProductInfo__header}>
        <h1>{product.title}</h1>
      </CardHeader>

      {!isAward && (
        <div className={styles.ProductInfo__cost}>
          <Cost icon="player-points" amount={product.cost_player_points} />
          {/* <Cost icon="team-points" amount={product.cost_team_points} /> */}
        </div>
      )}

      {
        hasImage
        && (
          <div className={styles.ProductInfo__imageWrap}>
            <img src={product.images[1].url} alt="Product" />
          </div>
        )
      }

      {
        hasTags
        && (
          <div className={styles.ProductInfo__tagsWrap}>
            <CardTagsList tags={product.tags} />
          </div>
        )
      }

      <div className={styles.ProductInfo__descriptionWrap}>
        <FromEditor>{product.description}</FromEditor>
      </div>

      {hasSellInstructions && (
        <div className={styles.ProductInfo__howToUseWrap}>
          <CardHeader className={styles.ProductInfo__header}>
            <h2>Где и как получить?</h2>
          </CardHeader>
          <FromEditor>{product.sell_instructions}</FromEditor>
        </div>
      )}
    </Card>
  );
}

ProductInfo.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    cost_player_points: PropTypes.number,
    cost_team_points: PropTypes.number,
    sell_instructions: PropTypes.string,
    tags: PropTypes.array,
  }).isRequired,
  isAward: PropTypes.bool.isRequired,
};
