import React from 'react';
import PropTypes from 'prop-types';

import styles from './ProgressRing.module.scss';

export default function ProgressRing({
  rings,
}) {
  const sizes = [];
  const ringItems = rings.map((ring) => {
    const size = (ring.r * 2) + ring.strokeWidth;
    sizes.push(size);

    const circumference = ring.r * 2 * Math.PI;
    const offset = circumference - (ring.percent / 100) * circumference;
    const circleStyles = {
      strokeDasharray: `${circumference} ${circumference}`,
      strokeDashoffset: offset,
    };

    return (
      <circle
        key={`${ring.stroke}-${ring.strokeWidth || 4}-${ring.r}-${ring.fill || 'transparent'}`}
        style={circleStyles}
        className={styles.Ring__circle}
        stroke={ring.stroke}
        strokeWidth={ring.strokeWidth || 4}
        fill={ring.fill || 'transparent'}
        r={ring.r}
        cx="50%"
        cy="50%"
      />
    );
  });

  const maxSize = Math.max(...sizes);

  return (
    <svg
      className={styles.Ring}
      width={maxSize}
      height={maxSize}
    >
      {ringItems}
    </svg>
  );
}

ProgressRing.propTypes = {
  rings: PropTypes.arrayOf(PropTypes.shape({
    r: PropTypes.number,
    strokeWidth: PropTypes.number,
    stroke: PropTypes.string,
    fill: PropTypes.string,
    percent: PropTypes.number,
  })),
};

ProgressRing.defaultProps = {
  rings: [
    {
      r: 30, strokeWidth: 4, stroke: 'red', fill: 'transparent', percent: 30,
    },
    {
      r: 15, strokeWidth: 2, stroke: 'green', fill: 'transparent', percent: 59,
    },
  ],
};
