/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconSvg from 'common/javascripts/components/IconSvg';
import PlayerRanksSlideUp from '../PlayerRanksSlideUp';

import styles from './RankProgress.module.scss';

const rankInfoPagePath = '/player_ranks';

export default function RankProgress({
  steps, stepsDone, rankTitle, nextRankTitle,
}) {
  const [showRanks, setShowRanks] = useState(false);
  const nextRankSteps = steps - stepsDone;
  const isFinalRank = nextRankSteps <= 0;

  const handleRanksShow = () => {
    setShowRanks(true);
  };

  const handleRanksClose = () => {
    setShowRanks(false);
  };

  if (isFinalRank) {
    return (
      <div className={styles.RankProgress}>
        <div className={styles.RankProgress__header}>
          <span className={styles.RankProgress__headerText}>
            Поздравляем! Ты достиг высшего звания&nbsp;–&nbsp;
          </span>
          <span
            className={styles.RankProgress__headerRankLabel}
            onClick={handleRanksShow}
            onKeyDown={handleRanksShow}
            role="button"
            tabIndex="0"
          >
            {rankTitle}
          </span>
        </div>
      </div>
    );
  }

  const getIconName = (index) => {
    if (index < stepsDone) {
      return 'rank-step-done';
    }
    if (index === stepsDone) {
      return 'rank-step-current';
    }
    return 'rank-step';
  };

  const progressLineItems = [...Array(steps + 1)].map((_, index) => (
    <React.Fragment key={index}>
      <IconSvg
        icon={getIconName(index)}
        className={cn(styles.RankProgress__stepIcon, { [styles.RankProgress__stepIcon_current]: index === stepsDone })}
        key={index}
      />
      {(index < steps) && <div className={styles.RankProgress__stepLine} />}
    </React.Fragment>
  ));

  return (
    <div className={styles.RankProgress}>
      <div className={styles.RankProgress__header}>
        <span className={styles.RankProgress__headerText}>До </span>
        <span
          className={styles.RankProgress__headerRankLabel}
          onClick={handleRanksShow}
          onKeyDown={handleRanksShow}
          role="button"
          tabIndex="0"
        >
          {nextRankTitle}
        </span>
        <span className={styles.RankProgress__headerText}>
          {` тебе еще ${nextRankSteps} заданий`}
        </span>
      </div>

      <div className={styles.RankProgress__progressLine}>
        {progressLineItems}
      </div>

      {/* <div className={styles.RankProgress__ranks}> */}
      {/* <span className={styles.RankProgress__rankLabel}>{rankTitle}</span> */}
      {/* <span className={styles.RankProgress__rankLabel}>{nextRankTitle}</span> */}
      {/* </div> */}
      <PlayerRanksSlideUp show={showRanks} onClose={handleRanksClose} />
    </div>
  );
}

RankProgress.propTypes = {
  steps: PropTypes.number,
  stepsDone: PropTypes.number,
  rankTitle: PropTypes.string,
  nextRankTitle: PropTypes.string,
};

RankProgress.defaultProps = {
  steps: 2,
  stepsDone: 1,
  rankTitle: '',
  nextRankTitle: '',
};
