import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { format } from 'date-fns';
import { ru } from 'date-fns/esm/locale';
import { isEventFinished } from '../../../../../helpers/dates';

import PageCardsList from '../../../../../components/PageCardsList';
import EventListCard from '../ListCard';

// import { visitOrRedirectTo } from '../../../../../../../common/javascripts/helpers';
// import { saveScrollElementToHistory } from '../../../../../helpers/scroll';
import BottomSlideUp from '../../../../../components/BottomSlideUp';
import EventShowScreen from '../../../Show/Show';
import moderatedEventStore from '../../../../../stores/moderatedEvents';

import styles from './List.module.scss';

// const eventPath = (id, day) => `/events/${id}?date=${day}`;
const eventElementId = (id, day) => `event-${id}-${day}`;

function EventsList({ day, events }) {
  if ((events || []).length <= 0) return null;

  const [showSlideUp, setShowSlideUp] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setModeratedEvent } = moderatedEventStore;

  const getEvent = async (itemId) => {
    try {
      const response = await fetch(`/events/${itemId}.json?date=${day}`);
      const data = await response.json();

      if (data.status === 'ok') {
        setCurrentEvent(data.event);
        setModeratedEvent(data.event);
        setCurrentPlayer(data.player);
        setLoading(false);
        setShowSlideUp(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const handleVisitEvent = (event) => {
  //   saveScrollElementToHistory(eventElementId(event.id, day));
  //   visitOrRedirectTo(eventPath(event.id, day));
  // };

  const handleClicEvent = (itemId) => {
    setLoading(true);
    getEvent(itemId);
  };

  const handleCloseEvent = () => {
    setCurrentEvent(null);
    setShowSlideUp(false);
  };

  return (
    <>
      <div className={loading ? styles.List__modalLoader : ''}>
        <div className={loading ? styles.List__modalLoaderText : styles.List__modalLoaderTextNone} />
      </div>
      <div className={styles.List}>
        <h4 className={styles.List__dayHeader} id={`h4-${day}`}>
          {format(new Date(day), 'd MMMM, eeee', { locale: ru })}
        </h4>
        <PageCardsList>
          {
            events.map((event) => {
              const isFinished = isEventFinished(
                new Date(day), new Date(event.start_at), new Date(event.end_at),
              );

              return (
                <li id={eventElementId(event.id, day)} key={`${day}_${event.id}`}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    // eslint-disable-next-line no-script-url
                    href="javascript:void(0)"
                    className={cn(styles.List__cardLink, { [styles.List__cardLink_finished]: isFinished })}
                    onClick={handleClicEvent.bind(null, event.id)}
                  >
                    <EventListCard
                      event={event}
                      day={day}
                      isFinished={isFinished}
                    />
                  </a>
                </li>
              );
            })
          }
        </PageCardsList>
        <BottomSlideUp show={showSlideUp} onClose={handleCloseEvent}>
          {currentEvent && (
            <EventShowScreen
              event={currentEvent}
              token={null}
              player={currentPlayer}
              day={day}
            />
          )}
        </BottomSlideUp>
      </div>
    </>
  );
}

EventsList.propTypes = {
  day: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
  })).isRequired,
};

export default EventsList;
