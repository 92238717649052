import React from 'react';
import PropTypes from 'prop-types';

import NotificationCard from '../components/Card';
import NotificationTitle from '../components/Title';
import PlayerRatingCard from '../../PlayerRatingCard';

export default function NotificationInviteToTeamRejectedCard({ notification }) {
  return (
    <NotificationCard notification={notification}>
      <NotificationTitle
        titleForNoHeaderCard
        title={notification.data.title}
      />
      <PlayerRatingCard noPublicLink incard player={notification.notice_player} />
    </NotificationCard>
  );
}

NotificationInviteToTeamRejectedCard.propTypes = {
  notification: PropTypes.object.isRequired,
};
