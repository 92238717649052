import React from 'react';
import PropTypes from 'prop-types';

import Card from '../Card';
import styles from './ModeratedOrder.module.scss';
import moderatedOrdersStore from '../../stores/moderatedOrders';

function ModeratedOrder({ order }) {
  const canComplete = order.state === 'paid';
  const canCancel = order.state === 'paid';

  const { completeOrder, cancelOrder, isOrderUpdating } = moderatedOrdersStore;

  const handleCancelClick = () => {
    cancelOrder(order.id);
  };
  const handleCompleteClick = () => {
    completeOrder(order.id);
  };

  return (
    <Card noShadow className={styles.ModeratedOrder}>
      <div className={styles.ModeratedOrder__amount}>
        {`${order.amount} шт.`}
      </div>
      <div className={styles.ModeratedOrder__actions}>
        <button
          type="button"
          className="button button_secondary"
          disabled={!canCancel || isOrderUpdating}
          onClick={handleCancelClick}
        >
          Удалить приз
        </button>
        <button
          type="button"
          className="button"
          disabled={!canComplete || isOrderUpdating}
          onClick={handleCompleteClick}
        >
          Выдать приз
        </button>
      </div>
    </Card>
  );
}

ModeratedOrder.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    state: PropTypes.string,
  }).isRequired,
};

export default ModeratedOrder;
