import React from 'react';
import ProductInfo from '../components/ProductInfo';
import ProductOrderForm from './components/OrderForm';
import ProductPartnersList from '../components/PartnersList';

export default function ProductShowScreen({ product, player, token, onShowOrder }) {
  const handleShowOrder = (result) => {
    if (result.status !== 'ok') return;

    onShowOrder(result);
  };

  return (
    <>
      <ProductInfo product={product} />
      <ProductOrderForm
        product={product}
        token={token}
        player={player}
        onShowOrder={handleShowOrder}
      />
      <ProductPartnersList partners={product.partners} />
    </>
  );
}
