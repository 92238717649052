import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './PageCardsList.module.scss';

export default function PageCardsList({ children, space }) {
  return (
    <ul className={cn(styles.PageCardsList, styles[`PageCardsList_space-${space}`])}>
      {children}
    </ul>
  );
}

PageCardsList.propTypes = {
  children: PropTypes.node.isRequired,
  space: PropTypes.number,
};

PageCardsList.defaultProps = {
  space: 1,
}
