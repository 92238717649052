import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import IconSvg from '../../../../../common/javascripts/components/IconSvg';
import BottomSlideUp from '../../BottomSlideUp';
import ProgramLinkSlideUpContent from './SlideUpContent';

import { visitOrRedirectTo } from '../../../../../common/javascripts/helpers';

import currentPlayerStore from '../../../stores/currentPlayerStore';

import styles from './ProgramLink.module.scss';

const requestProgramAccessPath = '/players/request_program_access';
const programPath = '/events/program';

export default function ProgramLink() {
  const [showSlideUp, setShowSlideUp] = useState(false);

  const { player } = currentPlayerStore;

  const handleLinkClick = (e) => {
    e.preventDefault();
    if (player.access_to_programm) {
      visitOrRedirectTo(programPath);
    } else {
      setShowSlideUp(true);
    }
  };

  const slideUpClose = () => setShowSlideUp(false);

  return (
    <>
      <a className="bottomMenu__item" href="/events/program" onClick={handleLinkClick}>
        <IconSvg icon="program" className="bottomMenu__itemIcon" />
        <span className="bottomMenu__itemTitle">Программа</span>
      </a>

      <BottomSlideUp show={showSlideUp} onClose={slideUpClose}>
        <ProgramLinkSlideUpContent
          programPath={programPath}
          requestProgramAccessPath={requestProgramAccessPath}
        />
      </BottomSlideUp>
    </>
  );
}
