import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { format } from 'date-fns';

import PageContentOtherBg from '../../../components/PageContentOtherBg';
import EventNavigation from './components/Navigation';
import EventsHeader from './components/EventsHeader';
import ContentNotice from '../../../components/ContentNotice';
import EventTagsFilter from './components/EventTagsFilter';
import EventDaysFilter from './components/EventDaysFilter';
import BannersList from '../../../components/Banner/BannersList';
import EventPartnerLogos from '../components/ParnerLogos';
import { scrollToElement, scrollElementFromParam } from '../../../helpers/scroll';

import EventsList from './components/List';

import eventStore from '../../../stores/events';

const h4ElementId = (day) => `h4-${day}`;

function EventsScreen({
  initialTab,
  initialTagFilter,
  initialDayFilter,
  days,
  tags,
  typeEvent,
  settings
}) {
  const {
    filteredEvents,
    isLoading,
    dayFilter,
    tagFilter,
    currentTab,
    fetchEvents,
    onFilterChange,
    setFiltersFromQuery,
    isInit,
    currentTypeEvent,
  } = eventStore;

  const currentDate = format(new Date(), 'yyyy-MM-dd');

  const handleTagFilterChange = (newTagFilter) => {
    onFilterChange({
      tag: newTagFilter,
    });
  };

  const handleDayFilterChange = (newDayFilter) => {
    onFilterChange({
      day: newDayFilter,
    });
  };

  const handleShowMore = (e) => {
    e.preventDefault();
    eventStore.fetchMoreEvents();
  };

  useEffect(() => {
    async function fetchAndScroll() {
      await fetchEvents(typeEvent, initialTagFilter, initialDayFilter, initialTab);
      scrollToElement({
        getTarget: () => scrollElementFromParam('event'),
      });
    }

    if (currentTypeEvent !== typeEvent) fetchAndScroll();

    if (!isInit && !isLoading && currentTab === 'tags') {
      const eventsByDate = toJS(filteredEvents)[currentDate];
      const firstEventByDate = eventsByDate ? toJS(filteredEvents)[currentDate][0] : null;

      if (firstEventByDate) {
        scrollToElement({
          getTarget: () => document.getElementById(h4ElementId(currentDate)),
        });
      } else {
        scrollToElement({
          getTarget: () => scrollElementFromParam('event'),
        });
      }

      return;
    }

    // if (isInit) fetchAndScroll();
    if (!isInit) scrollToElement({ getTarget: () => scrollElementFromParam('event') });

    window.onpopstate = () => {
      setFiltersFromQuery();
      scrollToElement({
        getTarget: () => scrollElementFromParam('event'),
      });
    };
  }, [tagFilter]);

  const eventsList = () => (
    Object.entries(toJS(filteredEvents)).map(([day, dayEvents]) => (
      <EventsList day={day} events={dayEvents} key={day} />
    ))
  );

  const anyEvents = Object.keys(toJS(filteredEvents) || {}).length > 0;

  return (
    <>
      <BannersList usedFor="program" />

      <EventsHeader />

      <EventNavigation />

      {(currentTab === 'days') && (
        <EventDaysFilter
          filter={dayFilter}
          days={days}
          onFilterChange={handleDayFilterChange}
        />
      )}

      {(currentTab === 'tags') && (
        <EventTagsFilter
          filter={tagFilter}
          tags={tags}
          onFilterChange={handleTagFilterChange}
        />
      )}

      <PageContentOtherBg>
        {eventsList()}

        {(!anyEvents && !isLoading) && (
          <ContentNotice
            outline
            notClosable
            bottomSpace
          >
            <div
              // className={styles.WinnerAward__prize}
              dangerouslySetInnerHTML={{ __html: settings?.events_notice || 'Cкоро здесь появятся события' }}
            />
          </ContentNotice>
        )}

        {(isLoading) && (
          <button
            type="button"
            className="button button_large button_fullwidth"
            onClick={handleShowMore}
            disabled={isLoading}
          >
            {isLoading ? 'Загружаем...' : 'Показать еще'}
          </button>
        )}

        <EventPartnerLogos />
      </PageContentOtherBg>
    </>
  );
}

EventsScreen.propTypes = {
  initialTab: PropTypes.string,
  initialTagFilter: PropTypes.number,
  initialDayFilter: PropTypes.string,
  days: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    day: PropTypes.string,
    description: PropTypes.string,
    month: PropTypes.string,
    position: PropTypes.number,
  })),
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  })),
  typeEvent: PropTypes.string,
  settings: PropTypes.shape({
    events_notice: PropTypes.string,
  }).isRequired,
};

EventsScreen.defaultProps = {
  initialTab: 'days',
  initialTagFilter: 0,
  initialDayFilter: null,
  typeEvent: 'program',
  days: [],
  tags: [],
};

export default observer(EventsScreen);
