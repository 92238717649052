import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import IconSvg from '../../../../common/javascripts/components/IconSvg';
import CardHeader from '../CardHeader';
import BottomSlideUp from '../BottomSlideUp';

import Avatar from '../Avatar';
import BrumelImage from '../../../images/brumel.jpg';
import { headersWithToken } from '../../../../common/javascripts/fetchOptions';

import styles from './BecomePartnerButton.module.scss';

export default function BecomePartnerButton({ top, bottom, center }) {
  const [showSlideUp, setShowSlideUp] = useState(false);
  const [contact, setContact] = useState(false);

  const fetchContact = async () => {
    try {
      const response = await fetch('/contacts/contact_for_partner', {
        method: 'GET',
        headers: headersWithToken(),
      });

      const result = await response.json();

      if (response.ok) {
        setContact(result.contact);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchContact();
  }, []);

  if (!contact) return '';

  const hasEmail = contact.email.length !== 0;
  const hasWhatsapp = contact.whatsapp.length !== 0;
  const hasTelegram = contact.telegram.length !== 0;

  return (
    <>
      <button
        type="button"
        className={cn('button button_large button_fullwidth', styles.Button__bg,
          { [styles.Button__top]: top },
          { [styles.Button__bottom]: bottom },
          { [styles.Button__center]: center },
        )}
        onClick={() => setShowSlideUp(true)}
      >
        Стать партнером
      </button>

      <BottomSlideUp show={showSlideUp} onClose={() => setShowSlideUp(false)}>
        <div className={styles.Contact}>
          <Avatar
            avatar={contact.avatar_url || BrumelImage}
            className={styles.Contact__avatar}
          />

          <CardHeader className={styles.Contact__name}>
            <h2>{`${contact.name} ${contact.surname}`}</h2>
          </CardHeader>

          <div className={styles.Contact__phoneHeader}>
            {contact.description}
          </div>

          <a className={styles.Contact__phone} href={`tel:${contact.phone}`}>
            {contact.phone}
          </a>

          {
            (hasWhatsapp || hasEmail || hasTelegram) && (
              <div className={styles.Contact__messageWrap}>

                {
                  hasEmail && (
                    <a
                      className={styles.Contact__messageChannel}
                      href={`mailto:${contact.email}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconSvg icon="contact-mail" className={styles.Contact__messageIcon} />
                      <span className={styles.Contact__messageLabel}>почта</span>
                    </a>
                  )
                }

                {
                  hasWhatsapp && (
                    <a
                      className={styles.Contact__messageChannel}
                      href={`https://${contact.whatsapp}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconSvg icon="contact-whatsapp" className={styles.Contact__messageIcon} />
                      <span className={styles.Contact__messageLabel}>WhatsApp</span>
                    </a>
                  )
                }

                {
                  hasTelegram && (
                    <a
                      className={styles.Contact__messageChannel}
                      href={`https://${contact.telegram}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconSvg icon="contact-telegram" className={styles.Contact__messageIcon} />
                      <span className={styles.Contact__messageLabel}>Telegram</span>
                    </a>
                  )
                }
              </div>
            )
          }
          <button
            type="button"
            className={cn('button button_large button_fullwidth', styles.Button__bg)}
            onClick={() => setShowSlideUp(false)}
          >
            OK
          </button>
        </div>
      </BottomSlideUp>
    </>
  );
}

BecomePartnerButton.propTypes = {
  top: PropTypes.string,
  bottom: PropTypes.string,
  center: PropTypes.string,
};

BecomePartnerButton.defaultProps = {
  top: false,
  bottom: true,
  center: false,
};
