import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PageCardsList from '../../../../../components/PageCardsList';
import PartnerListCard from '../../../../../components/Partner/ListCard';
import BottomSlideUp from '../../../../../components/BottomSlideUp';
import PartnerShowScreen from '../../../Show/Show';

import styles from './CardsList.module.scss';

export default function PartnerCardsList({ items }) {
  const [showSlideUp, setShowSlideUp] = useState(false);
  const [elem, setElem] = useState({});

  const handleClickCard = (item) => {
    setElem(item);
    setShowSlideUp(!showSlideUp);
  };

  const cardItems = items.map((item) => (
    <li key={item.id}>
      <a className={styles.PartnerCardsList__link} onClick={handleClickCard.bind(null, item)}>
        <PartnerListCard partner={item} />
      </a>
    </li>
  ));

  return (
    <>
      <PageCardsList>
        {cardItems}
      </PageCardsList>
      <BottomSlideUp show={showSlideUp} onClose={() => setShowSlideUp(false)}>
        <PartnerShowScreen partner={elem} />
      </BottomSlideUp>
    </>
  );
}

PartnerCardsList.propTypes = {
  items: PropTypes.instanceOf(Array),
};

PartnerCardsList.defaultProps = {
  items: [],
};
