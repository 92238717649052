import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import TopPageNotice from '../../../../../components/TopPageNotice';
import SocialButton from '../SocialButton';
import TaskAnswersApi from '../../../../../stores/taskAnswersApi';
import CardHeader from '../../../../../components/CardHeader';
import InstagramButton from '../InstagramButton';
import BuyHint from '../BuyHint/BuyHint';

const answersApi = new TaskAnswersApi();

const buttonDefinitions = {
  // instagram: {
  // provider: 'instagram_graph',
  // text: 'Я выложил в Instagram',
  // },
  vk: {
    provider: 'vkontakte',
    text: 'Я выложил в VK',
  },
  facebook: {
    provider: 'facebook',
    text: 'Я выложил в Facebook',
  },
};

function authUrl(provider, answer) {
  const taskPath = `${window.location.pathname}?answer=${answer}`;
  return `/auth/${provider}?origin=${encodeURIComponent(taskPath)}`;
}

function hasProfile(player, provider) {
  return !!player.player_identities.find((identity) => provider === identity.provider);
}

function removeParamsFromAddress() {
  window.history.replaceState({}, document.title, window.location.pathname);
}

function AnswerSocial({ taskStep, player, onTaskAnswer }) {
  const [isLoading, setIsLoading] = useState(false);
  const [notice, setNotice] = useState();

  const handleSubmitClick = (social) => async () => {
    setNotice('');
    setIsLoading(true);

    // TODO: policy on server for can_{provider};
    try {
      const result = await answersApi.createAnswer(taskStep.id, social);

      setIsLoading(false);

      if (result) {
        setNotice(result.notice);
        setTimeout(() => onTaskAnswer(), 450);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const isDisabled = isLoading || taskStep.attempts_left <= 0;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const answerFromParams = urlParams.get('answer');
    Object.keys(buttonDefinitions).forEach((answer) => {
      const { provider } = buttonDefinitions[answer];
      if (answerFromParams === answer && hasProfile(player, provider)) {
        handleSubmitClick(answer)();
        removeParamsFromAddress();
      }
    });
  }, []);

  return (
    <>
      <TopPageNotice notice={notice} onClose={() => setNotice(null)} />
      <CardHeader bottomSpace="s">
        <h4>
          Сделай пост у себя&nbsp;
          {taskStep.can_instagram ? 'Instagram' : ''}
          {!taskStep.can_instagram && taskStep.can_vkontakte ? 'Vkontakte' : ''}
          {taskStep.can_instagram && taskStep.can_vkontakte ? ' или Vkontakte' : ''}
          {(taskStep.can_instagram || taskStep.can_vkontakte) && taskStep.can_facebook ? ' или Facebook' : '' }
          {(!taskStep.can_instagram && !taskStep.can_vkontakte) && taskStep.can_facebook ? 'Facebook' : '' }
          &nbsp;согласно условиям задания.
        </h4>
      </CardHeader>

      {
        taskStep.can_instagram && (
          <InstagramButton
            hasProfile={hasProfile(player, 'instagram_graph')}
            onAnswer={() => handleSubmitClick('instagram')()}
          />
        )
      }

      {
        Object.keys(buttonDefinitions).map((answer) => {
          const { provider, text } = buttonDefinitions[answer];
          if (!taskStep[`can_${provider}`]) return null;
          return (
            <SocialButton
              key={answer}
              isDisabled={isDisabled}
              onClick={handleSubmitClick(answer)}
              authUrl={authUrl(provider, answer)}
              hasProfile={hasProfile(player, provider)}
            >
              {text}
            </SocialButton>
          );
        })
      }

      <BuyHint text={taskStep.human_attempts_left} />

    </>
  );
}

AnswerSocial.propTypes = {
  taskStep: PropTypes.shape({
    id: PropTypes.number,
    attempts_left: PropTypes.number,
    human_attempts_left: PropTypes.string,
    can_instagram: PropTypes.bool,
    can_facebook: PropTypes.bool,
    can_vkontakte: PropTypes.bool,
  }).isRequired,
  player: PropTypes.shape({
    id: PropTypes.number,
    player_identities: PropTypes.arrayOf(PropTypes.shape({
      provider: PropTypes.string,
    })).isRequired,
  }).isRequired,
  onTaskAnswer: PropTypes.func.isRequired,
};

export default AnswerSocial;
