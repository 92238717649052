import React from 'react';
// import Logo from '../../../../../../images/rf-logo-events.png';
import styles from './EventsHeader.module.scss';

function EventPlayers() {
  return (
    <div className={styles.EventsHeader}>
      <h1 className={styles.EventsHeader__header}>Программа</h1>
      <div className={styles.EventsHeader__logo}>
        {/* <img src={Logo} alt="Fest logo" /> */}
      </div>
    </div>
  );
}

export default React.memo(EventPlayers);
