import React from 'react';
import PropTypes from 'prop-types';
import IconSvg from '../../../../common/javascripts/components/IconSvg';

import styles from './SocialButtons.module.scss';

export default function SocialButtons({
  vkProfile, fbProfile, igProfile,
}) {
  const hasSocials = vkProfile || fbProfile || igProfile;

  if (!hasSocials) return null;

  return (
    <div className={styles.SocialButtons}>
      {
        vkProfile
        && (
          <a href={`https://${vkProfile}`} target="_blank" rel="noreferrer">
            <IconSvg icon="vkontakte" className={styles.SocialButtons__icon} />
          </a>
        )
      }
      {
        fbProfile
        && (
          <a href={`https://${fbProfile}`} target="_blank" rel="noreferrer">
            <IconSvg icon="facebook" className={styles.SocialButtons__icon} />
          </a>
        )
      }
      {
        igProfile
        && (
          <a href={`https://${igProfile}`} target="_blank" rel="noreferrer">
            <IconSvg icon="instagram" className={styles.SocialButtons__icon} />
          </a>
        )
      }
    </div>
  );
}

SocialButtons.propTypes = {
  vkProfile: PropTypes.string,
  fbProfile: PropTypes.string,
  igProfile: PropTypes.string,
};

SocialButtons.defaultProps = {
  vkProfile: null,
  fbProfile: null,
  igProfile: null,
};
