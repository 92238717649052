import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import AttentionMessage from '../../../../../components/AttentionMessage';
import { visitOrRedirectTo } from "common/javascripts/helpers";
import { DEFAULT_OPTIONS, headersWithToken } from "common/javascripts/fetchOptions";

import styles from './NewStep.module.scss';

export default function TeamPlayNewStep({ url, setNotice }) {
  const [title, setTitle] = useState('');
  const [isValid, setIsValid] = useState(false);

  const handleChangeTitle = (e) => {
    const { value } = e.target;

    setTitle(value);
    if (value.length >= 1) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleNewStepSubmit = async (title) => {
    setNotice('');
    const data = { team: { title: title } };

    try {
      const response = await fetch(`${url}.json`, {
        ...DEFAULT_OPTIONS,
        method: 'POST',
        body: JSON.stringify(data),
        headers: headersWithToken(),
      });
      const result = await response.json();

      if (result.status === 'error') {
        setNotice(result.notice);
        return;
      }
      visitOrRedirectTo(result.redirect);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();
    handleNewStepSubmit(title);
  };

  return (
    <>
      <form className={styles.Step}>
        <div className={styles.Step__inputWrap}>
          <input
            className={cn('input', 'input_large', 'input_darkBorder')}
            type="text"
            id="title"
            value={title}
            placeholder="Введите название"
            onChange={handleChangeTitle}
          />
        </div>

        <button
          className="button button_large button_fullwidth"
          onClick={handleSubmitClick}
          disabled={!isValid}
          type="submit"
        >
          Создать команду
        </button>
      </form>
      <AttentionMessage
        isHint
        message="В течение 24 часов ты можешь удалить команду"
      />
    </>
  );
}
