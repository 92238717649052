import React from 'react';
import PropTypes from 'prop-types';

import styles from './AgeRestrictionBadge.module.scss';

export default function AgeRestrictionBadge({ age }) {
  if (!age || age <= 0) return null;

  return (
    <div className={styles.AgeRestrictionBadge}>
      {age}&nbsp;+
    </div>
  );
}

AgeRestrictionBadge.propTypes = {
  age: PropTypes.number,
}
