import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './PageHeader.module.scss';

export default function PageHeader({ className, children, bottomSpace }) {
  return (
    <div className={cn(className, styles.PageHeader, { [styles[`PageHeader_bottomSpace-${bottomSpace}`]]: bottomSpace })}>
      {children}
    </div>
  );
}

PageHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  bottomSpace: PropTypes.string,
};

PageHeader.defaultProps = {
  className: '',
  children: '',
  bottomSpace: null,
};
