import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PageHeader from '../../../../../components/PageHeader';
import PageCardsList from '../../../../../components/PageCardsList';
import PartnerListCard from '../../../../../components/Partner/ListCard';
import BecomePartnerButton from '../../../../../components/BecomePartnerButton';
import BottomSlideUp from '../../../../../components/BottomSlideUp';
import PartnerShowScreen from '../../../../Partner/Show';

import styles from './PartnersList.module.scss';
import clickCounter from '../../../../../helpers/counter';

export default function EventPartnersList({ partners, contact }) {
  if (partners && partners.length === 0) return null;

  const [showSlideUp, setShowSlideUp] = useState(false);
  const [elem, setElem] = useState({});

  const handleClickCard = (item) => {
    setElem(item);
    setShowSlideUp(!showSlideUp);
    clickCounter(`/partners/${item.id}`, item);
  };

  const partnerItems = partners.map((partner) => (
    <li key={partner.id}>
      <a className={styles.PartnersList__link} onClick={handleClickCard.bind(null, partner)}>
        <PartnerListCard partner={partner} />
      </a>
    </li>
  ));

  return (
    <div className={styles.PartnersList}>
      <PageHeader>
        <h2>Партнеры</h2>
      </PageHeader>
      <PageCardsList>{partnerItems}</PageCardsList>
      <BottomSlideUp show={showSlideUp} onClose={() => setShowSlideUp(false)}>
        <PartnerShowScreen partner={elem} />
      </BottomSlideUp>
      <BecomePartnerButton />
    </div>
  );
}

EventPartnersList.propTypes = {
  partners: PropTypes.arrayOf(PropTypes.shape({
    tags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      important: PropTypes.bool,
      title: PropTypes.string,
    })),
    age_restriction: PropTypes.number,
    name: PropTypes.string.isRequired,
  })).isRequired,
};
