import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import NotificationCard from '../components/Card';
import NotificationCardHeader from '../components/Header';
import NotificationCardFooter from '../components/Footer';
import NotificationCardPoints from '../components/Points';
import NotificationTitle from '../components/Title';

import styles from './Product.module.scss';

const pointsKinds = {
  product_bought: 'expense',
  product_returned: 'income',
};

export default function NotificationProductCard({ notification }) {
  const { action } = notification;
  const hasFooter = !['product_for_registration', 'registration_award_returned'].includes(action);
  const body = notification.data.body.split('\\n').map((text) => <div key={text} className={styles.ProductBase__text}>{text}</div>);

  return (
    <NotificationCard notification={notification}>
      <NotificationCardHeader
        id={notification.data.order_id}
        statusText={notification.data.title}
      />

      <div className={styles.ProductBase}>
        <div className={styles.ProductBase__content}>
          <NotificationTitle title={notification.data.order_title} />
          {
            notification.data.body
            && (
              <div className={styles.ProductBase__text}>{body}</div>
            )
          }
        </div>
        {notification.product_avatar_url && (
          <div
            className={cn(styles.ProductBase__image, styles[`ProductBase__image_${notification.visual_theme}`])}
            style={{ backgroundImage: `url(${notification.product_avatar_url}` }}
          />
        )}
      </div>

      {/* <NotificationCardBase */}
        {/* avatar={notification.product_avatar_url} */}
        {/* avatarType='product' */}
        {/* title={notification.data.order_title} */}
        {/* text={notification.data.body} */}
      {/* /> */}

      {hasFooter && (
        <NotificationCardFooter>
          <NotificationCardPoints
            playerPoints={notification.data.player_points}
            teamPoints={notification.data.team_points}
            kind={pointsKinds[action]}
          />
        </NotificationCardFooter>
      )}

    </NotificationCard>
  );
}

NotificationProductCard.propTypes = {
  notification: PropTypes.object.isRequired,
};
