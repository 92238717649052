import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactAvatarEditor from 'react-avatar-editor';
import Avatar, { getAvatarImage } from 'client/javascripts/components/Avatar';
import IconSvg from 'common/javascripts/components/IconSvg';
import { headersWithToken } from 'common/javascripts/fetchOptions';
import { visitOrRedirectTo } from 'common/javascripts/helpers';

const editorSize = 300;
const initialScale = 1;

AvatarUploader.propTypes = {
  resource: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string
  }),
  avatar: PropTypes.string
};

export default function AvatarUploader({ resource, avatar }) {
  const [isEditorMode, setIsEditorMode] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [imageScale, setImageScale] = useState(initialScale);
  const [imageRotate, setImageRotate] = useState(0);
  const [image, setImage] = useState(() => getAvatarImage(resource.type, avatar));

  const editorRef = useRef();
  const initialImage = useRef();
  const imageChanged = useRef(false);
  const newImageFileRef = useRef();

  useEffect(() => {
    initialImage.current = getAvatarImage(resource.type, avatar);
  });

  const handleNewImage = (e) => {
    const newImage = new Image();
    const file = e.target.files[0];
    const imgUrl = URL.createObjectURL(file);

    newImage.onload = setImage(imgUrl);
    newImage.src = imgUrl;
  };

  const cancelAction = () => {
    setIsEditorMode(false);
    setImage(initialImage.current);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    cancelAction();
  };

  const handleNewImageFileClick = () => {
    newImageFileRef.current.click();
  };

  const handleImageSave = (e) => {
    e.preventDefault();
    if (imageChanged.current) {
      editorRef.current.getImageScaledToCanvas().toBlob(uploadImage);
    } else {
      cancelAction();
    }
  };

  const uploadImage = async (imageToUpload) => {
    setIsUploading(true);

    try {
      const formData = new FormData();

      formData.append('file', imageToUpload, 'image.png');

      const url = `/images.json?parent_id=${resource.id}&parent_type=${resource.type}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: headersWithToken().delete('Content-type'),
        body: formData,
        multipartFormData: true
      });

      const result = await response.json();

      if (response.ok) {
        setIsUploading(false);
        setImage(result.image_url);
        setIsEditorMode(false);
        // force to refresh whole interface, so reload or visit current page.
        visitOrRedirectTo(window.location.href);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageScale = (e) => {
    setImageScale(parseFloat(e.target.value));
  };

  const handleImageChange = () => {
    imageChanged.current = true;
  };

  return (
    <div className='avatarUploader'>
      <div className='avatarUploader__avatar' onClick={() => setIsEditorMode(true)}>
        <IconSvg
          icon='edit-circle'
          className='avatarUploader__editIcon'
        />
        <Avatar type={resource.type} avatar={image} />
      </div>
      {isEditorMode &&
        <div className='avatarUploader__popup'>
          <div className='avatarUploader__popupContent'>
            <div className='avatarUploader__loadNewImage' onClick={handleNewImageFileClick}>
              <IconSvg icon='upload'/>
              <span>Загрузить фото</span>
              <input
                name='newImage'
                type='file'
                id='avatarUploadButton'
                ref={newImageFileRef}
                onChange={handleNewImage}
              />
            </div>


            <div className='avatarUploader__image'>
              <ReactAvatarEditor
                border={0}
                borderRadius={editorSize / 2}
                image={image}
                ref={editorRef}
                scale={imageScale}
                rotate={imageRotate}
                width={editorSize}
                height={editorSize}
                onImageChange={handleImageChange}
                className='avatarUploader__editorCanvas'
              />
            </div>

            <div className='avatarUploader__imageControls'>
              <span className='avatarUploader__controlsIcon' onClick={() => setImageRotate(imageRotate - 45)}>
                <IconSvg icon='rotate-left' />
              </span>
              <div className='avatarUploader__scale'>
                <span className='avatarUploader__controlsIcon' onClick={() => setImageScale(imageScale - 0.2)}>
                  <IconSvg icon='minus' className='avatarUploader__scaleIcon'/>
                </span>
                <input
                  name='imageScale'
                  type='range'
                  min={1}
                  max={4}
                  step={0.01}
                  onChange={handleImageScale}
                  value={imageScale}
                />
                <span className='avatarUploader__controlsIcon' onClick={() => setImageScale(imageScale + 0.2)}>
                  <IconSvg icon='plus' className='avatarUploader__scaleIcon' />
                </span>
              </div>
              <span className='avatarUploader__controlsIcon' onClick={() => setImageRotate(imageRotate + 45)}>
                <IconSvg icon='rotate-right' />
              </span>
            </div>

            <div className='avatarUploader__popupActions'>
              <button
                className='button button_secondary button_large button_fullwidth'
                onClick={handleCancel}
              >
                Отменить
              </button>

              <button
                className='button button_large button_fullwidth'
                onClick={handleImageSave}
                disabled={isUploading}
              >
                Сохранить
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
