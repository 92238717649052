import {
  action,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import moderatedPlayerStore from './moderatedPlayer';
import AnswersApi from './answersApi';

class ModeratedAnswers {
  answers = []

  isAnswerUpdating = false

  constructor(playerStore, answersApi) {
    makeObservable(this, {
      answers: observable,
      isAnswerUpdating: observable,
      fetchAnswers: action,
      acceptAnswer: action,
      rejectAnswer: action,
      replaceAnswer: action,
      takeAnswer: action,
    });
    this.moderatedPlayerStore = playerStore;
    this.answersApi = answersApi;
  }

  fetchAnswers = async (taskId) => {
    const { player } = this.moderatedPlayerStore;
    if (player) {
      const result = await this.answersApi.fetchAnswers(player.id, taskId);
      if (result.task_answers) {
        runInAction(() => {
          this.answers = result.task_answers;
        });
      }
    } else {
      this.answers = [];
    }
  }

  acceptAnswer = async (taskStepId) => {
    const { player } = this.moderatedPlayerStore;
    if (player) {
      this.isAnswerUpdating = true;
      const result = await this.answersApi.acceptAnswer(player.id, taskStepId);
      this.replaceAnswer(result.task_answer);
    }
  }

  rejectAnswer = async (taskStepId) => {
    const { player } = this.moderatedPlayerStore;
    if (player) {
      this.isAnswerUpdating = true;
      const result = await this.answersApi.rejectAnswer(player.id, taskStepId);
      this.replaceAnswer(result.task_answer);
    }
  }

  replaceAnswer = (updatedAnswer) => {
    if (updatedAnswer) {
      const answerIndex = this.answers.findIndex((a) => a.id === updatedAnswer.id);

      if (answerIndex > 0) {
        this.answers = [
          ...this.answers.slice(0, answerIndex),
          updatedAnswer,
          ...this.answers.slice(answerIndex + 1, this.answers.length),
        ];
      } else {
        this.answers = [updatedAnswer, ...this.answers];
      }
    }

    this.isAnswerUpdating = false;
  }

  takeAnswer = async (playerId, taskStepId) => {
    this.isAnswerUpdating = true;
    const answer = await this.answersApi.takeAnswer(playerId, taskStepId);
    runInAction(() => {
      this.isAnswerUpdating = false;
    });
    return answer;
  }
}

export default new ModeratedAnswers(moderatedPlayerStore, new AnswersApi());
