import React from 'react';
import PropTypes from 'prop-types';

import PageContentOtherBg from '../../components/PageContentOtherBg';
import ProfileCard from './components/ProfileCard';

export default function ProfileShowScreen({ profile }) {
  return (
    <PageContentOtherBg>
      <ProfileCard profile={profile} />
    </PageContentOtherBg>
  );
}

ProfileShowScreen.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    cover_url: PropTypes.string,
  }).isRequired,
};
