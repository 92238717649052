document.addEventListener('turbolinks:load', () => {
  const interval = setInterval(() => {
    if (document.readyState === 'complete') {
      clearInterval(interval);

      const btnEdit = document.querySelector('.js-player__instagram-edit');
      const inputNickName = document.querySelector('.js-player__identity-nickname');
      const profileUrl = document.querySelector('.js-player__identity-profile-url');

      const clickBtnEdit = (e) => {
        e.preventDefault();
        console.log('clickBtnEdit =>', profileUrl, inputNickName.value);
        if (profileUrl.style.display === 'none') {
          profileUrl.style.display = '';
          inputNickName.style.display = 'none';
          profileUrl.href = `https://instagram.com/${inputNickName.value}/`;
          profileUrl.innerHTML = `https://instagram.com/${inputNickName.value}/`;
          return;
        }
        if (profileUrl.style.display === '') {
          profileUrl.style.display = 'none';
          inputNickName.style.display = '';
        }
      };

      const init = () => {
        console.log('profile init');
        profileUrl.style.display = '';
        inputNickName.style.display = 'none';
      };

      if (btnEdit) {
        init();
        btnEdit.removeEventListener('click', clickBtnEdit, false);
        btnEdit.addEventListener('click', clickBtnEdit, false);
      }
    }
  }, 50);
});
