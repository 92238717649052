/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Portal from '../Portal';
import IconSvg from '../../../../common/javascripts/components/IconSvg';

import styles from './BottomSlideUp.module.scss';

export default function BottomSlideUp({
  children, show, onClose,
}) {
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  const handleOverflowClick = () => {
    onClose();
  };

  return (
    render && (
      <Portal>
        <div className={cn(styles.BottomSlideUp, styles[`BottomSlideUp_show_${show}`])}>
          <div
            className={styles.BottomSlideUp__overlay}
            onClick={handleOverflowClick}
          />

          <div
            className={styles.BottomSlideUp__body}
            onAnimationEnd={onAnimationEnd}
          >
            <button type="button" className={styles.BottomSlideUp__close} onClick={onClose}>
              <IconSvg icon="close" />
            </button>
            <div className={styles.BottomSlideUp__content}>
              {children}
            </div>
          </div>
        </div>
      </Portal>
    )
  );
}

BottomSlideUp.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

BottomSlideUp.defaultProps = {
  children: '',
  show: false,
};
