import React from 'react';
import PropTypes from 'prop-types';
import ReactQRCode from 'qrcode.react';

function QRCode({ value, renderAs, size }) {
  return <ReactQRCode value={value} renderAs={renderAs} size={size} level="H" />;
}

QRCode.propTypes = {
  value: PropTypes.string.isRequired,
  renderAs: PropTypes.string,
  size: PropTypes.number,
};

QRCode.defaultProps = {
  renderAs: 'canvas',
  size: 128,
};

export default QRCode;
