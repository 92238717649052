import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { headersWithToken } from '../../../../../../common/javascripts/fetchOptions';
import IconSvg from '../../../../../../common/javascripts/components/IconSvg';

import styles from '../../InvitePlayer.module.scss';

const newPlayerTeamInvitePath = '/new_player_team_invites';

export default function TeamPlayerNotFound({ phone, onShowPlayerSearch }) {
  const [isLoading, setIsLoading] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);

  const handleInviteClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const data = { phone };

    try {
      const response = await fetch(newPlayerTeamInvitePath, {
        method: 'POST',
        headers: headersWithToken(),
        body: JSON.stringify(data),
      });

      setIsLoading(false);

      if (response.ok) {
        setInviteSent(true);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  return (
    <div className={styles.TeamInvitePlayer}>
      <IconSvg icon="team-invite-player-not-found" className={styles.TeamInvitePlayer__icon} />
      <h1 className={styles.TeamInvitePlayer__title}>Игрок не найден</h1>
      {
        phone && (
          <div className={styles.TeamInvitePlayer__text}>
            {`Игрок с номером ${phone} не найден. Отправить ему приглашение по СМС?`}
          </div>
        )
      }
      <div className={styles.TeamInvitePlayer__sendingStatus}>
        {isLoading && 'Отправляем приглашение...'}
        {inviteSent && 'Приглашение отправлено'}
      </div>
      <div className={styles.TeamInvitePlayer__notFoundActions}>
        {
          (!isLoading && !inviteSent)
          && (
            <>
              <button
                type="button"
                onClick={onShowPlayerSearch}
                className="button button_large button_secondary button_fullwidth"
              >
                Назад
              </button>
              {
                phone && (
                  <button
                    type="button"
                    onClick={handleInviteClick}
                    className="button button_large button_fullwidth"
                  >
                    Да, отправить
                  </button>
                )
              }
            </>
          )
        }
      </div>
    </div>
  );
}

TeamPlayerNotFound.propTypes = {
  phone: PropTypes.string.isRequired,
  onShowPlayerSearch: PropTypes.func.isRequired,
};
