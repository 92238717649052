/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import eventStore from '../../../../../stores/events';

const tabs = [
  [
    'По датам',
    'days',
  ],
  [
    'По событиям',
    'tags',
  ],
];

function EventNavigation() {
  const {
    currentTab,
    onFilterChange,
  } = eventStore;

  const handleClickTab = (index) => {
    onFilterChange({
      tab: index,
    });
  };

  return (
    <div
      className="pageContent__navTabs navTabs"
    >
      {
        tabs.map(([tabLabel, tabKey]) => {
          const className = cn('navTabs__item', { navTabs__item_active: tabKey === currentTab });

          return (
            <div
              className={className}
              role="button"
              key={tabKey}
              onClick={() => handleClickTab(tabKey)}
            >
              <span className="navTabs__itemText">{tabLabel}</span>
            </div>
          );
        })
      }
    </div>
  );
}

export default observer(EventNavigation);
