import React from 'react';
import PropTypes from 'prop-types';
import IconSvg from '../../../../../../common/javascripts/components/IconSvg';
import Cost from '../../../Cost';

import styles from './Points.module.scss';

const label = {
  income: 'Поступление',
  expense: 'Расход',
  outcome: 'Расход',
};

const icon = {
  income: 'payment-plus',
  expense: 'payment-minus',
  outcome: 'payment-minus',
};

export default function NotificationCardPoints({
  playerPoints, teamPoints, bonusPoints, kind,
}) {
  const className = [
    styles.NotificationCardPoints,
    styles[`NotificationCardPoints_${kind}`],
  ].join(' ');

  return (
    <div className={className}>
      <span className={styles.NotificationCardPoints__label}>
        {label[kind]}
      </span>

      <IconSvg icon={icon[kind]} className={styles.NotificationCardPoints__kindIcon} />

      <span className={styles.NotificationCardPoints__section}>
        <Cost icon="player-points" amount={playerPoints} />
      </span>
      <span className={styles.NotificationCardPoints__section}>
        <Cost icon="team-points" amount={teamPoints} />
      </span>
      <span className={styles.NotificationCardPoints__section}>
        <Cost icon="bonus-points" amount={bonusPoints} />
      </span>
    </div>
  );
}

NotificationCardPoints.propTypes = {
  playerPoints: PropTypes.number,
  teamPoints: PropTypes.number,
  bonusPoints: PropTypes.number,
  kind: PropTypes.string,
};

NotificationCardPoints.defaultProps = {
  playerPoints: 0,
  teamPoints: 0,
  bonusPoints: 0,
  kind: 'income',
};
