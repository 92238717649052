import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Timer from '../../../../components/Timer';
import WinnerProfile from '../WinnerProfile';

import styles from './Winner.module.scss';

function Winner({
  announceDate,
  winner,
  isAdmin,
  showLikePlayer,
  onFire,
}) {
  const [showWinner, setShowWinner] = useState(!isAdmin);

  const winners = winner.length === 0 ? null : winner.map((current_winner) => {
    return (
      <div
        className={styles.Winner__winner}
        key={`winner_${current_winner.name}`}
      >
        <WinnerProfile winner={current_winner} />
      </div>
    );
  });

  const handleShowWinnerClick = () => {
    setShowWinner(true);
    onFire();
  };

  if (announceDate && !winner) {
    return (
      <div className={styles.Winner}>
        Победитель будет объявлен
        <br />
        {announceDate}
      </div>
    );
  }

  return (
    <>
      {
        !showWinner && !showLikePlayer && (
          <button
            type="button"
            className="button button_large button_fullwidth"
            onClick={handleShowWinnerClick}
          >
            Показать победителя
          </button>
        )
      }

      {(showWinner || (isAdmin && showLikePlayer)) && (
        winners
      )}

    </>
  );
}

Winner.propTypes = {
  announceDate: PropTypes.string,
  winner: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(['team', 'player']),
    avatar: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
  })),
};

Winner.defaultProps = {
  announceDate: null,
  winner: null,
};

export default Winner;
