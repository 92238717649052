import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Card from '../../../../../components/Card';
import CallWriteSnippet from '../../../../../components/CallWriteSnippet';
import IconSvg from '../../../../../../../common/javascripts/components/IconSvg';

import styles from './Snippet.module.scss';

export default function TaskCallWriteSnippet({ contact, textContent }) {
  const [isOpen, setIsOpen] = useState(false);

  const contactsGroup = contact.map((item) => (
    <Card key={`contact_card_${item.id}`} noShadow className={styles.Snippet__content}>
      <CallWriteSnippet contact={item} />
    </Card>
  ));

  return (
    <div className={cn(styles.Snippet, { [styles.Snippet_opened]: isOpen })}>
      <button
        className={styles.Snippet__trigger}
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <IconSvg icon="triangle-down" />
        {textContent.iconText}
      </button>

      {
        isOpen
        && (
          contactsGroup
        )
      }
    </div>
  );
}

TaskCallWriteSnippet.propTypes = {
  contact: PropTypes.arrayOf(PropTypes.shape({
    phone: PropTypes.string,
    header_card: PropTypes.string,
  })).isRequired,
  textContent: PropTypes.shape({
    iconText: PropTypes.string,
    cardHeaderText: PropTypes.string,
  }),
};

TaskCallWriteSnippet.defaultProps = {
  textContent: {
    iconText: 'Если возникла проблема',
    cardHeaderText: 'Сообщи нам номер задания и проблему',
  },
};
