import React from 'react';
import PropTypes from 'prop-types';

import AttentionMessage from '../../../../../components/AttentionMessage';

export default function BuyHint({ text }) {
  return (
    <AttentionMessage>
      {text}
    </AttentionMessage>
  );
}

BuyHint.propTypes = {
  text: PropTypes.string,
};
