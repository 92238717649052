import React from 'react';
import PropTypes from 'prop-types';
import Card from '../../../../components/Card';
import CardHeader from '../../../../components/CardHeader';

import TopAward from '../TopAward';

import styles from './Group.module.scss';

export default function TopAwardGroup({ group }) {
  return (
    <li>
      <Card noShadow>
        <div className={styles.Group__header}>
          <CardHeader>
            <h2>{group.title}</h2>
          </CardHeader>
        </div>
        {
          group.subtitle
          && (
            <div className={styles.Group__subheader}>
              {group.subtitle}
            </div>
          )
        }
        <div className={styles.Group__awards}>
          {
            group.top_awards.map((award) => (
              <TopAward
                key={award.id}
                topAward={award}
              />
            ))
          }
        </div>
      </Card>
    </li>
  );
}

TopAwardGroup.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    top_awards: PropTypes.instanceOf(Array).isRequired,
  }).isRequired,
};
