import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import cn from 'classnames';
import IconSvg from '../../../../../../common/javascripts/components/IconSvg';

import { phoneMask, isPhoneValid } from '../../../../helpers/phone';

import styles from '../../Auth.module.scss';

export default function AuthPhoneStep({ onSubmit, initialPhone, isLoading, welcomeText }) {
  const [isStepValid, setIsStepValid] = useState(false);
  const [isAgreeChecked, setIsAgreeChecked] = useState(true);
  const [phone, setPhone] = useState(initialPhone || '');

  const checkStepValid = () => {
    const newState = isAgreeChecked && isPhoneValid(phone);

    if (newState !== isStepValid) {
      setIsStepValid(newState);
    }
  };

  const handleAreeChange = (e) => {
    setIsAgreeChecked(e.target.checked);
  };

  const handleBeforeMaskedStateChange = ({ nextState }) => {
    let { value } = nextState;
    const { enteredString } = nextState;
    const digits = enteredString ? enteredString.replace(/[^0-9]/g, '') : '';

    if (digits.length >= 10) {
      const maskPhone = digits.slice(-10);

      value = `+7 (${maskPhone.slice(0, 3)}) ${maskPhone.slice(3, 6)}-${maskPhone.slice(6, 8)}-${maskPhone.slice(8, 10)}`;
    }

    return {
      ...nextState,
      value,
    };
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleSubmitClick = () => {
    onSubmit(phone);
  };

  useEffect(() => {
    checkStepValid();
  });

  return (
    <>
      <h1 className={styles.Auth__stepHeader}>Укажите свой номер телефона</h1>

      <div className={styles.Auth__inputWrap}>
        <InputMask
          mask={phoneMask}
          alwaysShowMask
          onChange={handlePhoneChange}
          value={phone}
          beforeMaskedStateChange={handleBeforeMaskedStateChange}
        >
          <input
            className="input input_large input_attention"
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
          />
        </InputMask>
        {
          isStepValid
          && (
            <button
              className={cn(styles.Auth__inputAddonBtn, 'button button_small', styles.Auth__bg)}
              disabled={!isStepValid || isLoading}
              onClick={handleSubmitClick}
              type="submit"
            >
              <IconSvg icon="arrow-right" />
            </button>
          )
        }
      </div>

      <div className={styles.Auth__agreeWrap}>
        <input
          type="checkbox"
          id="agree_checkbox"
          className="checkbox"
          onChange={handleAreeChange}
          checked={isAgreeChecked}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="agree_checkbox">
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: welcomeText.policy }}
          />
        </label>
      </div>

      <div className={styles.Auth__buttonWrap}>
        <button
          className={cn('button button_large button_fullwidth', styles.Auth__bg)}
          disabled={!isStepValid || isLoading}
          onClick={handleSubmitClick}
          type="submit"
        >
          Далее
        </button>
      </div>
    </>
  );
}

AuthPhoneStep.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialPhone: PropTypes.string,
  isLoading: PropTypes.bool,
  welcomeText: PropTypes.shape({
    text_1: PropTypes.string,
    text_2: PropTypes.string,
    text_3: PropTypes.string,
    policy: PropTypes.string,
    age_restriction: PropTypes.number,
  }).isRequired,
};

AuthPhoneStep.defaultProps = {
  initialPhone: '',
  isLoading: false,
};
