import React from 'react';
import PropTypes from 'prop-types';
import Booster from '../Booster';

export default function BoostersList({
  taskId, taskStepId, boosters, onBoosterUse,
}) {
  const collection = boosters.map((item) => (
    <Booster
      key={item.id}
      taskId={taskId}
      taskStepId={taskStepId}
      booster={item}
      onBoosterUse={onBoosterUse}
    />
  ));

  if (collection.length > 0) {
    return collection;
  }
  return null;
}

BoostersList.propTypes = {
  taskId: PropTypes.number,
  taskStepId: PropTypes.number,
  boosters: PropTypes.instanceOf(Array).isRequired,
  onBoosterUse: PropTypes.func.isRequired,
};
