import { headersWithToken } from '../../../common/javascripts/fetchOptions';

class AnswersApi {
  fetchAnswers = async (playerId, taskId) => {
    const response = await fetch(`/moderator/players/${playerId}/answers?task_id=${taskId}`);
    return response.json();
  }

  acceptAnswer = async (playerId, taskStepId) => {
    const response = await fetch(`/moderator/players/${playerId}/answers/accept`, {
      method: 'PATCH',
      headers: headersWithToken(),
      body: JSON.stringify({
        task_step_id: taskStepId,
      }),
    });
    return response.json();
  }

  rejectAnswer = async (playerId, taskStepId) => {
    const response = await fetch(`/moderator/players/${playerId}/answers/reject`, {
      method: 'PATCH',
      headers: headersWithToken(),
      body: JSON.stringify({
        task_step_id: taskStepId,
      }),
    });
    return response.json();
  }

  takeAnswer = async (playerId, taskStepId) => {
    try {
      const response = await fetch(`/moderator/players/${playerId}/answers`, {
        method: 'POST',
        headers: headersWithToken(),
        body: JSON.stringify({
          task_step_id: taskStepId,
        }),
      });

      const result = await response.json();
      if (result.status === 'error') {
        return null;
      }

      return result.task_answer;
    } catch (e) {
      return null;
    }
  }
}

export default AnswersApi;
